import React, { useEffect, useState, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { api } from "@globals/axiosConfig";
import { LoadingContext } from "@contexts/LoadingContext";
import ImageInput from "@components/ImageInput";
import { useSelector } from "react-redux";
import useGetUser from "@hooks/useGetUser";
import ErrorLabel, { usePasswordCheck } from "./hooks/usePasswordCheck";
import { toast } from "react-toastify";

export default () => {
  const getMe = useGetUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state) => state?.auth?.user);

  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const { isValid: passwordValid } = usePasswordCheck(password || "");
  const [openPassword, setOpenPassword] = useState(false);
  const [openPassword1, setOpenPassword1] = useState(false);
  const [openPassword2, setOpenPassword2] = useState(false);

  const invalidForm =
    !password ||
    !passwordValid ||
    !password1 ||
    !password2 ||
    password1 !== password2;

  const onSubmit = async () => {
    try {
      showLoader();
      await axios.post(`/auth/managers/password`, {
        newPassword: password1,
        verifyPassword: password2,
        currentPassword: password,
      });
      setPassword("");
      setPassword1("");
      setPassword2("");
      setOpenPassword(false);
      setOpenPassword1(false);
      setOpenPassword2(false);
      toast.success("Mot de passe modifié avec succès !");
    } catch (e) {
      console.log("change pass error", e);
      toast.error("Erreur de modification");
    } finally {
      hideLoader();
    }
  };
  return (
    <Container fluid className="  text-center h-100">
      <Row>
        <Col className="d-flex justify-content-start mt-4 ms-3">
          <h1 className="headerText">{t("Modifierlemotdepasse")}</h1>
        </Col>
      </Row>
      {/* users form  */}
      <Row className="mt-4 ms-1  ">
        <Col xs={12} md={4} className="">
          <Row>
            <Col className="d-flex justify-content-start mt-3">
              <div className="FormTitle"> {t("Motdepasseactuel")}</div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-star flex-column mt-1">
              <div className="textFormContainer">
                <div
                  onClick={() => setOpenPassword(!openPassword)}
                  className="input-addon"
                >
                  <img
                    className="input-addon-img"
                    alt="+"
                    src={
                      openPassword
                        ? require("@imgs/eye.png")
                        : require("@imgs/close-eye.png")
                    }
                  />
                </div>
                <input
                  id="passwordinput2"
                  type={openPassword ? "text" : "password"}
                  value={password}
                  onChange={(t) => setPassword(t.target.value)}
                  className="TabFormInput"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start mt-3">
              <div className="FormTitle">{t("Nouveaumotdepasse")}</div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-star flex-column mt-1">
              <div className="textFormContainer">
                <div
                  onClick={() => setOpenPassword1(!openPassword1)}
                  className="input-addon"
                >
                  <img
                    className="input-addon-img"
                    alt="+"
                    src={
                      openPassword1
                        ? require("@imgs/eye.png")
                        : require("@imgs/close-eye.png")
                    }
                  />
                </div>
                <input
                  id="inputpassword2"
                  type={openPassword1 ? "text" : "password"}
                  value={password1}
                  onChange={(t) => setPassword1(t.target.value)}
                  className="TabFormInput"
                />
              </div>
              {password1 !== "" && <ErrorLabel password={password1} />}
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start mt-3">
              <div className="FormTitle">{t("Confirmermotdepasse")}</div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-star flex-column mt-1">
              <div className="textFormContainer">
                <div
                  onClick={() => setOpenPassword2(!openPassword2)}
                  className="input-addon"
                >
                  <img
                    className="input-addon-img"
                    alt="+"
                    src={
                      openPassword2
                        ? require("@imgs/eye.png")
                        : require("@imgs/close-eye.png")
                    }
                  />
                </div>
                <input
                  id="inputpassword3"
                  type={openPassword2 ? "text" : "password"}
                  value={password2}
                  onChange={(t) => setPassword2(t.target.value)}
                  className="TabFormInput"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mt-5 ms-2 pb-5 d-flex justify-content-start ">
        <Col xs={12} md={4} lg={2} className="d-flex justify-content-start ">
          <div
            onClick={() => {
              !invalidForm && onSubmit();
            }}
            className="btnSave d-flex justify-content-center align-items-center"
          >
            {t("Sauvegarder")}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
