import { useContext, useState } from "react";
import { LoadingContext } from "@contexts/LoadingContext";
import axios from "@globals/axiosConfig";
import { toast } from "react-toastify";
export default () => {
  const [errorStats, setErrorStats] = useState({ ok: true });
  const [successStats, setSuccessStats] = useState(null);
  const { showLoader, hideLoader } = useContext(LoadingContext);

  async function SubscribeStats(payload) {
    try {
      showLoader();

      const rep = await axios.post(
        `/manager/societies/subscription/stats`,
        payload
      );
      setSuccessStats(rep?.data);
      toast.success(rep?.data.message);
    } catch (error) {
      setErrorStats(error);
         if (error?.response?.data?.message) {
          //  toast.error(error?.response?.data?.message);
         }
    } finally {
      hideLoader();
    }
  }

  return [
    { errorStats, setErrorStats, successStats, setSuccessStats },
    SubscribeStats,
  ];
};
