// Modal.js
import React from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import "./stylesforModal.css";
const CustomModal = ({ headerText, contentText, onYes, onNo, onClose,Body }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      contentLabel="Popup Alert"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
        },
        content: {
          width: "70%",
          maxWidth: "400px",
          margin: "auto",
          borderRadius: "10px",
          padding: "0",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          border: "none",
          height: "235px",
        },
      }}
    >
      <div
        className="modal-header"
        style={{
          backgroundColor: "#1FB789",
          padding: "10px",
          borderRadius: "10px 10px 0 0",
          width: "100%",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <h2 className="" style={{ color: "white", margin: 0 }}>
          {headerText}
        </h2>
      </div>
      <div className="" style={{ backgroundColor: "white", padding: "20px" }}>
        <p>{contentText}</p>
      </div>
      <div
        className="modal-footer"
        style={{
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "0 0 10px 10px",
          display: "flex",
          // alignSelf: "end",
          marginTop: "auto",
          justifyContent: "space-between",
          // alignItems: "center",
        }}
      >
        <button
          onClick={onNo}

          className="btnLeft"
        >
          {t("Non")}
        </button>
        <button onClick={onYes} className="btnRight">
          {t("Oui")}
        </button>
      </div>
    </Modal>
  );
};

export default CustomModal;
