import { useContext, useState } from "react";
import axios from "@globals/axiosConfig";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "@contexts/LoadingContext";

// endpoints
import { sendMessageUrl } from "../CodeMessagesApi";

export default function useUpdateCodePro(id) {
  const [fetchingSend, setFetching] = useState(false);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const navigate = useNavigate();
  async function sendMessage(payload,cb = () => {}) {
    try {
      setFetching(true);
      showLoader();
      const rep = await axios.post(sendMessageUrl(), payload);
      setFetching(false);
      cb(rep);
    } catch (error) {
    } finally {
      setFetching(false);
      hideLoader();
    }
  }

  return [fetchingSend, sendMessage];
}
