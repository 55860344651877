import { useContext, useState } from "react";
import { codesUrl } from "../CodeMessagesApi";
import axios from "@globals/axiosConfig";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "@contexts/LoadingContext";

export default () => {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState();

  const { showLoader, hideLoader } = useContext(LoadingContext);
  const navigate = useNavigate();
  async function getCodes(cb = () => {}, skip, Qtext) {
    try {
      setFetching(true);
      showLoader();
      const rep = await axios.get(
        codesUrl(`&$top=${skip||10}&$q=${Qtext || ""}`)
      );
      if (!Qtext && rep?.data?.count === 0) {
        navigate(`/empty`);
      } else {
        setFetching(false);
        setData(rep?.data?.value);
        cb(
          rep?.data?.value,
          rep?.data?.top,
          rep?.data?.count,
        );
      }
    } catch (error) {
    } finally {
      setFetching(false);
      hideLoader();
    }
  }
  return [fetching, data, getCodes];
};
