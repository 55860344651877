import { useContext, useState } from "react";
import axios from "@globals/axiosConfig";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "@contexts/LoadingContext";
import { useModal } from "@/contextProviders/ModalContext";

// endpoints
import { deleteCodeUrl } from "../CodeMessagesApi";
import { useTranslation } from "react-i18next";

export default function useUpdateCodeNotPro(id) {
  const [fetchingDelete, setFetching] = useState(false);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { openModal, closeModal } = useModal();
   const { t } = useTranslation();

  const navigate = useNavigate();
  async function deleteCode(cb = () => {}) {
    openModal(
      t("Confirmation"),
      t("Supprimercode"),
      async () => {
        try {
          setFetching(true);
          showLoader();
          const rep = await axios.delete(deleteCodeUrl(id));
          //TODO RESET THE CODE IN REDUX
          setFetching(false);
          cb(rep);
        } catch (error) {
        } finally {
          hideLoader();
          closeModal();
          setFetching(false);
        }
      },
      () => {
        console.log("No clicked");
      }
    );
  }

  return [fetchingDelete, deleteCode];
}
