// PrivateRoute.js
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getToken } from "@globals/authService";


const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!getToken();
  if (!isAuthenticated) {
    return children;
  } else {
    //TODO IF THE USER NAVIGATE TO AUTH PAGE WHEN CONNECTED THEN REDIRECT TO HOME PAGE
    return <Navigate to="/" replace />;
  }
};
export default PrivateRoute;
