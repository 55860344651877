import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AppInput from "@components/AppInput";
import PhoneInput from "@components/PhoneInput";
import Button from "@components/Button";
import CodeTypeButton from "@components/CodeTypeButton";
import ButtonOutLigned from "@components/ButtonOutLigned";
import ImageInput from "@components/ImageInput";
import MultyMediaInput from "@components/MultyMediaInput";
import GooglePlaceInput from "@components/GooglePlaceInput";
import useCreateCode from "./hooks/useCreateCode";
import { useNavigate } from "react-router-dom";
import { validateFields, geocodeAddress } from "@helpers/helpers";
import "./styles.css";
import { useTranslation } from "react-i18next";
import CropperModal from "@components/CropperModal";
import {
  setCode as setReduxCode,
  deleteCode,
  setmobileView,
  deletemobileView,
} from "../../Home/slice";
import { useDispatch } from "react-redux";
import AiButton from "@components/AiButton";
import { useModal } from "@/contextProviders/ModalContext";
import Modal from "react-modal";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const ScrollDownIndicator = () => {
  return (
    <div className="scroll-down-indicator">
      <img src={require("@imgs/arrow.png")} alt="Scroll Down" />
    </div>
  );
};

export default () => {
  const isMobileMedia = useMediaQuery("(max-width: 458px)");
  console.log("isMobileMedia", isMobileMedia);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: null,
    thumbnail: null,
    type: null,
    content: null,
    address1: null,
    location: null,
    country: "FR",
  });
  const [logo, setLogo] = useState();
  const [QrCodeTypeSelection, setQrCodeTypeSelection] = useState(true);
  const [logoQR, setLogoQR] = useState();
  const [MultyMedia, setMultyMedia] = useState();
  const [fetching, createCode] = useCreateCode();
  const [OrigineImageToCrop, setOrigineImageToCrop] = useState();
  const [showCropper, setShowCropper] = useState(false);
  const divHeaderRef = useRef(null);
  const onChoosingImageClickHandler = () => {
    setShowCropper(!showCropper);
  };
  const getCoordinates = async (address) => {
    try {
      const location = await geocodeAddress(address.value.description);
      setFormData({
        ...formData,
        location,
        address1: address,
      });
    } catch (error) {
      console.log(error);
    }
  };
  // return modal based on currentLanguage
  const modalContent = () => {
    switch (currentLanguage) {
      case "fr":
        return (
          <>
            <div
              // className="modal-header"
              style={{
                backgroundColor: "#1FB789",
                padding: "30px",
                borderRadius: "10px 10px 0 0",
                width: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                position: "fixed",
                width: "70%",
                maxWidth: "800px",
              }}
            >
              <h2 className="" style={{ color: "white", margin: 0 }}>
                {"Génération de QR codes avec Intelligence artificielle"}
              </h2>
            </div>
            <div
              className=""
              style={{
                backgroundColor: "white",
                padding: isMobileMedia ? `10px` : "20px",
                marginTop: isMobileMedia ? `200px` : `140px`,
              }}
            >
              <h2>Étape 1 : Générer ou récupérer un QR code</h2>
              <p>
                Option 1 - Générer un QR code AI sur un site gratuit comme :
              </p>
              <ul>
                <li>
                  <a
                    href="https://huggingface.co/spaces/sayakanishikawa/QR-code-AI-art-generator"
                    target="_blank"
                  >
                    https://huggingface.co/spaces/sayakanishikawa/QR-code-AI-art-generator
                  </a>
                </li>
                <li>
                  <a
                    href="https://qr-code-generator-ai.web.app/"
                    target="_blank"
                  >
                    https://qr-code-generator-ai.web.app/
                  </a>
                </li>
                <li>
                  <a href="https://QR-code-studio.com" target="_blank">
                    https://QR-code-studio.com
                  </a>
                </li>
                <li>
                  Ou le générateur de QR codes AI de Google :{" "}
                  <a href="https://ai.googleblog.com/" target="_blank">
                    https://ai.googleblog.com/
                  </a>
                </li>
              </ul>
              <p>
                Option 2 - Récupérer l'image d'un QR code que vous avez déjà
                créé sur n'importe quelle plateforme.
              </p>

              <h2>Étape 2 : Importer le QR code sur Cool2Scan</h2>
              <p>
                Connectez-vous à Cool2Scan et allez dans "Créer un QR code".
                Cliquez sur "Importer" et chargez l'image de votre QR code.
              </p>

              <h2>Étape 3 : Personnaliser le QR code</h2>
              <p>Sur Cool2Scan, vous pouvez personnaliser votre QR code en :</p>
              <ul>
                <li>Le nommant</li>
                <li>L'associant à votre logo ou une image</li>
                <li>Ajoutant une adresse mail de contact</li>
                <li>Ajoutant un numéro de téléphone</li>
                <li>Renseignant une localisation géographique</li>
              </ul>
              <p>
                Ces informations apparaîtront sur le mobile des personnes qui
                scannent avec l'appli Cool2Scan.
              </p>

              <h2>Étape 4 : Intégrer du contenu enrichi</h2>
              <p>Liez du contenu à votre QR :</p>
              <ul>
                <li>Pages web, vidéos, sons, visuels</li>
                <li>Programmez des notifications push</li>
                <li>Activez le tracking d'engagement, le mode pro</li>
              </ul>

              <h2>Étape 5 : Publier le QR code enrichi</h2>
              <p>Publiez où vous voulez :</p>
              <ul>
                <li>Supports imprimés</li>
                <li>Web, télévision, vidéos, etc.</li>
              </ul>

              <p>
                Grâce à ce tutoriel, vous pouvez générer un QR code unique avec
                l'intelligence artificielle ou en importer un existant, le
                personnaliser sur Cool2Scan, puis y intégrer des contenus
                dynamiques pour créer une expérience interactive riche.
              </p>
            </div>
            <div
              className="modal-footer"
              style={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "0 0 10px 10px",
                display: "flex",
                // alignSelf: "end",
                marginTop: "auto",
                justifyContent: "center",
                // alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <button
                onClick={() => {
                  setIsModalOpen(false);
                }}
                className="btnRight"
              >
                {t("Oui")}
              </button>
            </div>
          </>
        );
      case "en":
        return (
          <>
            <div
              // className="modal-header"
              style={{
                backgroundColor: "#1FB789",
                padding: "30px",
                borderRadius: "10px 10px 0 0",
                width: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                position: "fixed",
                width: "70%",
                maxWidth: "800px",
              }}
            >
              <h2 className="" style={{ color: "white", margin: 0 }}>
                {"QR Code Generation with Artificial Intelligence"}
              </h2>
            </div>
            <div
              className=""
              style={{
                backgroundColor: "white",
                padding: isMobileMedia ? `10px` : "20px",
                marginTop: isMobileMedia ? `200px` : `140px`,
              }}
            >
              <h2>Step 1: Generate or Retrieve a QR Code</h2>
              <p>Option 1 - Generate an AI QR code on a free site such as:</p>
              <ul>
                <li>
                  <a
                    href="https://huggingface.co/spaces/sayakanishikawa/QR-code-AI-art-generator"
                    target="_blank"
                  >
                    https://huggingface.co/spaces/sayakanishikawa/QR-code-AI-art-generator
                  </a>
                </li>
                <li>
                  <a
                    href="https://qr-code-generator-ai.web.app/"
                    target="_blank"
                  >
                    https://qr-code-generator-ai.web.app/
                  </a>
                </li>
                <li>
                  <a href="https://QR-code-studio.com" target="_blank">
                    https://QR-code-studio.com
                  </a>
                </li>
                <li>
                  Or the Google AI QR code generator:{" "}
                  <a href="https://ai.googleblog.com/" target="_blank">
                    https://ai.googleblog.com/
                  </a>
                </li>
              </ul>
              <p>
                Option 2 - Retrieve the image of a QR code you have already
                created on any platform.
              </p>

              <h2>Step 2: Import the QR Code to Cool2Scan</h2>
              <p>
                Log in to Cool2Scan and go to "Create a QR code". Click on
                "Import" and upload the image of your QR code.
              </p>

              <h2>Step 3: Customize the QR Code</h2>
              <p>On Cool2Scan, you can customize your QR code by:</p>
              <ul>
                <li>Naming it</li>
                <li>Associating it with your logo or an image</li>
                <li>Adding a contact email address</li>
                <li>Adding a phone number</li>
                <li>Providing a geographical location</li>
              </ul>
              <p>
                These information will appear on the mobile devices of people
                who scan it with the Cool2Scan app.
              </p>

              <h2>Step 4: Integrate Rich Content</h2>
              <p>Link content to your QR:</p>
              <ul>
                <li>Web pages, videos, sounds, visuals</li>
                <li>Schedule push notifications</li>
                <li>Activate engagement tracking, pro mode</li>
              </ul>

              <h2>Step 5: Publish the Enriched QR Code</h2>
              <p>Publish wherever you want:</p>
              <ul>
                <li>Printed media</li>
                <li>Web, television, videos, etc.</li>
              </ul>

              <p>
                Thanks to this tutorial, you can generate a unique QR code with
                artificial intelligence or import an existing one, customize it
                on Cool2Scan, then integrate dynamic content to create a rich
                interactive experience.
              </p>
            </div>
            <div
              className="modal-footer"
              style={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "0 0 10px 10px",
                display: "flex",
                // alignSelf: "end",
                marginTop: "auto",
                justifyContent: "center",
                // alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <button
                onClick={() => {
                  setIsModalOpen(false);
                }}
                className="btnRight"
              >
                {t("Oui")}
              </button>
            </div>
          </>
        );
      case "gr":
        return (
          <>
            <div
              // className="modal-header"
              style={{
                backgroundColor: "#1FB789",
                padding: "30px",
                borderRadius: "10px 10px 0 0",
                width: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                position: "fixed",
                width: "70%",
                maxWidth: "800px",
              }}
            >
              <h2 className="" style={{ color: "white", margin: 0 }}>
                {"QR-Code-Erstellung mit Künstlicher Intelligenz"}
              </h2>
            </div>
            <div
              className=""
              style={{
                backgroundColor: "white",
                padding: isMobileMedia ? `10px` : "20px",
                marginTop: isMobileMedia ? `200px` : `140px`,
              }}
            >
              <h2>Schritt 1: Einen QR-Code generieren oder abrufen</h2>
              <p>
                Option 1 - Einen AI-QR-Code auf einer kostenlosen Website
                generieren, wie z.B.:
              </p>
              <ul>
                <li>
                  <a
                    href="https://huggingface.co/spaces/sayakanishikawa/QR-code-AI-art-generator"
                    target="_blank"
                  >
                    https://huggingface.co/spaces/sayakanishikawa/QR-code-AI-art-generator
                  </a>
                </li>
                <li>
                  <a
                    href="https://qr-code-generator-ai.web.app/"
                    target="_blank"
                  >
                    https://qr-code-generator-ai.web.app/
                  </a>
                </li>
                <li>
                  <a href="https://QR-code-studio.com" target="_blank">
                    https://QR-code-studio.com
                  </a>
                </li>
                <li>
                  Oder der Google AI-QR-Code-Generator:{" "}
                  <a href="https://ai.googleblog.com/" target="_blank">
                    https://ai.googleblog.com/
                  </a>
                </li>
              </ul>
              <p>
                Option 2 - Das Bild eines QR-Codes abrufen, den Sie bereits auf
                einer beliebigen Plattform erstellt haben.
              </p>

              <h2>Schritt 2: Den QR-Code zu Cool2Scan importieren</h2>
              <p>
                Melden Sie sich bei Cool2Scan an und gehen Sie zu "QR-Code
                erstellen". Klicken Sie auf "Importieren" und laden Sie das Bild
                Ihres QR-Codes hoch.
              </p>

              <h2>Schritt 3: Den QR-Code anpassen</h2>
              <p>Auf Cool2Scan können Sie Ihren QR-Code anpassen, indem Sie:</p>
              <ul>
                <li>Ihn benennen</li>
                <li>Ihn mit Ihrem Logo oder einem Bild verknüpfen</li>
                <li>Eine Kontakt-E-Mail-Adresse hinzufügen</li>
                <li>Eine Telefonnummer hinzufügen</li>
                <li>Einen geografischen Standort angeben</li>
              </ul>
              <p>
                Diese Informationen erscheinen auf den Mobilgeräten der
                Personen, die ihn mit der Cool2Scan-App scannen.
              </p>

              <h2>Schritt 4: Reichhaltigen Inhalt integrieren</h2>
              <p>Verknüpfen Sie Inhalte mit Ihrem QR:</p>
              <ul>
                <li>Webseiten, Videos, Sounds, Visuals</li>
                <li>Push-Benachrichtigungen planen</li>
                <li>Engagement-Tracking aktivieren, Pro-Modus</li>
              </ul>

              <h2>Schritt 5: Den angereicherten QR-Code veröffentlichen</h2>
              <p>Veröffentlichen Sie, wo Sie möchten:</p>
              <ul>
                <li>Druckmedien</li>
                <li>Web, Fernsehen, Videos, usw.</li>
              </ul>

              <p>
                Dank dieses Tutorials können Sie einen einzigartigen QR-Code mit
                künstlicher Intelligenz generieren oder einen vorhandenen
                importieren, ihn auf Cool2Scan anpassen und dann dynamische
                Inhalte integrieren, um eine reichhaltige interaktive Erfahrung
                zu schaffen.
              </p>
            </div>
            <div
              className="modal-footer"
              style={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "0 0 10px 10px",
                display: "flex",
                // alignSelf: "end",
                marginTop: "auto",
                justifyContent: "center",
                // alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <button
                onClick={() => {
                  setIsModalOpen(false);
                }}
                className="btnRight"
              >
                {t("Oui")}
              </button>
            </div>
          </>
        );
      case "it":
        return (
          <>
            <div
              // className="modal-header"
              style={{
                backgroundColor: "#1FB789",
                padding: "30px",
                borderRadius: "10px 10px 0 0",
                width: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                position: "fixed",
                width: "70%",
                maxWidth: "800px",
              }}
            >
              <h2 className="" style={{ color: "white", margin: 0 }}>
                {"Generazione di Codici QR con Intelligenza Artificiale"}
              </h2>
            </div>
            <div
              className=""
              style={{
                backgroundColor: "white",
                padding: isMobileMedia ? `10px` : "20px",
                marginTop: isMobileMedia ? `200px` : `140px`,
              }}
            >
              <h2>Passo 1: Generare o Recuperare un Codice QR</h2>
              <p>
                Opzione 1 - Generare un codice QR AI su un sito gratuito come:
              </p>
              <ul>
                <li>
                  <a
                    href="https://huggingface.co/spaces/sayakanishikawa/QR-code-AI-art-generator"
                    target="_blank"
                  >
                    https://huggingface.co/spaces/sayakanishikawa/QR-code-AI-art-generator
                  </a>
                </li>
                <li>
                  <a
                    href="https://qr-code-generator-ai.web.app/"
                    target="_blank"
                  >
                    https://qr-code-generator-ai.web.app/
                  </a>
                </li>
                <li>
                  <a href="https://QR-code-studio.com" target="_blank">
                    https://QR-code-studio.com
                  </a>
                </li>
                <li>
                  O il generatore di codici QR AI di Google:{" "}
                  <a href="https://ai.googleblog.com/" target="_blank">
                    https://ai.googleblog.com/
                  </a>
                </li>
              </ul>
              <p>
                Opzione 2 - Recuperare l'immagine di un codice QR che hai già
                creato su qualsiasi piattaforma.
              </p>

              <h2>Passo 2: Importare il Codice QR su Cool2Scan</h2>
              <p>
                Accedi a Cool2Scan e vai su "Crea un codice QR". Clicca su
                "Importa" e carica l'immagine del tuo codice QR.
              </p>

              <h2>Passo 3: Personalizzare il Codice QR</h2>
              <p>
                Su Cool2Scan, puoi personalizzare il tuo codice QR in questo
                modo:
              </p>
              <ul>
                <li>Nominarlo</li>
                <li>Associarlo al tuo logo o un'immagine</li>
                <li>Aggiungendo un indirizzo email di contatto</li>
                <li>Aggiungendo un numero di telefono</li>
                <li>Fornendo una posizione geografica</li>
              </ul>
              <p>
                Queste informazioni appariranno sui dispositivi mobili delle
                persone che lo scansionano con l'app Cool2Scan.
              </p>

              <h2>Passo 4: Integrare Contenuti Arricchiti</h2>
              <p>Collega contenuti al tuo QR:</p>
              <ul>
                <li>Pagine web, video, suoni, visuali</li>
                <li>Programmare notifiche push</li>
                <li>Attivare il tracciamento dell'impegno, modalità pro</li>
              </ul>

              <h2>Passo 5: Pubblicare il Codice QR Arricchito</h2>
              <p>Pubblica dove vuoi:</p>
              <ul>
                <li>Supporti stampati</li>
                <li>Web, televisione, video, ecc.</li>
              </ul>

              <p>
                Grazie a questo tutorial, puoi generare un codice QR unico con
                intelligenza artificiale o importare uno esistente,
                personalizzarlo su Cool2Scan, e poi integrare contenuti dinamici
                per creare un'esperienza interattiva ricca.
              </p>
            </div>
            <div
              className="modal-footer"
              style={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "0 0 10px 10px",
                display: "flex",
                // alignSelf: "end",
                marginTop: "auto",
                justifyContent: "center",
                // alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <button
                onClick={() => {
                  setIsModalOpen(false);
                }}
                className="btnRight"
              >
                {t("Oui")}
              </button>
            </div>
          </>
        );
      case "sp":
        return (
          <>
            <div
              // className="modal-header"
              style={{
                backgroundColor: "#1FB789",
                padding: "30px",
                borderRadius: "10px 10px 0 0",
                width: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                position: "fixed",
                width: "70%",
                maxWidth: "800px",
              }}
            >
              <h2 className="" style={{ color: "white", margin: 0 }}>
                {"Generación de Códigos QR con Inteligencia Artificial"}
              </h2>
            </div>
            <div
              className=""
              style={{
                backgroundColor: "white",
                padding: isMobileMedia ? `10px` : "20px",
                marginTop: isMobileMedia ? `200px` : `140px`,
              }}
            >
              <h2>Paso 1: Generar o Recuperar un Código QR</h2>
              <p>
                Opción 1 - Generar un código QR AI en un sitio gratuito como:
              </p>
              <ul>
                <li>
                  <a
                    href="https://huggingface.co/spaces/sayakanishikawa/QR-code-AI-art-generator"
                    target="_blank"
                  >
                    https://huggingface.co/spaces/sayakanishikawa/QR-code-AI-art-generator
                  </a>
                </li>
                <li>
                  <a
                    href="https://qr-code-generator-ai.web.app/"
                    target="_blank"
                  >
                    https://qr-code-generator-ai.web.app/
                  </a>
                </li>
                <li>
                  <a href="https://QR-code-studio.com" target="_blank">
                    https://QR-code-studio.com
                  </a>
                </li>
                <li>
                  O el generador de códigos QR AI de Google:{" "}
                  <a href="https://ai.googleblog.com/" target="_blank">
                    https://ai.googleblog.com/
                  </a>
                </li>
              </ul>
              <p>
                Opción 2 - Recuperar la imagen de un código QR que ya has creado
                en cualquier plataforma.
              </p>

              <h2>Paso 2: Importar el Código QR en Cool2Scan</h2>
              <p>
                Inicia sesión en Cool2Scan y ve a "Crear un código QR". Haz clic
                en "Importar" y carga la imagen de tu código QR.
              </p>

              <h2>Paso 3: Personalizar el Código QR</h2>
              <p>
                En Cool2Scan, puedes personalizar tu código QR de la siguiente
                manera:
              </p>
              <ul>
                <li>Nombrándolo</li>
                <li>Asociándolo con tu logo o una imagen</li>
                <li>
                  Agregando una dirección de correo electrónico de contacto
                </li>
                <li>Agregando un número de teléfono</li>
                <li>Proporcionando una ubicación geográfica</li>
              </ul>
              <p>
                Esta información aparecerá en los dispositivos móviles de las
                personas que lo escaneen con la aplicación Cool2Scan.
              </p>

              <h2>Paso 4: Integrar Contenido Enriquecido</h2>
              <p>Vincula contenido a tu QR:</p>
              <ul>
                <li>Páginas web, videos, sonidos, visuales</li>
                <li>Programa notificaciones push</li>
                <li>Activa el seguimiento de compromiso, modo pro</li>
              </ul>

              <h2>Paso 5: Publicar el Código QR Enriquecido</h2>
              <p>Publica donde quieras:</p>
              <ul>
                <li>Medios impresos</li>
                <li>Web, televisión, videos, etc.</li>
              </ul>

              <p>
                Gracias a este tutorial, puedes generar un código QR único con
                inteligencia artificial o importar uno existente, personalizarlo
                en Cool2Scan, y luego integrar contenido dinámico para crear una
                experiencia interactiva rica.
              </p>
            </div>
            <div
              className="modal-footer"
              style={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "0 0 10px 10px",
                display: "flex",
                // alignSelf: "end",
                marginTop: "auto",
                justifyContent: "center",
                // alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <button
                onClick={() => {
                  setIsModalOpen(false);
                }}
                className="btnRight"
              >
                {t("Oui")}
              </button>
            </div>
          </>
        );

      default:
        break;
    }
  };

  return (
    <Container fluid className="ps-4   h-100">
      <div className="maxHightForContainer">
        {/* page name */}
        <Row>
          <Col>
            <h1 className=" creationText mt-3 ">{t("Creeruncode")}</h1>
          </Col>
        </Row>
        {/* name & logo */}
        <Row className="mt-3">
          <Col xs={12} md={8} lg={4}>
            <AppInput
              labelText={t("Nomducode")}
              value={formData.name}
              onChange={(name) => setFormData({ ...formData, name })}
              required
              className="AppInput"
            />
          </Col>
          {/* logo */}
          <Col xs={12} md={8} lg={4}>
            <ImageInput
              labelText={t("Logoducode")}
              placeholder={t("Choisirunephoto")}
              value={logo}
              required
              setImageUrl={(file) => {
                setOrigineImageToCrop(file);
                setLogo(file);
                file && onChoosingImageClickHandler();
              }}
              setImageUrlFromApi={(thumbnail) => {
                setFormData({ ...formData, thumbnail });
              }}
              className="AppInput"
              LeftIcon={
                <img
                  src={require("@imgs/LeftImageIcon.png")}
                  className="LeftIcon"
                  alt={"LeftIcon"}
                />
              }
            />
          </Col>
        </Row>
        {/* Qrcode import or generate section  */}
        <Row className="mt-3">
          <Col xs={12} md={8} lg={3} className="responsiveCol ">
            {QrCodeTypeSelection ? (
              <Button
                text={t("newCode")}
                labelText={t("QRCode") + "*"}
                fetching={false}
                customStyles=" justify-content-center  d-flex align-items-center"
                onClick={() => {
                  setQrCodeTypeSelection(true);
                }}
                LeftComponent={() => (
                  <img
                    src={require("@imgs/iconQr.png")}
                    className="leftBtnIcon"
                    alt={"imageComponent"}
                  />
                )}
              />
            ) : (
              <ButtonOutLigned
                customStyles=" justify-content-center d-flex align-items-center"
                text={t("newCode")}
                fetching={false}
                // black
                labelText={t("QRCode") + "*"}
                onClick={() => {
                  setQrCodeTypeSelection(true);
                }}
                LeftComponent={() => (
                  <img
                    src={require("@imgs/greenQr.png")}
                    className="leftBtnIcon"
                    alt={"imageComponent"}
                  />
                )}
              />
            )}
          </Col>
          <Col
            xs={12}
            md={8}
            lg={3}
            className="d-flex align-items-end justify-content-start mt-3 mt-lg-0  responsiveCol "
          >
            {QrCodeTypeSelection ? (
              <ButtonOutLigned
                customStyles=" justify-content-center d-flex align-items-center"
                text={t("uploadCode")}
                fetching={false}
                onClick={() => {
                  setQrCodeTypeSelection(false);
                }}
                LeftComponent={() => (
                  <img
                    src={require("@imgs/iconDown.png")}
                    className="leftBtnIcon"
                    alt={"imageComponent"}
                  />
                )}
              />
            ) : (
              <Button
                text="Importer un code existant"
                fetching={false}
                customStyles=" justify-content-center  d-flex align-items-center"
                onClick={() => {
                  setQrCodeTypeSelection(false);
                }}
                LeftComponent={() => (
                  <img
                    src={require("@imgs/Whitedownload.png")}
                    className="leftBtnIcon"
                    alt={"imageComponent"}
                  />
                )}
              />
            )}
          </Col>
        </Row>
        {/* logo import */}
        {!QrCodeTypeSelection && (
          <Row className="pt-3">
            {/* logo */}
            <Col xs={12} md={8} lg={4}>
              <ImageInput
                isQrCode={true}
                labelText={t("Joindrelaphotodimention")}
                placeholder={t("Choisirunephoto")}
                value={logoQR}
                setImageUrl={setLogoQR}
                setImageUrlFromApi={(qrcode) => {
                  setFormData({ ...formData, qrcode });
                }}
                className="AppInput"
                LeftIcon={
                  <img
                    src={require("@imgs/LeftImageIcon.png")}
                    className="LeftIcon"
                    alt={"LeftIcon"}
                  />
                }
              />
            </Col>
          </Row>
        )}
        {/* Qrcode type section btns  */}
        <Row className="mt-4">
          <Col className=" me-3 text-piece">{t("Typedecode")} *</Col>
        </Row>
        <Row>
          <Col
            xs={12}
            md={8}
            lg={8}
            className=" d-flex  flex-wrap justify-content-lg-start justify-content-center my-3 "
            style={{ maxWidth: "750px" }}
          >
            {/*text */}

            {/* url */}
            <CodeTypeButton
              labelText={t("Siteweb")}
              fetching={false}
              customStyles="btnCode justify-content-center  d-flex align-items-center"
              onClick={() => {
                setFormData({ ...formData, type: "url", content: "" });
                setMultyMedia(null);
              }}
              activeStyle={formData?.type === "url"}
              LeftComponent={() => (
                <img
                  src={
                    formData?.type === "url"
                      ? require("@imgs/whitelink.png")
                      : require("@imgs/webIcon.png")
                  }
                  className="CodeTypeIconUrl"
                  alt={"imageComponent"}
                />
              )}
            />
            {/* Facebook */}
            <CodeTypeButton
              labelText={"Facebook"}
              fetching={false}
              customStyles="btnCode justify-content-center  d-flex align-items-center"
              onClick={() => {
                setFormData({
                  ...formData,
                  type: "socialFacebook",
                  content: "",
                });
                setMultyMedia(null);
              }}
              activeStyle={formData?.type === "socialFacebook"}
              LeftComponent={() => (
                <img
                  src={
                    formData?.type === "socialFacebook"
                      ? require("@imgs/whitefacebook-logo.png")
                      : require("@imgs/fbIcon.png")
                  }
                  className="CodeTypeIconFacebook"
                  alt={"imageComponent"}
                />
              )}
            />
            {/* Instagram */}
            <CodeTypeButton
              labelText={"Instagram"}
              fetching={false}
              customStyles="btnCode justify-content-center  d-flex align-items-center"
              onClick={() => {
                setFormData({
                  ...formData,
                  type: "socialInstagram",
                  content: "",
                });
                setMultyMedia(null);
              }}
              activeStyle={formData?.type === "socialInstagram"}
              LeftComponent={() => (
                <img
                  src={
                    formData?.type === "socialInstagram"
                      ? require("@imgs/whiteinstagram-logo.png")
                      : require("@imgs/instaIcon.png")
                  }
                  className="CodeTypeIconinstagram"
                  alt={"imageComponent"}
                />
              )}
            />
            {/* Linkedin */}
            <CodeTypeButton
              labelText={"Linkedin"}
              fetching={false}
              customStyles="btnCode justify-content-center  d-flex align-items-center"
              onClick={() => {
                setFormData({
                  ...formData,
                  type: "socialLinkedin",
                  content: "",
                });
                setMultyMedia(null);
              }}
              activeStyle={formData?.type === "socialLinkedin"}
              LeftComponent={() => (
                <img
                  src={
                    formData?.type === "socialLinkedin"
                      ? require("@imgs/whitelinkedin.png")
                      : require("@imgs/inIcon.png")
                  }
                  className="CodeTypeIconlikdin"
                  alt={"imageComponent"}
                />
              )}
            />
            {/* WhatsApp */}
            <CodeTypeButton
              labelText={"WhatsApp"}
              fetching={false}
              customStyles="btnCode justify-content-center  d-flex align-items-center"
              onClick={() => {
                setFormData({
                  ...formData,
                  type: "socialWhatsApp",
                  content: "",
                });
                setMultyMedia(null);
              }}
              activeStyle={formData?.type === "socialWhatsApp"}
              LeftComponent={() => (
                <img
                  src={
                    formData?.type === "socialWhatsApp"
                      ? require("@imgs/Whitewhatsapp.png")
                      : require("@imgs/whatsappIcon.png")
                  }
                  className="CodeTypeIconWhatsapp"
                  alt={"imageComponent"}
                />
              )}
            />
            {/* Url de Paiement */}
            <CodeTypeButton
              labelText={t("UrldePaiement")}
              fetching={false}
              customStyles="btnCode justify-content-center  d-flex align-items-center"
              onClick={() => {
                setFormData({ ...formData, type: "urlPayment", content: "" });
                setMultyMedia(null);
              }}
              activeStyle={formData?.type === "urlPayment"}
              LeftComponent={() => (
                <img
                  src={
                    formData?.type === "urlPayment"
                      ? require("@imgs/whitepay-card.png")
                      : require("@imgs/cardIcon.png")
                  }
                  className="CodeTypeIconpay"
                  alt={"imageComponent"}
                />
              )}
            />
            {/* Youtube */}
            <CodeTypeButton
              labelText={"Youtube"}
              fetching={false}
              customStyles="btnCode justify-content-center  d-flex align-items-center"
              onClick={() => {
                setFormData({
                  ...formData,
                  type: "socialYoutube",
                  content: "",
                });
                setMultyMedia(null);
              }}
              activeStyle={formData?.type === "socialYoutube"}
              LeftComponent={() => (
                <img
                  src={
                    formData?.type === "socialYoutube"
                      ? require("@imgs/youtubeWhiteIcon.png")
                      : require("@imgs/youtubeIcon.png")
                  }
                  className="CodeTypeIconyoutube"
                  alt={"imageComponent"}
                />
              )}
            />
            {/* Apps mobiles */}
            <CodeTypeButton
              labelText={t("Appsmobiles")}
              fetching={false}
              customStyles="btnCode justify-content-center  d-flex align-items-center"
              onClick={() => {
                setFormData({ ...formData, type: "urlApp", content: "" });
                setMultyMedia(null);
              }}
              activeStyle={formData?.type === "urlApp"}
              LeftComponent={() => (
                <img
                  src={
                    formData?.type === "urlApp"
                      ? require("@imgs/whitestores.png")
                      : require("@imgs/storesIcon.png")
                  }
                  className="CodeTypeIconstores"
                  alt={"imageComponent"}
                />
              )}
            />
            {/* Photo */}
            <CodeTypeButton
              labelText={t("Photo")}
              fetching={false}
              customStyles="btnCode justify-content-center  d-flex align-items-center"
              onClick={() => {
                setFormData({ ...formData, type: "image", content: "" });
                setMultyMedia(null);
              }}
              activeStyle={formData?.type === "image"}
              LeftComponent={() => (
                <img
                  src={
                    formData?.type === "image"
                      ? require("@imgs/whitegallery.png")
                      : require("@imgs/imageIcon.png")
                  }
                  className="CodeTypeIconImage"
                  alt={"imageComponent"}
                />
              )}
              info={t("infoPhoto")}
            />
            {/* Vidéo  */}
            <CodeTypeButton
              labelText={t("Video")}
              fetching={false}
              customStyles="btnCode justify-content-center  d-flex align-items-center"
              onClick={() => {
                setFormData({ ...formData, type: "video", content: "" });
                setMultyMedia(null);
              }}
              activeStyle={formData?.type === "video"}
              LeftComponent={() => (
                <img
                  src={
                    formData?.type === "video"
                      ? require("@imgs/whitevideo-call.png")
                      : require("@imgs/videoIcon.png")
                  }
                  className="CodeTypeIconVideo"
                  alt={"imageComponent"}
                />
              )}
              info={t("infoVideo")}
            />
            {/* Audio */}
            <CodeTypeButton
              labelText={t("Audio")}
              fetching={false}
              customStyles="btnCode justify-content-center  d-flex align-items-center"
              onClick={() => {
                setFormData({ ...formData, type: "audio", content: "" });
                setMultyMedia(null);
              }}
              activeStyle={formData?.type === "audio"}
              LeftComponent={() => (
                <img
                  src={
                    formData?.type === "audio"
                      ? require("@imgs/whitemicrophone.png")
                      : require("@imgs/microIcon.png")
                  }
                  className="CodeTypeIconAudio"
                  alt={"imageComponent"}
                />
              )}
              info={t("infoAudio")}
            />
            {/* PDF */}
            <CodeTypeButton
              labelText={"PDF"}
              fetching={false}
              customStyles="btnCode justify-content-center  d-flex align-items-center"
              onClick={() => {
                setFormData({ ...formData, type: "pdf", content: "" });
                setMultyMedia(null);
              }}
              activeStyle={formData?.type === "pdf"}
              LeftComponent={() => (
                <img
                  src={
                    formData?.type === "pdf"
                      ? require("@imgs/whitepdf.png")
                      : require("@imgs/pdfIcon.png")
                  }
                  className="CodeTypeIconpdf"
                  alt={"imageComponent"}
                />
              )}
              info={t("infoPdf")}
            />
          </Col>
        </Row>
        {/* code multymedia */}
        {formData?.type && (
          <Row>
            {/* urls */}
            {(formData?.type === "url" ||
              formData?.type === "urlPayment" ||
              formData?.type.includes("social")) && (
              <Col xs={12} md={8} lg={4}>
                <AppInput
                  labelText={
                    formData?.type === "url"
                      ? t("Lien") + t("Siteweb")
                      : formData?.type === "socialFacebook"
                      ? t("Lien") + "Facebook"
                      : formData?.type === "socialInstagram"
                      ? t("Lien") + "Instagram"
                      : formData?.type === "socialLinkedin"
                      ? t("Lien") + "Linkedin"
                      : formData?.type === "socialYoutube"
                      ? t("Lien") + "Youtube"
                      : formData?.type === "socialWhatsApp"
                      ? t("Lien") + "WhatsApp"
                      : formData?.type === "urlPayment"
                      ? t("UrldePaiement")
                      : t("Contenuducode")
                  }
                  placeholder={
                    formData?.type === "urlPayment"
                      ? t("urlPay")
                      : // formData?.type.includes("social")
                        t("urlSocial")
                  }
                  value={formData.content}
                  onChange={(content) => setFormData({ ...formData, content })}
                  required
                  className="AppInput"
                  LeftIcon={
                    <img
                      src={require("@imgs/webIcon.png")}
                      className="LeftIcon"
                      alt={"LeftIcon"}
                    />
                  }
                />
              </Col>
            )}
            {/* multy media */}
            {(formData?.type === "audio" ||
              formData?.type === "image" ||
              formData?.type === "pdf" ||
              formData?.type === "video") && (
              <Col xs={12} md={8} lg={4}>
                <MultyMediaInput
                  type={formData?.type}
                  labelText={t("Contenuducode")}
                  placeholder={
                    formData?.type === "image"
                      ? t("formatImage")
                      : formData?.type === "audio"
                      ? t("Enformatmp3")
                      : formData?.type === "pdf"
                      ? t("formatPdf")
                      : formData?.type === "video"
                      ? t("formatMp4")
                      : ""
                  }
                  value={MultyMedia}
                  setFileUrl={setMultyMedia}
                  setFileUrlFromApi={(content) => {
                    setFormData({ ...formData, content });
                  }}
                  className="AppInput"
                  LeftIcon={
                    <img
                      src={require("@imgs/LeftImageIcon.png")}
                      className="LeftIcon"
                      alt={"LeftIcon"}
                    />
                  }
                  accept={
                    formData?.type === "image"
                      ? "image/*"
                      : formData?.type === "video"
                      ? "video/*"
                      : formData?.type === "audio"
                      ? ".ogg,.mp3,wav,"
                      : formData?.type === "pdf"
                      ? ".pdf"
                      : ""
                  }
                />
              </Col>
            )}
            {/* stores */}
            {formData?.type === "urlApp" && (
              <>
                <Col xs={12} md={8} lg={4}>
                  <AppInput
                    info={"info"}
                    placeholder={t("googlestoreHeader")}
                    labelText={t("googlestorePlaceholder")}
                    value={formData.playStoreUrl}
                    onChange={(playStoreUrl) =>
                      setFormData({ ...formData, playStoreUrl })
                    }
                    required
                    className="AppInput"
                  />
                </Col>
                <Col xs={12} md={8} lg={4}>
                  <AppInput
                    placeholder={t("applestorePlaceholder")}
                    labelText={t("applestoreHeader")}
                    value={formData.appStoreUrl}
                    onChange={(appStoreUrl) =>
                      setFormData({ ...formData, appStoreUrl })
                    }
                    required
                    info={"info"}
                    className="AppInput"
                  />
                </Col>
              </>
            )}
          </Row>
        )}

        {/* email & phone & location */}
        <Row>
          <Col xs={12} md={8} lg={4}>
            <AppInput
              info={"info"}
              labelText={t("Email")}
              value={formData.email}
              onChange={(email) => setFormData({ ...formData, email })}
              // required
              type="email"
              className="AppInput"
            />
          </Col>
          <Col xs={12} md={8} lg={4}>
            <PhoneInput
              labelText={t("Telephone")}
              info={"info"}
              // required
              type="tel"
              id="user-phone"
              value={formData.phone}
              country={formData.country}
              onCountryChange={(country) => {
                setFormData({
                  ...formData,

                  country,
                });
              }}
              onChange={(phone) => {
                setFormData({
                  ...formData,
                  phone,
                });
                console.log("phone", phone);
              }}
            />
          </Col>
          <Col xs={12} md={8} lg={4}>
            <GooglePlaceInput
              labelText={t("Localisationducode")}
              info={"info"}
              name="address"
              placeholder={t("Selectionneradresse")}
              value={formData.address1}
              onChange={(a) => {
                getCoordinates(a);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} lg={4} className="">
            <AiButton
              text={t("Créer un code avec l’IA")}
              fetching={false}
              disabled={fetching}
              black
              onClick={() => {
                // navigate("/");
                setIsModalOpen(true);
              }}
              RightComponent={() => (
                <img
                  src={require("@imgs/Ai.png")}
                  className="RightAiIcon"
                  alt={"RightIcon"}
                />
              )}
            />
          </Col>
        </Row>
      </div>

      {/* save & cancel */}
      <Row className="d-flex mt-5 me-3 pb-5 justify-content-end mt-4">
        <Col xs={12} md={8} lg={3} className=" maxBtnWith ">
          <ButtonOutLigned
            text={t("Annuler")}
            fetching={false}
            disabled={fetching}
            black
            onClick={() => {
              navigate("/");
            }}
          />
        </Col>
        <Col xs={12} md={8} lg={3} className=" maxBtnWith  mt-3 mt-lg-0">
          <Button
            text={t("Creerlecode")}
            fetching={fetching}
            onClick={() => {
              const {
                name,
                thumbnail,
                playStoreUrl,
                appStoreUrl,
                phone,
                email,
                type,
                content,
                location,
                address1,
                qrcode,
              } = formData;
              /**
               * 
               *     location,
        address1: addr,
               */
              // form Validations
              const isValidForm = validateFields(
                [
                  {
                    name: t("nomduCode"),
                    value: formData.name,
                    type: "text",
                  },
                  ...(QrCodeTypeSelection
                    ? []
                    : [
                        {
                          name: t("QrCodeImage"),
                          value: logoQR,
                          type: "text",
                        },
                      ]),
                  {
                    name: t("LogoduCode"),
                    value: logo,
                    type: "text",
                  },
                  { name: t("TypedeCode"), value: formData.type, type: "text" },
                  //validation of code type  link
                  type &&
                    (type === "url" ||
                      type === "urlPayment" ||
                      type.includes("social")) && {
                      name: "Url",
                      value: content,
                      type: "text",
                    },

                  // type &&
                  //   type.includes("social") && {
                  //     name: t("Contenuducode"),
                  //     value: content,
                  //     type: "text",
                  //   },
                  ...(type === "audio" ||
                  type === "image" ||
                  type === "pdf" ||
                  type === "video"
                    ? [
                        {
                          name:
                            type === "image"
                              ? "IMAGE"
                              : type === "audio"
                              ? "AUDIO"
                              : type === "pdf"
                              ? "PDF"
                              : type === "video"
                              ? "VIDEO"
                              : t("Contenuducode"),
                          value: MultyMedia,
                          type: "text",
                        },
                        // {
                        //   name: t("ContenuTelechargementError"),
                        //   value: content,
                        //   type: "text",
                        // },
                      ]
                    : []),
                  type &&
                    type === "urlApp" && {
                      name: t("PlayStore"),
                      value: playStoreUrl,
                      type: "text",
                    },
                  type &&
                    type === "urlApp" && {
                      name: t("AppleStore"),
                      value: appStoreUrl,
                      type: "text",
                    },
                ],
                t
              );
              isValidForm &&
                createCode(
                  {
                    name,
                    thumbnail,
                    ...(!QrCodeTypeSelection && { qrcode }),
                    ...(type === "urlApp" && {
                      playStoreUrl,
                      appStoreUrl,
                    }),
                    ...(phone && { phone }),
                    ...(email && { email }),
                    type: type?.includes("social") ? "url" : type,
                    content,
                    ...(address1 && { address1: address1?.label }),
                    ...(location && { location: location }),
                  },
                  () => {
                    dispatch(deleteCode());
                  }
                );

              //TODO REMEMBER TO TEST FOR THE DATA THAT NOT UPLOADED
              // TODO if isValidForm && do somthing
            }}
          />
        </Col>
      </Row>

      <CropperModal
        // modal trigger
        showModal={showCropper}
        translation={t}
        // modal trigger callback
        setShowModal={(val) => {
          setShowCropper(val);
        }}
        //cropper aspect (cropping demontion )
        aspect={1}
        //image to crop
        selectedimage={OrigineImageToCrop}
        //callback fire when the user complite cropping
        onDoneCropping={async (file) => {
          // //preview the image from the cropper
          setLogo(file);
        }}
        setImageUrlFromApi={(thumbnail) => {
          setFormData({ ...formData, thumbnail });
        }}
      />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Popup Alert"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
            },
            content: {
              width: "70%",
              maxWidth: "800px",
              margin: "auto",
              borderRadius: "10px",
              padding: "0",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
              border: "none",
              maxHeight: "1000px",
            },
          }}
        >
          {modalContent()}

          {/* {ScrollDownIndicator()} */}
        </Modal>
      )}
    </Container>
  );
};
