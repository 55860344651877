import React, { useEffect, useState, useContext } from "react";
import {
  Col,
  Container,
  FormGroup,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { api } from "@globals/axiosConfig";
import { LoadingContext } from "@contexts/LoadingContext";
import Switch from "./components/Switch";
import { Label } from "reactstrap";
import useCreateManagers from "./hooks/useCreateManagers";
import ErrorLabel, { usePasswordCheck } from "./hooks/usePasswordCheck";

export default () => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [{ fetching, error }, createManagers] = useCreateManagers();
  const [state, setState] = useState({ $q: "", account_enabled: false });
  const [password, setPassword] = useState("");
  const { isValid: passwordValid } = usePasswordCheck(password || "");
  const [openPassword, setOpenPassword] = useState(false);
  const invalidForm =
    !state.name || !passwordValid || !password || !state.email;
  const onCreate = () => {
    const payload = {
      name: state.name,
      email: state.email,
      password: password,
      role: "manager",
      account_enabled: state.account_enabled,
    };
    createManagers(payload);
  };
  const handleChange = (event) => {
    event.persist();
    const name = event.target.name;
    const is_checked = event.target.checked;
    setState({ ...state, [name]: is_checked });
  };
  return (
    <Container fluid className="  text-center h-100">
      <Row>
        <Col className="d-flex justify-content-start mt-4 ms-3">
          <h1 className="headerText">{t("Nouvelutilisateur")}</h1>
        </Col>
      </Row>
      {/* users form  */}
      <Row className="mt-4 ms-1  ">
        <Col xs={12} md={4} className="">
          <Row>
            <Col className="d-flex justify-content-start mt-3">
              <div className="FormTitle">{t("NometPrenom")}</div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-star mt-1">
              <div className="textFormContainer">
                <input
                  id="namenewlsuser"
                  value={state.name}
                  type="text"
                  onChange={(t) => setState({ ...state, name: t.target.value })}
                  className="TabFormInput"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start mt-3">
              <div className="FormTitle">{t("Email")}</div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-star mt-1">
              <div className="textFormContainer">
                <input
                  id="emailnewlsuser"
                  label="Email"
                  type="email"
                  onChange={(t) =>
                    setState({ ...state, email: t.target.value })
                  }
                  className="TabFormInput"
                  value={state.email}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start mt-3">
              <div className="FormTitle">{t("Motdepasse")}</div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-star flex-column mt-1">
              <div className="textFormContainer">
                <div
                  onClick={() => setOpenPassword(!openPassword)}
                  className="input-addon"
                >
                  <img
                    className="input-addon-img"
                    alt="+"
                    src={
                      openPassword
                        ? require("@imgs/eye.png")
                        : require("@imgs/close-eye.png")
                    }
                  />
                </div>
                <input
                  type={openPassword ? "text" : "password"}
                  value={password}
                  onChange={(t) => setPassword(t.target.value)}
                  id="passwordinput"
                  className="TabFormInput"
                />
              </div>
              {password !== "" && <ErrorLabel password={password} />}
            </Col>
          </Row>
        </Col>
      </Row>
      {/* activate user */}
      <Row>
        <Col className=" d-flex justify-content-start mt-4 ms-3" xs="12" sm="4">
          <FormGroup>
            <Label>{  t("Activer") }</Label>
            <Switch
              checked={state.account_enabled}
              onChange={(e) => {
                handleChange(e);
              }}
              name="account_enabled"
            />
          </FormGroup>
        </Col>
      </Row>
      {/* <Row>
        <Col className=" d-flex justify-content-start mt-2 ms-3" xs="12" sm="4">
          <FormGroup>
            <Label>Création de code</Label>
            <Switch
              // checked={state.account_enabled}
              onChange={(e) => {
                // handleChange(e);
              }}
              name="account_enabled"
            />
          </FormGroup>
        </Col>
      </Row> */}
      <Row className="mt-5 ms-2 pb-5 d-flex justify-content-end ">
        <Col xs={12} md={4} lg={2} className="d-flex justify-content-end ">
          <div
            onClick={() => {
              navigate("/Users");
            }}
            className="btnCancel d-flex justify-content-center align-items-center"
          >
            {t("Annuler")}
          </div>
        </Col>
        <Col xs={12} md={4} lg={2} className="d-flex justify-content-end ">
          <div
            onClick={() => {
              !invalidForm && onCreate();
            }}
            className="btnSave d-flex justify-content-center align-items-center"
          >
            {t("Envoyerinvitation")}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
