import React, { useState } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import Button from "@components/Button";
import "./styles.css";
import { useModal } from "@/contextProviders/ModalContext";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useUpdateCodePro from "../hooks/useUpdateCodePro";
import { api } from "@globals/axiosConfig";
import Dropdown from "react-bootstrap/Dropdown";
import useUpdateDeleteCode from "../hooks/useUpdateDeleteCode";
import { deleteCode as deletecodefromredux } from "../../slice";
import { useTranslation } from "react-i18next";
import ModalInfo from "@components/ModalInfo";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

export default () => {
  //TODO BEFORE WEE TEST FOR THIS TEXT "Nombre codes Pro disponibles est: 0" FOR REDIRECTION TO THE PAYMENT PAGE
  //TODO INSIST FOR BETTER WAY TO DO IT
  const { t } = useTranslation();
  const code = useSelector((state) => state?.home?.code);
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const [fetching, updateCodePro] = useUpdateCodePro(code?.id);
  const dispatch = useDispatch();

  //  const [{ fetching:fetchingUpdate, error }, updateCode] = useUpdateCode(code?.id);
  const [fetchingDelete, deleteCode] = useUpdateDeleteCode(code?.id);

  const handleOpenPopup = () => {
    openModal(
      t("Confirmation"),
      t("Est vous sûr d'activer le mode pro?"),
      () => {
        updateCodePro(
          () => {
            navigate(`/NewMessage`);
            closeModal();
          },
          (error) => {
            if (
              error === "Nombre codes Pro disponibles est: 0" ||
              error ===
                "Vous n'êtes pas autorisés à envyer des Messages (il faut que tous les codes soient PRO)." ||
              error === "Nombre codes Pro disponibles est: 0" ||
              error === "Number of Pro codes available is: 0" ||
              error === "Verfügbare Pro-Codes: 0" ||
              error === "Numero di codici Pro disponibili: 0" ||
              error === "Códigos Pro disponibles: 0" ||
              error === "Code expired." ||
              error === "Code expiré." ||
              error === "Code abgelaufen." ||
              error === "Codice scaduto." ||
              error === "Código expirado."
            ) {
              setShow(true);
            }
            closeModal();
          }
        );
      },
      () => {
        console.log("No clicked");
      }
    );
  };
  const [show, setShow] = useState(false);
  return (
    <Container className=" pt-3  text-center h-100">
      <Row>
        <Col
          xs={12}
          md={6}
          className=" order-md-1 order-2  d-flex justify-content-center justify-content-md-start  align-items-center align-items-md-start   "
        >
          <a
            href={`${api}${code?.qrcode?.image_download_url}`}
            className="newBtn"
          >
            {/* {t("TelechargerleQRCode")} */}
            <Image
              src={`${api}${code?.qrcode?.image_download_url}`}
              className="qrCodeLink"
              thumbnail
            />
          </a>
        </Col>
        <Col className=" order-1 order-md-2 me-3 d-flex justify-content-end align-items-start">
          <Dropdown
            drop={"start"}
            className="d-inline mx-2 ms-auto "
            autoClose="true"
          >
            <Dropdown.Toggle as={CustomToggle} id="dropdown-autoclose-outside">
              <img
                src={require("@imgs/dots.png")}
                className="dotsIcon cursor-pointer"
                alt={"imageComponent"}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item
                className="dropdownItem"
                onClick={() => {
                  navigate(`/NewMessage`);
                }}
              >
                Messages
              </Dropdown.Item> */}

              <Dropdown.Item
                className="dropdownItem"
                // onClick={() => {
                //   console.log("click =>    [[");
                // }}
                href={`${code?.uniq_qrcode}`}
                target="_blank"
              >
                {t("Visualisersingle")}
              </Dropdown.Item>
              <div className="DropdownDivider" />

              <Dropdown.Item
                className="dropdownItem"
                href={`${api}${code?.qrcode?.image_download_url}`}
              >
                {t("Telechargerlecode")}
              </Dropdown.Item>
              <div className="DropdownDivider" />

              <Dropdown.Item
                className="dropdownItem text-danger"
                onClick={() => {
                  //Api delete code required to be added to the collection
                  deleteCode(() => {
                    dispatch(deletecodefromredux());
                    navigate(`/`);
                    //TODO DELETE CODE FROM CODE
                  });
                }}
              >
                {t("Supprimerlecode")}
              </Dropdown.Item>
              {/* <div className="DropdownDivider" /> */}

              {/* <Dropdown.Item
                className="dropdownItem"
                onClick={() => {
                  handleOpenPopup();
                }}
              >
                Désactiver le mode pro
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <h1 className="mt-3 textActiverlemodepro">{t("Activerlemodepro")}</h1>
      <h2 className=" mb-5 textActiverlemodepro2">{t("etenvoyer")} </h2>
      <div className="NoProContainer  ">
        <Image
          src={require("@imgs/notProModeIcon.png")}
          className="EmptyProIcon"
          thumbnail
        />
      </div>
      <div className="NoProButtonContainer pt-4 mb-4 ">
        <Button
          text={t("Activerlemodepro")}
          customStyles="btnHightA"
          fetching={fetching}
          onClick={() => {
            handleOpenPopup();
          }}
        />
      </div>
      {show && (
        <ModalInfo
          title={t("Envoyerdesnotifications")}
          description={t("Choisissez1ouplusieursQRcodespro")}
          description1={t("Entrezvotremodedepaiement")}
          textOk={t("souscrire")}
          textNok={t("Retourner")}
          countingCercle={1}
          countingCercle2={2}
          onOkModal={() => {
            navigate("/plans");
          }}
          isOpen={show}
          onCloseModal={() => setShow(false)}
        />
      )}
    </Container>
  );
};
