import React from "react";
import Form from "react-bootstrap/Form";
import "./styles.css";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export default ({
  labelText,
  placeholder,
  required,
  type,
  customStyles,
  as,
  onChange,
  value,
  info,
  infoPlacement,
  id,
  ...props
}) => {
  return (
    <Form.Group id={id} className="mb-3">
      {labelText && (
        <OverlayTrigger
          // key={infoPlacement || "top"}
          placement={infoPlacement || "right"}
          overlay={info ? <Tooltip>{info}</Tooltip> : <></>}
        >
          <Form.Label className={"labelStyle  " + (info && "pointercursor")}>
            {labelText + (required ? " *" : info ? " (i)" : "")}
          </Form.Label>
        </OverlayTrigger>
      )}
      <InputGroup>
        {props?.LeftIcon && (
          <InputGroup.Text className="InputAddon">
            {props?.LeftIcon}
          </InputGroup.Text>
        )}
        <Form.Control
          value={value}
          {...props}
          onChange={(e) => {
            onChange && onChange(e.target.value ? e.target.value : "");
          }}
          className={
            "AppInput" +
            (type === "password" && value ? " passWordStyles" : "") +
            (customStyles ? " " + customStyles : "")
          }
          type={type || "text"}
          placeholder={placeholder}
          as={as}
        />
        {props?.RightIcon && (
          <InputGroup.Text className="InputAddonRight">
            {props?.RightIcon}
          </InputGroup.Text>
        )}
      </InputGroup>
    </Form.Group>
  );
};
