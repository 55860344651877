import React, { useState, useRef, useEffect } from "react";
import AppInput from "@components/AppInput";
import "./styles.css";
import useUploadFile from "@hooks/useUploadFile";

const ImageSelector = ({
  setImageUrl,
  setImageUrlFromApi,
  value,
  disabled,
  isQrCode = false,
  ...props
}) => {
  const fileInputRef = useRef(null);
  const { url, fetching, error, handleUpload } = useUploadFile(isQrCode);
  useEffect(() => {
    url && setImageUrlFromApi(url);
  }, [url]);
  useEffect(() => {
    if (error) {
      console.log("error from useEffect", error);
      setImageUrl("");
    }
  }, [error]);
  const handleImageInputChange = (event) => {
    const file = event.target.files[0];

    const url = URL.createObjectURL(file);
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl(reader.result);
      //    setFileUrl({ file, url });
      handleUpload(file);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = () => {
    setImageUrl("");
    setImageUrlFromApi("");
  };

  const handleCustomInputChange = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      {value ? (
        <div className="image-selector-container">
          <img
            src={value}
            alt="Selected"
            style={{ maxWidth: "100%", maxHeight: "150px" }}
          />
          <img
            className="btnRmv"
            src={require("@imgs/removeItem.png")}
            onClick={() => {
              !disabled && handleImageRemove();
            }}
          />
        </div>
      ) : (
        <div className="cursor-pointer">
          <AppInput
            onClick={() => {
              !disabled && handleCustomInputChange();
            }}
            {...props}
            readOnly
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageInputChange}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageSelector;
