import { useContext, useState } from "react";
import axios from "@globals/axiosConfig";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "@contexts/LoadingContext";

// endpoints
import { deleteCodefromProByIdUrl } from "../CodeMessagesApi";

export default function useUpdateCodeNotPro(id) {
  const [fetchingDisable, setFetching] = useState(false);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const navigate = useNavigate();
  async function updateCodeNotPro(cb = () => {}) {
    try {
      setFetching(true);
           showLoader();
      const rep = await axios.post(deleteCodefromProByIdUrl(id));
      setFetching(false);
      cb(rep);
    } catch (error) {
    } finally {
            hideLoader();

      setFetching(false);
    }
  }

  return [fetchingDisable, updateCodeNotPro];
}
