import { useContext, useState } from "react";
import { LoadingContext } from "@contexts/LoadingContext";
import axios from "@globals/axiosConfig";
import { toast } from "react-toastify";
export default () => {
  const [errorPro, setErrorPro] = useState({ ok: true });
  const [successPro, setSuccessPro] = useState(null);
  const { showLoader, hideLoader } = useContext(LoadingContext);

  async function createSubscribePro(payload, cb) {
    try {
      showLoader();
      const rep = await axios.post(
        `/manager/societies/subscription/pro`,
        payload
      );
      setSuccessPro(rep?.data);
      cb && cb();
      toast.success(rep?.data.message);
    } catch (error) {
      setErrorPro(error);
    if (error?.response?.data?.message) {
      // toast.error(error?.response?.data?.message);
    }
    } finally {
            hideLoader();

    }
  }

  return [
    { errorPro, setErrorPro, successPro, setSuccessPro },
    createSubscribePro,
  ];
};
