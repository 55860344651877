import { useContext, useState } from "react";
import { LoadingContext } from "@contexts/LoadingContext";
import axios from "@globals/axiosConfig";
import { toast } from "react-toastify";

export default () => {
  const [error, setError] = useState({ ok: true });
  const [success, setSuccess] = useState(null);
  const { showLoader, hideLoader } = useContext(LoadingContext);

  async function Unsubscribe(type) {
    try {
      showLoader();
      const rep = await axios.delete(`/manager/societies/subscribe/${type}`);
      setSuccess(rep?.data);
      toast.success(rep?.data.message);
    } catch (error) {
      setError(error);
    } finally {
      if (error?.response?.data?.message) {
        // toast.error(error?.response?.data?.message);
      }
      hideLoader();
    }
  }

  return [{ error, setError, success, setSuccess }, Unsubscribe];
};
