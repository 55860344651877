import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import AppInput from "@components/AppInput";
import Button from "@components/Button";
import MessageTypeButton from "@components/MessageTypeButton";
import "./styles.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import useUpdateCodeNotPro from "../hooks/useUpdateNotCode";
import useUpdateDeleteCode from "../hooks/useUpdateDeleteCode";
import useGetHistory from "../hooks/useGetHistory";
import useResendMessage from "../hooks/useResendMessage";
import useSendMessage from "../hooks/useSendMessage";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "@/contextProviders/ModalContext";
import { api } from "@globals/axiosConfig";
import AudioRecorder from "@components/AudioRecorder";
import Video from "@components/Video";
import Audio from "@components/Audio";
import Pdf from "@components/Pdf";
import useUploadFile from "@hooks/useUploadFile";
import CustomMultiSelect from "@components/CustomMultiSelect";
import moment from "moment";
import { deleteCode as deletecodefromredux } from "../../slice";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));
const CustomToggle2 = React.forwardRef(({ children, onClick }, ref) => (
  <div
    className="audioSelectDrop"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

//urlregix
var urlRegex = /^(http|https):\/\//;

export default () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  // const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const code = useSelector((state) => state?.home?.code);
  //hooks
  const [fetchingDisable, updateCodeNotPro] = useUpdateCodeNotPro(code?.id);
  const [fetchingDelete, deleteCode] = useUpdateDeleteCode(code?.id);
  const [fetchingHistory, historyData, getHistory] = useGetHistory(code?.id);
  const [pagination, setPagination] = useState(10);
  const [count, setCount] = useState(0);

  const [{ fetchingResend, errorResend }, resendMessage] = useResendMessage();
  const [fetchingSend, sendMessage] = useSendMessage(code?.id);
  useEffect(() => {
    getHistory((ListData, top, count) => {
      setCount(count);
      setPagination(top);
    });
  }, [code]);
  const { openModal, closeModal } = useModal();

  const handleOpenPopup = () => {
    openModal(
      t("Confirmation"),
      t("Desactivercode"),
      () => {
        updateCodeNotPro(() => {
          navigate(`/ActivateProMode`);
          closeModal();
          //TODO SEE IF YOU CAN ACTIVATE THE CODE HERE ==== set is_pro for true here
        });
      },
      () => {}
    );
  };

  const [formData, setFormData] = useState({
    content: "",
    attachements: [],
    codes: null,
    youtube_url: null,
  });
  const [state, setState] = useState({});

  //multymedia Logic
  const { url, fetching, error, handleUpload } = useUploadFile();
  const [LocalFileUrl, setLocalFileUrl] = useState();
  const [openAudioSave, setopenAudioSave] = useState();
  const [openAudioUpload, setopenAudioUpload] = useState();
  const [Itemid, setItemid] = useState("");
  const [plusCodes, setplusCodes] = useState(false);
  const [history, sethistory] = useState(false);
  const renderFiles = (value, type) => {
    switch (type) {
      case "image":
        return (
          <img
            src={value}
            alt="Selected"
            style={{ maxWidth: "100%", maxHeight: "200px" }}
          />
        );
      case "video":
        return <Video videoUrl={value} />;
      case "audio":
        return <Audio audioUrl={value} />;
      case "pdf":
        return <Pdf pdfUrl={value} />;

      default:
        return;
    }
  };

  useEffect(() => {
    if (url) {
      const localAttachments = formData?.attachements?.map(
        (val, insideMapIndex) => {
          if (insideMapIndex === formData?.attachements?.length - 1) {
            return {
              ...val,
              localfileUrl: LocalFileUrl,
              attachement: url,
            };
          }
          return val;
        }
      );

      setFormData({
        ...formData,
        attachements: localAttachments,
      });
    }
  }, [url]);

  const handleImageInputChange = (event, type) => {
    const file = event.target.files[0];
    if (event.target.files.length > 0) {
      //add file to ui handling
      if (type === "image" || type === "video") {
        if (formData?.attachements?.length === 1) {
          if (
            formData?.attachements.some(
              (attachment) =>
                attachment.type === "image" || attachment.type === "video"
            ) &&
            formData?.attachements[0].attachement &&
            formData?.attachements[0].localfileUrl
          ) {
            setFormData({
              ...formData,
              attachements: [
                ...formData?.attachements,
                {
                  type,
                  attachement: "",
                  localfileUrl: "",
                },
              ],
            });
          } else {
            setFormData({
              ...formData,
              attachements: [
                {
                  type,
                  attachement: "",
                  localfileUrl: "",
                },
              ],
            });
          }
        } else {
          setFormData({
            ...formData,
            attachements: [
              ...formData?.attachements,
              {
                type,
                attachement: "",
                localfileUrl: "",
              },
            ],
          });
        }
      }
      //pdf
      if (type === "pdf") {
        setFormData({
          ...formData,
          attachements: [
            {
              type: "pdf",
              attachement: "",
              localfileUrl: "",
            },
          ],
        });
      }
      //audio
      if (type === "audio") {
        setFormData({
          ...formData,
          attachements: [
            {
              type: "audio",
              attachement: "",
              localfileUrl: "",
            },
          ],
        });
      }
      //-----------------------
      const reader = new FileReader();
      reader.onloadend = () => {
        setLocalFileUrl(reader.result);
        handleUpload(file);
      };

      if (file) {
        reader.readAsDataURL(file);
      } else {
        console.log("----- nofile -----");
      }
      reader.onerror = (e) => {
        console.log("e", e);
      };
    }
  };
  const handleCustomInputChange = (accept, type) => {
    // fileInputRef.code.click();

    // document.getElementById("uploader").onChange = (e) =>{
    //   handleImageInputChange(e, type)}
    document.getElementById("uploader").accept = accept;
    document.getElementById("uploader").addEventListener("change", (e) => {
      handleImageInputChange(e, type);
    });
    document.getElementById("uploader").click();
  };
  //validate send
  const validateSend = () => {
    const { attachements, content } = formData;
    let validated = false;
    if (attachements?.length > 1) {
      validated = true;
    } else {
      if (attachements?.[0]?.attachement && attachements?.[0]?.type) {
        validated = true;
      } else {
        if (content) {
          validated = true;
        }
      }
    }

    return validated;
  };

  const emptyStates = () => {
    setLocalFileUrl(null);
    setopenAudioSave(false);
    setopenAudioUpload(false);
    setFormData({
      content: "",
      attachements: [],
      codes: null,
      youtube_url: null,
    });
    setplusCodes(false);
    setState({});
  };
  return (
    <Container fluid className="">
      {/* header name & actions drop down menu  */}
      <Row>
        <Col xs={7} md={6} className="  pt-3 ms-3  ">
          <div
            // className={
            //   isMobile ? "newCodeTextmessageMobile" : "newCodeTextmessage"
            // }
            className={isMobile ? "newCodeTextMobile" : "newCodeText"}
          >
            {t("Nouveaumessage")}
          </div>
        </Col>
        <Col className="col-2 ms-auto pt-3 me-3 d-flex justify-content-end align-items-center">
          <Dropdown
            drop={"start"}
            className="d-inline mx-2 ms-auto cursor-pointer"
            autoClose="true"
          >
            <Dropdown.Toggle as={CustomToggle} id="dropdown-autoclose-outside">
              <img
                src={require("@imgs/dots.png")}
                className="dotsIcon"
                alt={"imageComponent"}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                className="dropdownItem"
                onClick={() => {
                  navigate(`/MessageDetails`);
                }}
              >
                {t("Detailducode")}
              </Dropdown.Item>
              <div className="DropdownDivider" />

              <Dropdown.Item
                className="dropdownItem"
                // onClick={() => {
                //   console.log("click =>    [[");
                // }}
                href={`${code?.uniq_qrcode}`}
                target="_blank"
              >
                {t("Visualisersingle")}
              </Dropdown.Item>
              <div className="DropdownDivider" />

              <Dropdown.Item
                className="dropdownItem"
                href={`${api}${code?.qrcode?.image_download_url}`}
              >
                {t("Telechargerlecode")}
              </Dropdown.Item>
              <div className="DropdownDivider" />

              <Dropdown.Item
                className="dropdownItem text-danger"
                onClick={() => {
                  //Api delete code required to be added to the collection
                  deleteCode(() => {
                    dispatch(deletecodefromredux());
                    navigate(`/`);
                    //TODO DELETE CODE FROM CODE
                  });
                }}
              >
                {t("Supprimerlecode")}
              </Dropdown.Item>
              <div className="DropdownDivider" />

              <Dropdown.Item
                className="dropdownItem"
                onClick={() => {
                  handleOpenPopup();
                }}
              >
                {t("Desactiverlemodepro")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      {/* message text aria */}
      <Row className="mt-3  ">
        <Col className="ms-3 me-3">
          <AppInput
            placeholder={t("Tapezvotremessage")}
            customStyles={"AppInputNewMessage  pt-2 ps-2"}
            as="textarea"
            value={formData?.content}
            onChange={(content) => setFormData({ ...formData, content })}
          />
        </Col>
      </Row>
      {/* Pièce jointe text  */}
      <Row className="mt-1 ">
        <Col className="ms-3 me-3 text-piece1">{t("jointe")}</Col>
      </Row>
      {/* multymedia btns  */}
      <Row className=" mt0">
        <Col className=" ms-3 me-3 d-flex  flex-wrap justify-content-start  my-2">
          {/* video */}
          <MessageTypeButton
            info={t("infoVideo")}
            infoPlacement={"bottom"}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            active={formData?.attachements.some(
              (attachment) => attachment.type === "video"
            )}
            onClick={() => {
              handleCustomInputChange("video/*", "video");
            }}
            LeftComponent={() => (
              <img
                src={require("@imgs/videoIconBlack.png")}
                className="MessageTypeIconvideo"
                alt={"imageComponent"}
              />
            )}
          />
          {/* youtube */}
          <MessageTypeButton
            info={t("infoUrl")}
            infoPlacement={"bottom"}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            active={formData?.attachements[0]?.type === "youtube"}
            onClick={() => {
              setFormData({
                ...formData,
                attachements: [
                  {
                    type: "youtube",
                    attachement: "",
                    localfileUrl: "",
                  },
                ],
              });

              // handleCustomInputChange();
            }}
            LeftComponent={() => (
              <img
                src={require("@imgs/youtubeIconBlack.png")}
                className="MessageTypeIconYoutube"
                alt={"imageComponent"}
              />
            )}
          />
          {/* image */}
          <MessageTypeButton
            info={t("infoPhoto")}
            infoPlacement={"bottom"}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            active={formData?.attachements.some(
              (attachment) => attachment.type === "image"
            )}
            onClick={() => {
              handleCustomInputChange("image/*", "image");
            }}
            LeftComponent={() => (
              <img
                src={require("@imgs/imageIconblack.png")}
                className="MessageTypeIconImage"
                alt={"imageComponent"}
              />
            )}
          />
          {/* micro */}
          <MessageTypeButton
            info={t("infoAudio")}
            infoPlacement={"bottom"}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            active={formData?.attachements[0]?.type === "audio"}
            onClick={() => {
              setFormData({
                ...formData,
                attachements: [
                  {
                    type: "audio",
                  },
                ],
              });
              setopenAudioSave(false);
              setopenAudioUpload(false);
            }}
            LeftComponent={() => (
              <img
                src={require("@imgs/microImageBlack.png")}
                className="MessageTypeIconAudio"
                alt={"imageComponent"}
              />
            )}
          />
          {/* pdf */}
          <MessageTypeButton
            info={t("infoPdf")}
            infoPlacement={"bottom"}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            active={formData?.attachements[0]?.type === "pdf"}
            onClick={() => {
              handleCustomInputChange(".pdf", "pdf");
            }}
            LeftComponent={() => (
              <img
                src={require("@imgs/pdfIconBlack.png")}
                className="MessageTypeIconpdf"
                alt={"imageComponent"}
              />
            )}
          />
        </Col>
      </Row>
      {/* multymedia input */}
      <Row className="mt-3 ms-3  ">
        {formData?.attachements.length > 0 &&
          formData?.attachements?.map((item, index) => {
            if (item?.type === "audio") {
              if (openAudioSave) {
                return (
                  <Col xs={12} md={8} lg={8}>
                    <AudioRecorder
                      setFileAudio={(file, url) => {
                        setLocalFileUrl(url);
                        handleUpload(file);
                      }}
                      delete={() => {
                        const localAttachments = formData?.attachements?.filter(
                          (val, insideMapIndex) => index !== insideMapIndex
                        );
                        setTimeout(() => {
                          setFormData({
                            ...formData,
                            attachements: localAttachments,
                          });
                          setopenAudioSave(false);
                          setopenAudioUpload(false);
                        });
                      }}
                    />
                  </Col>
                );
              }

              if (openAudioUpload) {
                return (
                  item?.localfileUrl && (
                    <div className="messageItem">
                      {renderFiles(item?.localfileUrl, item?.type)}
                      <img
                        className="btnRmv"
                        src={require("@imgs/removeItem.png")}
                        onClick={() => {
                          setTimeout(() => {
                            setFormData({
                              ...formData,
                              attachements: [],
                            });
                            setopenAudioSave(false);
                            setopenAudioUpload(false);
                          });
                        }}
                      />
                    </div>
                  )
                );
              }
              return (
                <Col xs={12} md={8} lg={8}>
                  <Dropdown
                    drop={"down"}
                    className="d-inline mx-2 ms-auto "
                    autoClose="true"
                  >
                    <Dropdown.Toggle
                      as={CustomToggle2}
                      id="dropdown-autoclose-outside"
                    >
                      <div className="w-100 d-flex h-100  justify-content-between align-items-center">
                        <div className="drop-text ms-3">
                          {t("SelectionnerMSGaudio")}
                        </div>
                        <img
                          src={require("@imgs/arrow.png")}
                          className=" me-3  dotsIcon"
                          alt={"imageComponent"}
                        />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className="dropdownItem"
                        onClick={() => {
                          setopenAudioSave(true);
                        }}
                      >
                        {t("Enregistrer")}
                      </Dropdown.Item>
                      <div className="DropdownDivider" />

                      <Dropdown.Item
                        className="dropdownItem"
                        onClick={() => {
                          setopenAudioUpload(true);
                          setFormData({
                            content: "",
                            attachements: [],
                            codes: null,
                            youtube_url: null,
                          });
                          handleCustomInputChange(".ogg,.mp3,wav,", "audio");
                        }}
                      >
                        {t("Uploader")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              );
            } else {
              if (item?.type === "youtube") {
                return (
                  <Col xs={12} md={8} lg={8}>
                    <AppInput
                      placeholder={t("LienYoutube")}
                      value={item.attachement}
                      onChange={(url) => {
                        const localAttachments = formData?.attachements?.map(
                          (val, insideMapIndex) => {
                            if (insideMapIndex === index) {
                              return {
                                ...val,
                                localfileUrl: LocalFileUrl,
                                attachement: url,
                              };
                            }
                            return val;
                          }
                        );
                        setFormData({
                          ...formData,
                          attachements: localAttachments,
                        });
                      }}
                      required
                      className="AppInput"
                      LeftIcon={
                        <img
                          src={require("@imgs/webIcon.png")}
                          className="LeftIcon"
                          alt={"LeftIcon"}
                        />
                      }
                    />
                  </Col>
                );
              } else {
                return (
                  item?.localfileUrl && (
                    <div className="messageItem">
                      {renderFiles(item?.localfileUrl, item?.type)}
                      <img
                        className="btnRmv"
                        src={require("@imgs/removeItem.png")}
                        onClick={() => {
                          const localAttachments =
                            formData?.attachements?.filter(
                              (val, insideMapIndex) => index !== insideMapIndex
                            );
                          setTimeout(() => {
                            setFormData({
                              ...formData,
                              attachements: localAttachments,
                            });
                            setopenAudioSave(false);
                            setopenAudioUpload(false);
                          });
                        }}
                      />
                    </div>
                  )
                );
              }
            }
          })}
      </Row>
      {/* plus codes  */}
      <Row>
        <Col
          onClick={() => setplusCodes(!plusCodes)}
          className="ms-3 mt-3 me-3  cursor-pointer plusCodetext d-flex align-items-center"
        >
          <img
            src={require("@imgs/plusIcon.png")}
            className="plusIcon"
            alt={"imageComponent"}
          />
          {t("Inclurecodes")}
        </Col>
      </Row>
      {plusCodes && (
        <Row>
          <Col
            xs={12}
            md={8}
            lg={8}
            className="ms-3 me-3 mt-3 plusCodetext d-flex align-items-center"
          >
            <CustomMultiSelect
              url={`/manager/codes/?$q=${state.$q}&$filter={"is_pro": true}&$top=10`}
              placeholder={t("Ajouterdautrescodes")}
              onSelectValue={(c) => setState({ ...state, codes: c })}
              onInputChange={(c) => {
                setState({ ...state, $q: c });
              }}
              onFocus={() => {}}
              value={state.codes}
              name="codes"
              required
              id="codes-list"
            />
          </Col>
        </Row>
      )}
      {/* send */}
      <Row className=" d-flex justify-content-center justify-content-md-end mt-3">
        <Col className="col-12 col-md-4">
          <Button
            customStyles="btnEnvoyer relativeBtn "
            text={t("Envoyer")}
            fetching={fetchingSend}
            RightComponent={() => (
              <img
                src={require("@imgs/send.png")}
                className="EnvoyerIconSend"
                alt={"imageComponent"}
              />
            )}
            disabled={!validateSend()}
            onClick={() => {
              //extar codes logic
              const codes =
                state.codes !== null &&
                state.codes?.length > 0 &&
                state.codes
                  .map((item) => item.value)
                  .filter((item) => item !== code?.id);
              const { content, attachements } = formData;
              let payload;
              if (attachements?.length > 1) {
                payload = {
                  content,
                  type: "multy",
                  attachements: attachements?.map((item) => ({
                    type: item?.type,
                    attachement: item?.attachement,
                  })),
                  codes: codes.length > 0 ? [code?.id, ...codes] : [code?.id],
                };
              } else {
                if (attachements?.[0]?.attachement && attachements?.[0]?.type) {
                  if (
                    attachements?.[0]?.type === "image" ||
                    attachements?.[0]?.type === "video"
                  ) {
                    payload = {
                      content,
                      type: "multy",
                      attachements: [
                        {
                          attachement: attachements?.[0]?.attachement,
                          type: attachements?.[0]?.type,
                        },
                      ],
                      codes:
                        codes.length > 0 ? [code?.id, ...codes] : [code?.id],
                    };
                  } else {
                    payload = {
                      content,
                      type: attachements?.[0]?.type || "text",
                      ...(attachements?.[0]?.type !== "youtube" && {
                        attachement: attachements?.[0]?.attachement,
                      }),
                      codes:
                        codes.length > 0 ? [code?.id, ...codes] : [code?.id],
                      ...(attachements?.[0]?.type === "youtube" &&
                        urlRegex.test(attachements?.[0]?.attachement) && {
                          youtube_url: attachements?.[0]?.attachement,
                        }),
                    };
                  }
                } else {
                  //just text
                  payload = {
                    content,
                    type: "text",
                    codes: codes.length > 0 ? [code?.id, ...codes] : [code?.id],
                  };
                }
              }
              sendMessage(payload, () => {
                emptyStates();
                getHistory();
              });
            }}
          />
        </Col>
      </Row>
      {/* historique */}

      {historyData?.length > 0 && (
        <Row>
          <Col
            sethistory
            onClick={() => sethistory(!history)}
            xs={12}
            md={6}
            className="  pt-2 ms-3  d-flex align-items-center "
          >
            <span className="newCodeText cursor-pointer">
              {t("Historique")}
            </span>
            <img
              src={
                history
                  ? require("@imgs/arrowUp.png")
                  : require("@imgs/arrow.png")
              }
              className=" ms-3 historyIcon"
              alt={"imageComponent"}
            />
          </Col>
        </Row>
      )}
      {history &&
        historyData?.length > 0 &&
        historyData?.map((item, i) => (
          <Row>
            <Col>
              <div key={i}>
                <div className="info info-horizontal">
                  {/* <div className="icon icon-circle">icon</div> */}
                  <Row
                    className="
                  d-flex justify-content-between

                  ms-4
                  "
                  >
                    <div>
                      <div className="description">
                        <h4 className="info-title text-bold">
                          {item?.content}
                        </h4>
                        <p className="text-regular">
                          {moment(item.created_at).format("YYYY-MM-DD - HH:mm")}
                        </p>
                        {item?.has_resend && item?.numOfUsers > 0 && (
                          <h4 className="info-title font-italic  meduimText">
                            {"Vous avez " +
                              item?.numOfUsers +
                              " nouveaux abonnés par rapport au dernier message"}
                          </h4>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="my-2 my-xs-0 ">
                        {/* TODO ADD  SPINNER */}
                        <button
                          onClick={() => {
                            setItemid(item?.id);
                            resendMessage(item?.id, () => {
                              getHistory();
                            });
                          }}
                          disabled={!code?.is_pro}
                          type="button"
                          className={
                            !code?.is_pro
                              ? "disableds  buttonResend"
                              : "buttonResend"
                          }
                        >
                          {fetchingResend && Itemid === item?.id ? (
                            <Spinner size="sm" color="primary" />
                          ) : (
                            "Renvoyer ce message"
                          )}
                        </button>
                      </div>
                    </div>
                  </Row>
                </div>
                <hr />
              </div>
            </Col>
          </Row>
        ))}
      {history && historyData?.length > 9 && historyData?.length !== count && (
        <>
          <Row className="pb-4">
            <div
              className="text-center  my-4"
              style={{ cursor: "pointer", width: "100%" }}
            >
              <span
                onClick={() => {
                  setPagination(pagination + 10);
                  getHistory((ListData, top, count) => {
                    setCount(count);
                    setPagination(top);
                  }, pagination + 10);
                }}
                className="trier text-bold text-center"
                style={{ cursor: "pointer", width: "200px" }}
              >
                {t("AfficherPlus")}
              </span>
            </div>
          </Row>
        </>
      )}
      <input id="uploader" type="file" style={{ display: "none" }} />
    </Container>
  );
};
