import { useEffect, useContext, useState } from "react";
import { LoadingContext } from "@contexts/LoadingContext";
import { useNavigate } from "react-router-dom";
import axios from "@globals/axiosConfig";

export default () => {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [successPayer, setSuccessPayer] = useState(null);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const navigate = useNavigate();

  async function createManagers(payload) {
    try {
      showLoader();
      setFetching(true);
      await axios.post(`/manager/managers/`, payload);
      navigate("/Users");
    } catch (error) {
      setError(error); 
      setFetching(false);
    } finally {
      hideLoader();
    }
  }

  return [{ fetching, error }, createManagers];
};
