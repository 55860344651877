import React from "react";
import { useState, useEffect, useRef } from "react";
// this method to download audio
import { useRecorderPermission } from "@helpers/helpers";
import ProgressBar from "@ramonak/react-progress-bar";
// styles
import "./styles.css";
import { isMobile } from "react-device-detect"; 

const AUDIO_CLIP_LENGTH_IN_SECONDS = 60;
let myInterval = null;

export default (props) => {
  // State this components
  const [srcAudio, setSrcAudio] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const recorder = useRecorderPermission("audio");

  const [AudioObject, setAudioObject] = useState(null);
  const resetTimer = () => {
    clearInterval(myInterval);
  };

  const triggerTimer = async () => {
    myInterval = setInterval(() => {
      if (seconds < AUDIO_CLIP_LENGTH_IN_SECONDS) {
        // console.log("continue ", seconds);
        setSeconds((seconds) => seconds + 1);
      }
    }, 1000);
  };

  // start recording audio
  const startRecording = async () => {
    setSeconds(0);
    setSrcAudio(null);
    setIsRecording(true);
    await triggerTimer();
    recorder.startRecording();
    const sleep = (m) => new Promise((r) => setTimeout(r, m));
    await sleep(AUDIO_CLIP_LENGTH_IN_SECONDS * 1000);
    await stopRecording();
  };

  // get File Name audio
  function getFileName(fileExtension) {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    var date = d.getDate();
    return "Audio_" + year + month + date + "." + fileExtension;
  }

  // stop recording audio
  const stopRecording = async () => {
    setIsRecording(false);

    await recorder.stopRecording();
    let blob = await recorder.getBlob();

    //  convertMpegToMp3(blob);
    var file = new File([blob], getFileName("mpeg"), {
      type: "audio/mpeg",
    });

    console.log("file ", file);

    resetTimer();
    // convert file blob to url
    const url = window.URL.createObjectURL(blob);
    setSrcAudio(url);
    props.setFileAudio(file, url);
  };

  // Format time Audio

  //ma audio player

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  //permmission status state
  const [AudioPermission, setAudioPermission] = useState(false);
  //permmission handler
  async function checkAudioPermission() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // Permission is granted; you have access to the audio.
      console.log("Audio permission granted");
      setAudioPermission(true);
      stream.getTracks().forEach((track) => track.stop()); // Don't forget to stop the stream.
    } catch (error) {
      // Permission is denied, or an error occurred.
      setAudioPermission(false);
      console.log("Audio permission denied", error);
    }
  }

  useEffect(() => {
    checkAudioPermission();
  }, []);
  useEffect(() => {
    if (AudioObject) {
      const updateProgress = () => {
        setCurrentTime(AudioObject.currentTime);
        setDuration(AudioObject.duration);
        console.log("currentTime", AudioObject.currentTime);
        console.log("duration", AudioObject.duration);
        if (AudioObject.currentTime === AudioObject.duration) {
          setIsPlaying(false);
          AudioObject.pause();
          console.log("[ endddddd ]");
          setCurrentTime(0);
          //  AudioObject.removeEventListener("timeupdate", updateProgress);
        }
      };
      AudioObject.addEventListener("timeupdate", updateProgress);
    }
  }, [AudioObject]);
  useEffect(() => {
    if (srcAudio) {
      console.log("srcAudio", srcAudio);
      const audioElement = new Audio(srcAudio);
      setAudioObject(audioElement);
      // setDuration(audioElement.duration);
      // console.log('durations ==== > ',audioElement.duration);
    }
  }, [srcAudio]);

  const playAudio = () => {
    AudioObject.muted = false;
    AudioObject.volume = 1; // Set volume to 1 (maximum)

    if (!isPlaying) {
      AudioObject.play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error("Error playing audio:", error);
        });
    } else {
      AudioObject.pause();
      setIsPlaying(false);
      // AudioObject.removeEventListener("timeupdate", updateProgress);
      // setAudioObject(null);
    }
  };
  // ---

  return (
    <div className={"audio-recorderContainer-parent"}>
      <div className={"audio-recorderContainer"}>
        <div
          className={
            srcAudio
              ? "audio-recorder-container-play"
              : "audio-recorder-container"
          }
        >
          {isRecording
            ? !srcAudio && (
                <img
                  onClick={() => {
                    stopRecording();
                  }}
                  src={
                    isMobile
                      ? require("@imgs/audioBtnMobile.png")
                      : require("@imgs/stopRecording.png")
                  }
                  className="playerIcon"
                  alt=">"
                />
              )
            : !srcAudio && (
                <img
                  onClick={() => {
                    AudioPermission
                      ? startRecording()
                      : alert(
                          "Veuillez accorder l'autorisation d'enregistrement audio dans les paramètres."
                        );
                  }}
                  src={require("@imgs/audioBtn.png")}
                  className="playerIcon"
                  alt=">"
                />
              )}
          {srcAudio && (
            <img
              onClick={() => {
                playAudio();
              }}
              src={
                isPlaying
                  ? require("@imgs/pause.png")
                  : require("@imgs/play.png")
              }
              className="playerIcon"
              alt=">"
            />
          )}
          {srcAudio ? (
            <ProgressBar
              completed={parseInt(currentTime)}
              bgColor="#1eb789"
              height="10px"
              width="216px"
              borderRadius="8px"
              isLabelVisible={false}
              baseBgColor="white"
              maxCompleted={parseInt(seconds)}
              // dir="rtl"
            />
          ) : (
            <ProgressBar
              completed={parseInt(seconds)}
              bgColor="black"
              height="10px"
              width="216px"
              borderRadius="8px"
              isLabelVisible={false}
              baseBgColor="white"
              maxCompleted={60}
              // dir="rtl"
            />
          )}
        </div>
        {srcAudio && (
          <img
            onClick={() => {
              AudioObject && AudioObject.pause();
              props.delete && props.delete();
              setSrcAudio(null);
              setSeconds(0);
              setIsRecording(false);
              setAudioObject(null);
              setIsPlaying(false);
              setCurrentTime(0);
              setDuration(0);
            }}
            src={require("@imgs/delete.png")}
            className="DeleteIcon"
            alt=">"
          />
        )}
      </div>
      <div className="textCounter-container">
        <div className="text-bold textCounter">
          {srcAudio
            ? "00:" +
              (parseInt(currentTime) < 10
                ? "0" + parseInt(currentTime).toString()
                : parseInt(currentTime))
            : isRecording
            ? "00:" +
              (parseInt(seconds) < 10
                ? "0" + parseInt(seconds).toString()
                : parseInt(seconds))
            : ""}
        </div>
      </div>
    </div>
  );
};
