import React from "react";
import { FormGroup, Label } from "reactstrap";
import AsyncSelect from "react-select/async";
import "./styles.css";
import Api from "@globals/axiosConfig";

const customStyles = {
  container: (provided) => ({
    ...provided,
    background: "#eff8f5",
    borderRadius: "8px",
    borderWidth: "1px",
    width: "100%",
    height: "65px",
    color: "#373737",
    fontSize: "1em",
    borderColor: "#72a596",
    fontWeight: "bold",
    outline: "none",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#72a596",
      boxShadow: "none",
    },
  }),
  control: (provided) => ({
    ...provided,
    background: "#eff8f5",
    borderRadius: "8px",
    borderWidth: "1px",
    width: "100%",
    height: "65px",
    color: "#373737",
    fontSize: "1em",
    fontWeight: "bold",
    borderColor: "#72a596",
    // boxShadow: "#eff8f5",
    outline: "none",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#72a596",
      boxShadow: "none",
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    display: "none", // Hide the indicator separator
  }),
  className: "custom",
};

const SelectInput = ({
  url,
  label,
  onSelectValue,
  required,
  options,
  placeholder,
  name,
  onInputChange,
  ...props
}) => {
  const loadOptions = (inputValue) =>
    new Promise(async (resolve) => {
      try {
        const data = await Api.get(url);
        // setEmployers(data.value);
        const formatted = data?.data.value.map((one) => ({
          value: one._id,
          label: one.name?.first
            ? `${one.name?.first} ${one.name?.last}`
            : one.name,
        }));
        resolve(formatted);
      } catch {
        resolve([]);
      }
    });

  return (
    <FormGroup>
      {label && (
        <Label className="text-black" htmlFor={name}>
          {label}
          {required && <span>*</span>}
        </Label>
      )}
      <div style={{ position: "relative", width: "100%" }}>
        <AsyncSelect
          styles={customStyles}
          placeholder={placeholder ? placeholder : "Choisir"}
          cacheOptions
          isMulti
          loadOptions={loadOptions}
          defaultOptions
          onInputChange={onInputChange}
          onChange={(select) => onSelectValue(select)}
          id={props.id}
          inputId={props.id}
          defaultValue={props.defaultValue}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 15, // Adjust the right position as needed
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={require("@imgs/arrow.png")}
            className="arrowIcon2"
            alt={"imageComponent"}
          />
        </div>
      </div>
    </FormGroup>
  );
};

export default SelectInput;
