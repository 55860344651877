// LoadingContext.js
import React, { createContext, useContext, useState } from "react";
import { CirclesWithBar } from "react-loader-spinner";

export const LoadingContext = createContext();

 const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoader = () => setIsLoading(true);
  const hideLoader = () => setIsLoading(false);

  return (
    <LoadingContext.Provider value={{ showLoader, hideLoader }}>
      {children}
      {isLoading && (
        <div
          style={{
            // position: "fixed",
            // top: "50%",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
            // backgroundColor:'rgba(0, 0, 0, 0.5)',
            // width:'100%',
            // height:'100%',
            // justifyContent: "center",
            // alignContent: "center",
            // display: "flex",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.2)", // Black background with 50% opacity
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <CirclesWithBar
            height="100"
            width="100"
            color="#1EB789"
            outerCircleColor="#1EB789"
            innerCircleColor="#1EB789"
            barColor="#1EB789"
            ariaLabel="circles-with-bar-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </LoadingContext.Provider>
  );
};
export default LoadingProvider;

// export const useLoading = () => {
//   const context = useContext(LoadingContext);
//   if (!context) {
//     throw new Error("useLoading must be used within a LoadingProvider");
//   }
//   return context;
// };
