import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Row, Spinner, Image } from "react-bootstrap";
import AppInput from "@components/AppInput";
import Button from "@components/Button";
import "./styles.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import useUpdateCodeNotPro from "../hooks/useUpdateNotCode";
import useUpdateDeleteCode from "../hooks/useUpdateDeleteCode";
import useUpdateCode from "../hooks/useUpdateCode";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "@/contextProviders/ModalContext";
import { api } from "@globals/axiosConfig";
import { deleteCode as deletecodefromredux, setCode } from "../../slice";
import MultyMediaInput from "@components/MultyMediaInput";
import GooglePlaceInput from "@components/GooglePlaceInput";
import { validateFields, geocodeAddress } from "@helpers/helpers";
import ButtonOutLigned from "@components/ButtonOutLigned";
import CodeTypeButton from "@components/CodeTypeButton";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import PhoneInput from "@components/PhoneInput";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const code = useSelector((state) => state?.home?.code);
  // const fetching = false;
  //hooks
  const [fetchingDisable, updateCodeNotPro] = useUpdateCodeNotPro(code?.id);
  const [{ fetching, error }, updateCode] = useUpdateCode(code?.id);
  const [fetchingDelete, deleteCode] = useUpdateDeleteCode(code?.id);
  const { openModal, closeModal } = useModal();
  const handleOpenPopup = () => {
    openModal(
      t("Confirmation"),
      t("Desactivercode"),
      () => {
        updateCodeNotPro(() => {
          navigate(`/ActivateProMode`);
          closeModal();
          //TODO SEE IF YOU CAN ACTIVATE THE CODE HERE ==== set is_pro for true here
        });
      },
      () => {
        console.log("No clicked");
      }
    );
  };
  const [formData, setFormData] = useState({
    name: null,
    thumbnail: null,
    type: null,
    content: null,
    address1: null,
    location: null,
    country: "",
  });
  const [logo, setLogo] = useState();
  const [QrCodeTypeSelection, setQrCodeTypeSelection] = useState(true);
  const [logoQR, setLogoQR] = useState();
  const [MultyMedia, setMultyMedia] = useState();
  const getCoordinates = async (address) => {
    try {
      const location = await geocodeAddress(address.value.description);
      setFormData({
        ...formData,
        location,
        address1: address,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setFormData({
      type: code?.type || "",
      name: code?.name || "",
      thumbnail: code?.thumbnail || "",
      thumbnail_info: code?.thumbnail_info || "",
      qrcode: code?.qrcode || "",
      image_download_url: code?.qrcode?.image_download_url || "",
      image_view_url: code?.qrcode?.image_view_url || "",
      uniq_qrcode: code?.uniq_qrcode || "",
      content: code?.content || "",
      location: code?.location || "",
      country: code?.country ? code?.country : "",
      address1: {
        label: code?.address1 || "",
        value: code?.address1 || "",
      },
      ...(code?.type === "urlApp" && {
        playStoreUrl: code?.playStoreUrl,
        appStoreUrl: code?.appStoreUrl,
      }),
      ...(code?.phone && { phone: code?.phone }),
      ...(code?.email && { email: code?.email }),
    });

    setMultyMedia(
      code?.content_info?.preview ? api + code?.content_info?.preview : null
    );
    setLogo(api + code?.thumbnail_url);
  }, [code]);

  //code update hooks

  return (
    <Container fluid className="">
      <Row>
        <Col xs={7} md={6} className="  pt-3 ms-3  ">
          <div className={isMobile ? "newCodeTextMobile" : "newCodeText"}>
            {t("Detailducode")}
          </div>
        </Col>
        <Col className="col-2 ms-auto me-3 pt-3  d-flex justify-content-end align-items-center">
          <Dropdown
            drop={"start"}
            className="d-inline mx-2 ms-auto cursor-pointer"
            autoClose="true"
          >
            <Dropdown.Toggle as={CustomToggle} id="dropdown-autoclose-outside">
              <img
                src={require("@imgs/dots.png")}
                className="dotsIcon"
                alt={"imageComponent"}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                className="dropdownItem"
                onClick={() => {
                  navigate(`/NewMessage`);
                }}
              >
                {t("Messages")}
              </Dropdown.Item>
              <div className="DropdownDivider" />
              <Dropdown.Item
                className="dropdownItem"
                // onClick={() => {
                //   console.log("click =>    [[");
                // }}
                href={`${code?.uniq_qrcode}`}
                target="_blank"
              >
                {t("Visualisersingle")}
              </Dropdown.Item>
              <div className="DropdownDivider" />

              <Dropdown.Item
                className="dropdownItem"
                href={`${api}${code?.qrcode?.image_download_url}`}
              >
                {t("Telechargerlecode")}
              </Dropdown.Item>
              <div className="DropdownDivider" />

              <Dropdown.Item
                className="dropdownItem text-danger"
                onClick={() => {
                  //Api delete code required to be added to the collection
                  deleteCode(() => {
                    dispatch(deletecodefromredux());
                    navigate(`/`);
                    //TODO DELETE CODE FROM CODE
                  });
                }}
              >
                {t("Supprimerlecode")}
              </Dropdown.Item>
              <div className="DropdownDivider" />

              <Dropdown.Item
                className="dropdownItem"
                onClick={() => {
                  handleOpenPopup();
                }}
              >
                {t("Desactiverlemodepro")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row className="mt-1 ms-3 mt-3">
        <Col className=" me-3 text-piece">{t("Nomducode")} *</Col>
      </Row>
      <Row className="ms-3 mt-2">
        <Col xs={12} md={6} lg={6} className="">
          <AppInput
            // labelText={t("Nomducode")}
            required
            value={formData?.name}
            onChange={(name) => setFormData({ ...formData, name })}
            className="AppInput"
          />
        </Col>
      </Row>
      {/* Logo du code * */}
      <Row className="mt-1 ms-3 mt-3">
        <Col className=" me-3 text-piece">{t("Logoducode")} *</Col>
      </Row>
      <Row className="ms-3 mt-2"></Row>
      <Row className="mt-1 ms-3 ">
        <Col xs={12} md={6} lg={6} className="">
          {/* <Image
          src={setLogo(api + code?.thumbnail_url)}
          className="ApercuCodeIcon"
          alt={"imageComponent"}
        /> */}

          <MultyMediaInput
            type={"image"}
            // labelText={"Logo du code"}
            placeholder={"En format png,jpg ou jpeg"}
            value={logo}
            setFileUrl={setLogo}
            setFileUrlFromApi={(thumbnail) => {
              setFormData({ ...formData, thumbnail });
            }}
            className="AppInput ms-3"
            LeftIcon={
              <img
                src={require("@imgs/LeftImageIcon.png")}
                className="LeftIcon"
                alt={"LeftIcon"}
              />
            }
            accept={"image/*"}
          />
        </Col>
      </Row>
      <Row className="mt-1 ms-3 ">
        <Col className=" me-3 text-piece">{t("Apercuducode")} *</Col>
      </Row>
      <Row className="mt-1 me-3 ms-3 ">
        <Col className="">
          {" "}
          <Image
            src={api + code?.qrcode?.image_view_url}
            className="ApercuCodeIcon"
            alt={"imageComponent"}
          />
        </Col>
      </Row>
      {/* Qrcode type section  */}
      <Row className="mt-1 ms-3">
        <Col className=" me-3 text-piece">{t("Typedecode")} *</Col>
      </Row>
      <Row className="ms-3">
        <Col className="   d-flex  flex-wrap justify-content-lg-start justify-content-center my-3 ">
          {/* url */}
          <CodeTypeButton
            labelText={t("Siteweb")}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            onClick={() => {
              setFormData({ ...formData, type: "url", content: "" });
              setMultyMedia(null);
            }}
            activeStyle={formData?.type === "url"}
            LeftComponent={() => (
              <img
                src={
                  formData?.type === "url"
                    ? require("@imgs/whitelink.png")
                    : require("@imgs/webIcon.png")
                }
                className="CodeTypeIconUrl"
                alt={"imageComponent"}
              />
            )}
          />
          {/* Facebook
          <CodeTypeButton
            labelText={"Facebook"}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            onClick={() => {
              setFormData({ ...formData, type: "socialFacebook" });
            }}
            activeStyle={formData?.type === "socialFacebook"}
            LeftComponent={() => (
              <img
                src={
                  formData?.type === "socialFacebook"
                    ? require("@imgs/whitefacebook-logo.png")
                    : require("@imgs/fbIcon.png")
                }
                className="CodeTypeIcon"
                alt={"imageComponent"}
              />
            )}
          /> */}
          {/* Instagram
          <CodeTypeButton
            labelText={"Instagram"}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            onClick={() => {
              setFormData({ ...formData, type: "socialInstagram" });
            }}
            activeStyle={formData?.type === "socialInstagram"}
            LeftComponent={() => (
              <img
                src={
                  formData?.type === "socialInstagram"
                    ? require("@imgs/whiteinstagram-logo.png")
                    : require("@imgs/instaIcon.png")
                }
                className="CodeTypeIcon"
                alt={"imageComponent"}
              />
            )}
          /> */}
          {/* Linkedin
          <CodeTypeButton
            labelText={"Linkedin"}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            onClick={() => {
              setFormData({ ...formData, type: "socialLinkedin" });
            }}
            activeStyle={formData?.type === "socialLinkedin"}
            LeftComponent={() => (
              <img
                src={
                  formData?.type === "socialLinkedin"
                    ? require("@imgs/whitelinkedin.png")
                    : require("@imgs/inIcon.png")
                }
                className="CodeTypeIcon"
                alt={"imageComponent"}
              />
            )}
          /> */}
          {/* WhatsApp
          <CodeTypeButton
            labelText={"WhatsApp"}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            onClick={() => {
              setFormData({ ...formData, type: "socialWhatsApp" });
            }}
            activeStyle={formData?.type === "socialWhatsApp"}
            LeftComponent={() => (
              <img
                src={
                  formData?.type === "socialWhatsApp"
                    ? require("@imgs/Whitewhatsapp.png")
                    : require("@imgs/whatsappIcon.png")
                }
                className="CodeTypeIcon"
                alt={"imageComponent"}
              />
            )}
          /> */}
          {/* Url de Paiement */}
          <CodeTypeButton
            labelText={t("UrldePaiement")}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            onClick={() => {
              setFormData({ ...formData, type: "urlPayment", content: "" });
              setMultyMedia(null);
            }}
            activeStyle={formData?.type === "urlPayment"}
            LeftComponent={() => (
              <img
                src={
                  formData?.type === "urlPayment"
                    ? require("@imgs/whitepay-card.png")
                    : require("@imgs/cardIcon.png")
                }
                className="CodeTypeIconpay"
                alt={"imageComponent"}
              />
            )}
          />
          {/* Youtube
          <CodeTypeButton
            labelText={"Youtube"}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            onClick={() => {
              setFormData({ ...formData, type: "socialYoutube" });
            }}
            activeStyle={formData?.type === "socialYoutube"}
            LeftComponent={() => (
              <img
                src={
                  formData?.type === "socialYoutube"
                    ? require("@imgs/youtubeWhiteIcon.png")
                    : require("@imgs/youtubeIcon.png")
                }
                className="CodeTypeIcon"
                alt={"imageComponent"}
              />
            )}
          /> */}
          {/* Apps mobiles */}
          <CodeTypeButton
            labelText={t("Appsmobiles")}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            onClick={() => {
              setFormData({ ...formData, type: "urlApp", content: "" });
              setMultyMedia(null);
            }}
            activeStyle={formData?.type === "urlApp"}
            LeftComponent={() => (
              <img
                src={
                  formData?.type === "urlApp"
                    ? require("@imgs/whitestores.png")
                    : require("@imgs/storesIcon.png")
                }
                className="CodeTypeIconstores"
                alt={"imageComponent"}
              />
            )}
          />
          {/* Photo */}
          <CodeTypeButton
            labelText={t("Photo")}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            onClick={() => {
              setFormData({ ...formData, type: "image", content: "" });
              setMultyMedia(null);
            }}
            activeStyle={formData?.type === "image"}
            LeftComponent={() => (
              <img
                src={
                  formData?.type === "image"
                    ? require("@imgs/whitegallery.png")
                    : require("@imgs/imageIcon.png")
                }
                className="CodeTypeIconImage"
                alt={"imageComponent"}
              />
            )}
            info={t("infoPhoto")}
          />
          {/* Vidéo  */}
          <CodeTypeButton
            labelText={t("Video")}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            onClick={() => {
              setFormData({ ...formData, type: "video", content: "" });
              setMultyMedia(null);
            }}
            activeStyle={formData?.type === "video"}
            LeftComponent={() => (
              <img
                src={
                  formData?.type === "video"
                    ? require("@imgs/whitevideo-call.png")
                    : require("@imgs/videoIcon.png")
                }
                className="CodeTypeIconVideo"
                alt={"imageComponent"}
              />
            )}
            info={t("infoVideo")}
          />
          {/* Audio */}
          <CodeTypeButton
            labelText={t("Audio")}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            onClick={() => {
              setFormData({ ...formData, type: "audio", content: "" });
              setMultyMedia(null);
            }}
            activeStyle={formData?.type === "audio"}
            LeftComponent={() => (
              <img
                src={
                  formData?.type === "audio"
                    ? require("@imgs/whitemicrophone.png")
                    : require("@imgs/microIcon.png")
                }
                className="CodeTypeIconAudio"
                alt={"imageComponent"}
              />
            )}
            info={t("infoAudio")}
          />
          {/* PDF */}
          <CodeTypeButton
            labelText={"PDF"}
            fetching={false}
            customStyles="btnCode justify-content-center  d-flex align-items-center"
            onClick={() => {
              setFormData({ ...formData, type: "pdf", content: "" });
              setMultyMedia(null);
            }}
            activeStyle={formData?.type === "pdf"}
            LeftComponent={() => (
              <img
                src={
                  formData?.type === "pdf"
                    ? require("@imgs/whitepdf.png")
                    : require("@imgs/pdfIcon.png")
                }
                className="CodeTypeIconpdf"
                alt={"imageComponent"}
              />
            )}
            info={t("infoPdf")}
          />
        </Col>
      </Row>
      {/* code multymedia */}
      {formData?.type && (
        <Row className="ms-3">
          {/* urls */}
          {(formData?.type === "url" || formData?.type === "urlPayment") && (
            // ||
            // formData?.type.includes("social")
            <Col xs={12} md={6}>
              <AppInput
                labelText={
                  formData?.type === "url"
                    ? t("Lien") + t("Siteweb")
                    : formData?.type === "socialFacebook"
                    ? t("Lien") + "Facebook"
                    : formData?.type === "socialInstagram"
                    ? t("Lien") + "Instagram"
                    : formData?.type === "socialLinkedin"
                    ? t("Lien") + "Linkedin"
                    : formData?.type === "socialYoutube"
                    ? t("Lien") + "Youtube"
                    : formData?.type === "socialWhatsApp"
                    ? t("Lien") + "WhatsApp"
                    : formData?.type === "urlPayment"
                    ? t("UrldePaiement")
                    : t("Contenuducode")
                }
                placeholder={
                  formData?.type === "urlPayment"
                    ? t("urlPay")
                    : // formData?.type.includes("social")
                      t("urlSocial")
                }
                value={formData.content}
                onChange={(content) => setFormData({ ...formData, content })}
                required
                className="AppInput"
                LeftIcon={
                  <img
                    src={require("@imgs/webIcon.png")}
                    className="LeftIcon"
                    alt={"LeftIcon"}
                  />
                }
              />
            </Col>
          )}
          {/* multy media */}
          {(formData?.type === "audio" ||
            formData?.type === "image" ||
            formData?.type === "pdf" ||
            formData?.type === "video") && (
            <Col xs={12} md={6} lg={6}>
              <MultyMediaInput
                type={formData?.type}
                labelText={t("Contenuducode")}
                placeholder={
                  formData?.type === "image"
                    ? t("formatImage")
                    : formData?.type === "audio"
                    ? t("Enformatmp3")
                    : formData?.type === "pdf"
                    ? t("formatPdf")
                    : formData?.type === "video"
                    ? t("formatMp4")
                    : ""
                }
                value={MultyMedia}
                setFileUrl={setMultyMedia}
                setFileUrlFromApi={(content) => {
                  setFormData({ ...formData, content });
                }}
                className="AppInput"
                LeftIcon={
                  <img
                    src={require("@imgs/LeftImageIcon.png")}
                    className="LeftIcon"
                    alt={"LeftIcon"}
                  />
                }
                accept={
                  formData?.type === "image"
                    ? "image/*"
                    : formData?.type === "video"
                    ? "video/*"
                    : formData?.type === "audio"
                    ? ".ogg,.mp3,wav,"
                    : formData?.type === "pdf"
                    ? ".pdf"
                    : ""
                }
              />
            </Col>
          )}
          {/* stores */}
          {formData?.type === "urlApp" && (
            <>
              <Col xs={12} md={6} lg={6}>
                <AppInput
                  info={"info"}
                  placeholder={t("googlestoreHeader")}
                  labelText={t("googlestorePlaceholder")}
                  value={formData.playStoreUrl}
                  onChange={(playStoreUrl) =>
                    setFormData({ ...formData, playStoreUrl })
                  }
                  required
                  className="AppInput"
                />
              </Col>
              <Col xs={12} md={6} lg={6}>
                <AppInput
                  placeholder={t("applestorePlaceholder")}
                  labelText={t("applestoreHeader")}
                  value={formData.appStoreUrl}
                  onChange={(appStoreUrl) =>
                    setFormData({ ...formData, appStoreUrl })
                  }
                  required
                  info={"info"}
                  className="AppInput"
                />
              </Col>
            </>
          )}
        </Row>
      )}
      {/* email & phone & location */}
      <Row className="ms-3">
        <Col xs={12} md={6} lg={6}>
          <AppInput
            info={"info"}
            labelText={t("Email")}
            value={formData.email}
            onChange={(email) => setFormData({ ...formData, email })}
            // required
            className="AppInput"
          />
        </Col>
      </Row>
      <Row className="ms-3">
        <Col xs={12} md={6} lg={6}>
          <PhoneInput
            labelText={t("Telephone")}
            info={"info"}
            // required
            type="tel"
            id="user-phone"
            value={formData.phone}
            country={formData?.country || ""}
            onCountryChange={(country) => {
              console.log("country onCountryChange", country);
              setFormData({
                ...formData,
                country,
              });
            }}
            onChange={(phone, country) => {
              setFormData({
                ...formData,
                phone,
              });
            }}
          />
        </Col>
      </Row>
      <Row className="ms-3">
        <Col xs={12} md={6} lg={6}>
          <GooglePlaceInput
            labelText={t("Localisationducode")}
            info={"info"}
            name="address"
            placeholder={t("Selectionneradresse")}
            value={formData.address1}
            onChange={(a) => {
              getCoordinates(a);
            }}
          />
        </Col>
      </Row>
      {/* save & cancel */}
      <Row className=" ms-3 pb-5 d-flex justify-content-end align-items-end mt-4">
        <Col xs={12} md={4} lg={5}>
          <ButtonOutLigned
            customStyles="savebtns"
            text={t("Annuler")}
            fetching={false}
            disabled={fetching}
            black
            onClick={() => {
              navigate("/");
            }}
          />
        </Col>
        <Col xs={12} md={4} lg={5} className=" mt-5 mt-lg-0">
          <Button
            customStyles="savebtns"
            text={t("Sauvegarder")}
            fetching={fetching}
            onClick={() => {
              const {
                name,
                thumbnail,
                playStoreUrl,
                appStoreUrl,
                phone,
                email,
                type,
                content,
                location,
                address1,
                qrcode,
              } = formData;
              const isValidForm = validateFields(
                [
                  { name: "nom du code", value: formData.name, type: "text" },
                  ...(QrCodeTypeSelection
                    ? []
                    : [
                        {
                          name: "QrCode image",
                          value: logoQR,
                          type: "text",
                        },
                        {
                          name: "Contenu telechargement error",
                          value: qrcode,
                          type: "text",
                        },
                      ]),
                  { name: "Logo du Code", value: logo, type: "text" },
                  { name: "Type de code", value: formData.type, type: "text" },
                  //validation of code type  link
                  type &&
                    (type === "url" ||
                      type === "urlPayment" ||
                      type.includes("social")) && {
                      name: "Url",
                      value: content,
                      type: "text",
                    },

                  type &&
                    type.includes("social") && {
                      name: t("Contenuducode"),
                      value: content,
                      type: "text",
                    },
                  ...(type === "audio" ||
                  type === "image" ||
                  type === "pdf" ||
                  type === "video"
                    ? [
                        {
                          name:
                            type === "image"
                              ? "IMAGE"
                              : type === "audio"
                              ? "AUDIO"
                              : type === "pdf"
                              ? "PDF"
                              : type === "video"
                              ? "VIDEO"
                              : t("Contenuducode"),
                          value: MultyMedia,
                          type: "text",
                        },
                        {
                          name: "Contenu telechargement error",
                          value: content,
                          type: "text",
                        },
                      ]
                    : []),
                  type &&
                    type === "urlApp" && {
                      name: "Play store",
                      value: playStoreUrl,
                      type: "text",
                    },
                  type &&
                    type === "urlApp" && {
                      name: "Apple store",
                      value: appStoreUrl,
                      type: "text",
                    },
                ],
                t
              );
              const payload = {
                name,
                thumbnail,
                ...(!QrCodeTypeSelection && { qrcode }),
                ...(type === "urlApp" && {
                  playStoreUrl,
                  appStoreUrl,
                }),
                ...(phone && { phone }),
                ...(email && { email }),
                type: type?.includes("social") ? "url" : type,
                content,
                ...(address1 && { address1: address1?.label }),
                ...(location && { location: location }),
              };

              console.log("payload = > ", payload);
              isValidForm &&
                updateCode(payload, (data) => {
                  // navigate("/");
                  console.log("=====> from update", data?.data);
                  console.log("=====> from update", code);
                  dispatch(setCode(data?.data));
                });

              //TODO REMEMBER TO TEST FOR THE DATA THAT NOT UPLOADED
              // TODO if isValidForm && do somthing
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};
