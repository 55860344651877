import { useEffect, useContext, useState } from "react";
import { LoadingContext } from "@contexts/LoadingContext";
import { useNavigate } from "react-router-dom";
import axios from "@globals/axiosConfig";

export default (id) => {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const navigate = useNavigate();
  async function updateManagers(payload) {
    try {
      showLoader();
      setFetching(true);
      await axios.put(`/manager/managers/${id}`, payload);
      navigate("/Users");
      setFetching(false);
    } catch (error) {
      setError(error);
      setFetching(false);
    } finally {
      hideLoader();
    }
  }

  return [{ fetching, error }, updateManagers];
};
