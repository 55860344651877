import { useContext, useState } from "react";
import axios from "@globals/axiosConfig";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "@contexts/LoadingContext";
// endpoints
import { CodesByIdUrl } from "../CodeMessagesApi";
export default (id)=> {
  const [fetchingUpdate, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const navigate = useNavigate();

  async function updateCode(payload,cb=()=>{}) {
    try {
      setFetching(true);
      showLoader();
      const resp = await axios.put(CodesByIdUrl(id), payload);
      cb(resp);
    } catch (error) {
      setError(error);
    } finally {
      setFetching(false);
      hideLoader();
    }
  }

  return [{ fetchingUpdate, error }, updateCode];
}
