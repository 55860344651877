import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import home from "@Home/slice";
import auth from "@Auth/slice";
import subUser from "@Home/Users/slice";

const reducer = combineReducers({
  home,
  auth,
  subUser,
});
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["home", "auth", "subUser"],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });

    // if (__DEV__ && !process.env.JEST_WORKER_ID) {
    //   // const createDebugger = require("redux-flipper").default;
    //   // middlewares.push(createDebugger());
    // }

    return middlewares;
  },
});

// if (process.env.NODE_ENV !== "production" && module.hot) {
//   // Enable hot module replacement for reducers
//   // module.hot.accept(() => {
//   //   store.replaceReducer(home);
//   // });
// }

const persistor = persistStore(store);

export { store, persistor };
