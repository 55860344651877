// Auth.js
import React, { useEffect } from "react";
import { Outlet, redirect } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "./styles.css";

import AppNavBar from "@components/AppNavBar";
const Auth = () => {
  return (
    <Container fluid className="AppBackground ">
      <AppNavBar />
      <Container fluid className="bg-white AppFrame1 ">
        <Outlet />
      </Container>
    </Container>
  );
};

export default Auth;
