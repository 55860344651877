import React from "react";
import { FormGroup, Label } from "reactstrap";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "./styles.css";
import Form from "react-bootstrap/Form";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const GOOGLE_API_KEY = "AIzaSyBXWOxwBiZNJf_cTQ3NwieZfZhJrH4lPJg";

export default function GooglePlaceInput({
  labelText,
  required,
  hasError,
  name,
  placeholder,
  value,
  onChange,
  info,
  infoPlacement,
  ...props
}) {
  return (
    <>
      <FormGroup className="password-input">
        {labelText && (
          <OverlayTrigger
            // key={infoPlacement || "top"}
            placement={infoPlacement || "right"}
            overlay={info ? <Tooltip>{info}</Tooltip> : <></>}
          >
            <Form.Label className={"labelStyle " + (info && "pointercursor")}>
              {labelText + (required ? " *" : info ? " (i)" : "")}
            </Form.Label>
          </OverlayTrigger>
        )}
        <div style={{ position: "relative", width: "100%" }}>
          <GooglePlacesAutocomplete
            apiKey={GOOGLE_API_KEY}
            // as the client requested

            // autocompletionRequest={{
            //   componentRestrictions: {
            //     country: ["fr", "ma"],
            //   },
            // }}
            selectProps={{
              placeholder: placeholder || "Sélectionner l'adresse",
              styles: {
                singleValue: (provided) => ({
                  ...provided,
                  color: "#373737",
                  fontSize: "1rem",
                  borderColor: "#72a596",
                  fontWeight: "400",
                }),
                container: (provided) => ({
                  ...provided,
                  background: "#eff8f5",
                  borderRadius: "8px",
                  borderWidth: "1px",
                  width: "100%",
                  height: "65px",
                  color: "#373737",
                  fontSize: "1em",
                  borderColor: "#72a596",
                  fontWeight: "bold",
                  // boxShadow: "none",
                  outline: "none",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#72a596",
                    boxShadow: "none",
                  },
                }),
                control: (provided) => ({
                  ...provided,
                  background: "#eff8f5",
                  borderRadius: "8px",
                  borderWidth: "1px",
                  width: "100%",
                  height: "65px",
                  color: "#373737",
                  fontSize: "1em",
                  fontWeight: "bold",
                  borderColor: "#72a596",
                  outline: "none",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#72a596",
                    boxShadow: "none",
                  },
                }),
                indicatorsContainer: (provided) => ({
                  ...provided,
                  display: "none", // Hide the indicator separator
                }),
              },
              className: "custom",
              value,
              onChange,
            }}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 15, // Adjust the right position as needed
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={require("@imgs/arrow.png")}
              className="arrowIcon2"
              alt={"imageComponent"}
            />
          </div>
        </div>
      </FormGroup>
    </>
  );
}
