import { useContext, useState } from "react";
import { LoadingContext } from "@contexts/LoadingContext";
import axios from "@globals/axiosConfig";
import { toast } from "react-toastify";

export default () => {
  const [errorFree, setErrorFree] = useState({ ok: true });
  const [successFree, setSuccessFree] = useState(null);
  const { showLoader, hideLoader } = useContext(LoadingContext);

  async function SubscribeFree(payload, cb) {
    try {
      showLoader();
      const rep = await axios.post(
        `/manager/societies/subscription/free`,
        payload
      );
      setSuccessFree(rep?.data);
      toast.success(rep?.data.message);

      cb && cb();
    } catch (error) {
      console.log(
        "error useSubscribeFree = > ",
        error?.response?.data?.message
      );
      setErrorFree(error);
      if (error?.response?.data?.message) {
        // toast.error(error?.response?.data?.message);
      }
      //errorFree?.ok
      hideLoader();
    } finally {
      hideLoader();
    }
  }

  return [
    { errorFree, setErrorFree, successFree, setSuccessFree },
    SubscribeFree,
  ];
};
