import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subUser: null,
};

const subUser = createSlice({
  name: "subUser",
  initialState,
  reducers: {
    setSubUser: (state, action) => {
      state.subUser = action.payload;
    },
    deleteSubUser: (state) => {
      state.subUser = null;
    },
  },
});

export const { setSubUser, deleteSubUser } = subUser.actions;

export default subUser.reducer;
