import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./en.json";
import frTranslation from "./fr.json";
import grTranslation from "./gr.json";
import itTranslation from "./it.json";
import spTranslation from "./sp.json";

const resources = {
  en: {
    translation: enTranslation,
  },
  fr: {
    translation: frTranslation,
  },
  gr: {
    translation: grTranslation,
  },
  it: {
    translation: itTranslation,
  },
  sp: {
    translation: spTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fr", // Default language
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;
