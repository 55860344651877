import { useContext, useState } from "react";
import { ResendMessageUrl } from "../CodeMessagesApi";
import Api from "@globals/axiosConfig";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "@contexts/LoadingContext";

export default () => {
  const [fetchingResend, setFetching] = useState(false);
  const [errorResend, setError] = useState(null);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const navigate = useNavigate();
  async function resendMessage(id, callback = () => {}) {
    try {
      setFetching(true);
      showLoader();
      await Api.post(ResendMessageUrl(id));
      callback();
      setFetching(false);
    } catch (error) {
      setError(error);
    } finally {
      setFetching(false);
      hideLoader();
    }
  }
  return [{ fetchingResend, errorResend }, resendMessage];
};
