import React, { useEffect, useState, useContext } from "react";
import {
  Col,
  Container,
  FormGroup,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoadingContext } from "@contexts/LoadingContext";
import Switch from "./components/Switch";
import { Label } from "reactstrap";
import useUpdateManagers from "./hooks/useUpdatemanagers";
import { useDispatch, useSelector } from "react-redux";

export default () => {        
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const navigate = useNavigate();
  const subUser = useSelector((state) => state?.subUser?.subUser);
  const { t } = useTranslation();
  const [{ fetching, error }, updatemanager] = useUpdateManagers(subUser?._id);
  const [state, setState] = useState({
    $q: "",
    name: subUser?.name,
    email: subUser?.email,
    account_enabled: subUser?.account_enabled,
    can_create_codes: subUser?.can_create_codes,
  });
  useEffect(() => {
    setState({
      name: subUser?.name,
      email: subUser?.email,
      account_enabled: subUser?.account_enabled,
      can_create_codes: subUser?.can_create_codes,
    });
  }, [subUser]);
  const invalidForm = !state.name || !state.email;

  const onUpdateData = () => {
    const payload = {
      name: state.name,
      email: state.email,
      // password: password,
      role: "manager",
      account_enabled: state.account_enabled,
      can_create_codes: state.can_create_codes,
    };
    updatemanager(payload);
  };
  const handleChange = (event) => {
    event.persist();
    const is_checked = event.target.checked;
    setState({ ...state, [event.target.name]: is_checked });
  };
  return (
    <Container fluid className="  text-center h-100">
      <Row>
        <Col className="d-flex justify-content-start mt-4 ms-3">
          <h1 className="headerText">{t("Detailsutilisateur")}</h1>
        </Col>
      </Row>
      {/* users form  */}
      <Row className="mt-4 ms-1  ">
        <Col xs={12} md={4} className="">
          <Row>
            <Col className="d-flex justify-content-start mt-3">
              <div className="FormTitle">{t("NometPrenom")}</div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-star mt-1">
              <div className="textFormContainer">
                <input
                  id="namedetailsuser"
                  value={state.name}
                  type="text"
                  onChange={(t) => setState({ ...state, name: t.target.value })}
                  className="TabFormInput"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start mt-3">
              <div className="FormTitle">{t("Email")}</div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-star mt-1">
              <div className="textFormContainer">
                <input
                  id="emaildetailsuser"
                  label="Email"
                  type="email"
                  onChange={(t) =>
                    setState({ ...state, email: t.target.value })
                  }
                  className="TabFormInput"
                  value={state.email}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* activate user */}
      <Row>
        <Col className=" d-flex justify-content-start mt-4 ms-3" xs="12" sm="4">
          <FormGroup>
            <Label>Activer</Label>
            <Switch
              checked={state.account_enabled}
              onChange={(e) => {
                handleChange(e);
              }}
              name="account_enabled"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className=" d-flex justify-content-start mt-2 ms-3" xs="12" sm="4">
          <FormGroup>
            <Label>Création de code</Label>
            <Switch
              checked={state.can_create_codes}
              onChange={(e) => {
                handleChange(e);
              }}
              name="can_create_codes"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-5 ms-2 pb-5 d-flex justify-content-end ">
        <Col xs={12} md={4} lg={2} className="d-flex justify-content-end ">
          <div
            onClick={() => {
              navigate("/Users");
            }}
            className="btnCancel d-flex justify-content-center align-items-center"
          >
            {t("Annuler")}
          </div>
        </Col>
        <Col xs={12} md={4} lg={2} className="d-flex justify-content-end ">
          <div
            onClick={() => {
              !invalidForm && onUpdateData();
            }}
            className="btnSave d-flex justify-content-center align-items-center"
          >
            {t("Sauvegarder")}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
