import React  from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import "./styles.css";
const ModalInfo = (props) => {
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={() => {
          props.onCloseModal();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title">{props.title} </h5>
          <div
            onClick={(e) => {
              props.onCloseModal();
            }}
            aria-label="Close"
            className="BtncloseMofdal"
            type="button"
          >
            <img
              src={require("@imgs/iconCloseModal.png")}
              className=""
              width="22px"
              alt=">"
            />
            {/* <span aria-hidden={true}>×</span> */}
          </div>
        </div>
        <ModalBody>
          <div className="discription " style={{ textAlign: "left mt-2" }}>
            <div className="ModalInsideItem">
              {props?.countingCercle && (
                <div className="countingCercle me-2">
                  {props?.countingCercle}
                </div>
              )}
              {props.description}
            </div>
          </div>
          <div className="ModalInsideItem">
            {props?.countingCercle2 && (
              <div className="countingCercle me-2">
                {props?.countingCercle2}
              </div>
            )}
            <div className="discription" style={{ textAlign: "left mt-2" }}>
              {props.description1}
            </div>
          </div>
        </ModalBody>

        {props.is_pro ? (
          <div className="modal-footer2">
            <Button
              className="btn-Global text-bold ms-2"
              onClick={(e) => {
                e.preventDefault();
                props.onCloseModal();
              }}
              type="button"
            >
              {props?.textNok || "Fermer"}
            </Button>
          </div>
        ) : (
          <div className="modal-footer">
            <Button
              className=" btn-Global-fermer text-bold ms-2"
              onClick={(e) => {
                e.preventDefault();
                props.onCloseModal();
              }}
              type="button"
            >
              {props?.textNok || "Retourner"}
            </Button>

            <Button
              className="btn-Modal-Save text-bold ms-2"
              onClick={(e) => {
                e.preventDefault();
                     props.onOkModal();
              }}
              type="button"
            >
              {props?.textOk || "S’abonner"}
            </Button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ModalInfo;
