import React from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";

import "./styles.css";
export default ({
  text,
  disabled,
  onClick,
  fetching,
  labelText,
  LeftComponent,
  RightComponent,
  customStyles,
  ...props
}) => {
  return (
    <div>
      {labelText && (
        <Form.Label className={"labelStyle "}>{labelText}</Form.Label>
      )}
      <Button
        disabled={disabled}
        onClick={onClick}
        className={"Button2  " + (customStyles && customStyles)}
      >
        {fetching ? (
          <Spinner animation="border" />
        ) : (
          <>
            {LeftComponent && <LeftComponent />}
            {text}
            {RightComponent && <RightComponent />}
          </>
        )}
      </Button>
    </div>
  );
};
