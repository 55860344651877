import Signin from "./signIn";
import Signup from "./signUp";
import Forgot from "./forgot";
export default [
  {
    // path: "signin",
    element: <Signin />,
    index:true,
  },
  {
    path: "signup",
    element: <Signup />,
  },
  {
    path: "forgot",
    element: <Forgot />,
  },
];
