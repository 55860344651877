import {useEffect, useContext, useState } from "react";
import { LoadingContext } from "@contexts/LoadingContext";

// api
import axios from "@globals/axiosConfig";
import { toast } from "react-toastify";

export function usePayer() {
  const [fetchingPayer, setFetching] = useState(false);
  const [errorPayer, setErrorPayer] = useState({ ok: true });
  const [successPayer, setSuccessPayer] = useState(null);
  const { showLoader, hideLoader } = useContext(LoadingContext);

  async function Payer(id, payload) {
    try {
      setFetching(true);
            showLoader();

      const rep = await axios.post(`/manager/invoices/${id}/pay`, payload);
      setFetching(false);
      setSuccessPayer(rep?.data);
      toast.success(rep?.data.message);
    } catch (error) {
   if (error?.response?.data?.message) {
    //  toast.error(error?.response?.data?.message);
   }
      console.log("=====usePayer error : ", error);
      setFetching(false);
    } finally {
      hideLoader();
    }
  }
  useEffect(() => {}, [successPayer, errorPayer]);
  return [
    { fetchingPayer, errorPayer, setErrorPayer, successPayer, setSuccessPayer },
    Payer,
  ];
}
