import React from "react";
import "./styles.css";
import AppInput from "@components/AppInput";
import Button from "@components/Button";
import Copyright from "@components/Copyright";
import useForgot from "../hooks/useForgot";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default () => {
  const navigator = useNavigate();
  const { HandlForgot, fetching, error } = useForgot();
  const [username, setUsername] = React.useState("");
    const { t, i18n } = useTranslation();

  return (
    <div className="AuthContainerForgot">
      <div className="iconTopContainer">
        <h1 className="headerTextForgot">{t("Demanderunnouveaumotdepasse")}</h1>
        <img
          src={require("@imgs/lock.png")}
          className="iconTop"
          alt={"imageComponent"}
        />
      </div>
      <AppInput
        onChange={setUsername}
        value={username}
        labelText={t("E_mail")}
        required
        className="AppInput"
      />
      <div className="between" />
      <div className="between" />

      <Button
        text={t("Envoyer")}
        disabled={!username}
        fetching={fetching}
        onClick={() => {
          HandlForgot(username);
        }}
      />
      <div className="between" />
      <div className="between" />

      <p onClick={() => navigator("/auth")} className="signInText">
        <span>
          {t("Dejainscrit")} &nbsp;
          <a className="green-text ">{t("Connectez_vous")}</a>
        </span>
      </p>
      {/* <Copyright /> */}
    </div>
  );
};
