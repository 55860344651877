// useForgot.js
import { useContext, useState } from "react";
import axios from "@globals/axiosConfig";
import { forgotUrl } from "../AuthEndPoints";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "@contexts/LoadingContext";
const useForgot = () => {
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(false);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const navigate = useNavigate();

  const HandlForgot = async (username) => {
    try {
      showLoader();
      setFetching(true);
      await axios.post(forgotUrl(), {
        username,
      });
      navigate("/auth");
    } catch (error) {
      console.error("forgot failed:", error);
      setError("Invalid username or password.");
    } finally {
      setFetching(false);
      hideLoader();
    }
  };

  return {
    HandlForgot,
    error,
    fetching,
  };
};

export default useForgot;
