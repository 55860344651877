import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  code: null,
  mobileView: false,
};

const home = createSlice({
  name: "home",
  initialState,
  reducers: {
    setCode: (state, action) => {
      state.code = action.payload;
    },
    deleteCode: (state) => {
      state.code = null;
    },
    setmobileView: (state, action) => {
      state.mobileView = action.payload;
    },
    deletemobileView: (state) => {
      state.mobileView = false;
    },
  },
});

export const { setCode, deleteCode, setmobileView, deletemobileView } =
  home.actions;

export default home.reducer;
