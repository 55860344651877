import { useContext, useState } from "react";
import axios from "@globals/axiosConfig";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "@contexts/LoadingContext";
import { useModal } from "@/contextProviders/ModalContext";

// endpoints
import { CodesProUrl } from "../CodeMessagesApi";

export default function useUpdateCodePro(id) {
  const [fetching, setFetching] = useState(false);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { openModal, closeModal } = useModal();

  const navigate = useNavigate();
  async function updateCodePro(cb = () => {}, cbERR = () => {}) {
    try {
      setFetching(true);
      showLoader();
      const rep = await axios.post(CodesProUrl(id));
      setFetching(false);
      cb(rep);
    } catch (error) {
      console.log(" pro error", error?.response?.data?.message);
      cbERR(error?.response?.data?.message);
      //TODO PayModal openModal(
      //   t("Deconnexion"),
      //   t("vousetesdeconnecter"),
      //   async () => {},
      //   () => {
      //     console.log("No clicked");
      //   }
      // );
      // error?.response?.data?.message ===
      //   "Nombre codes Pro disponibles est: 0" && navigate("/Payment");
    } finally {
      setFetching(false);
      hideLoader();
    }
  }

  return [fetching, updateCodePro];
}
