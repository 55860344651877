// Auth.js
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Col, Image, Row } from "react-bootstrap";
import "./styles.css";
import { setLang } from "./slice";
import AppNavBar from "@components/AppNavBar";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
export default () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    const allowedLanguages = ["fr", "en", "gr", "it", "sp"];
    const languageCode = userLanguage.split("-")[0].toLowerCase();
    const selectedLanguage = allowedLanguages.includes(languageCode)
      ? languageCode
      : "fr";

    i18n.changeLanguage(selectedLanguage);
    dispatch(setLang(selectedLanguage));
  }, []);
  return (
    <Container fluid className="desableScrolling" style={{ height: "100%" }}>
      {/* <AppNavBar /> */}

      <Row style={{ height: "100%" }}>
        <Col
          style={{ height: "100%" }}
          className={`bg-signeIn-color-left`}
          xs={12}
          md={8}
          lg={6}
        >
          <Outlet />
          {/* <Copyright /> */}
        </Col>

        <Col
          className="bg-signeIn-color-right text-center minWithStyle  "
          style={{ height: "100%" }}
          xs={12}
          md={6}
        >
          <img
            src={require("@imgs/appLogo.png")}
            className="AuthRightTopImage"
            alt={"imageComponent"}
          />
          <div className="TextContainer">
            <span className="firstText">{t("Allezplusloin")}</span>
            <br />
            <span className="scondText">{t("avecvosQRCodes")}</span>
          </div>
          {/* <img
            src={require("@imgs/SignInBanner.png")}
            className="AuthRightBottomImage"
            alt={"imageComponent"}
          /> */}
          <Image
            src={require("@imgs/SignInBanner.png")}
            className="AuthRightBottomImage"
            // thumbnail
          />
        </Col>
      </Row>
    </Container>
  );
};

