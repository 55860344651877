import React from "react";

const AudioPreview = ({ audioUrl }) => {
  return (
    <audio className="m-4 mx-auto audioComponent" controls>
      <source src={audioUrl} type="audio/mp3" />
      Your browser does not support the audio tag.
    </audio>
  );
};

export default AudioPreview;
