import React, { useState } from "react";
import "./styles.css";
import CropperModal from "@components/CropperModal";
import AppInput from "@components/AppInput";
import Button from "@components/Button";
import CheckBox from "@components/CheckBox";
import ImageInput from "@components/ImageInput";
import useSignUp from "../hooks/useSignUp";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

export default () => {
  const { t, i18n } = useTranslation();

  const navigator = useNavigate();
  const { handleSignUp, error, fetching } = useSignUp();
  const [formData, setFormData] = useState({
    name: "",
    society_name: "",
    email: "",
    society_logo: "",
    address1: "",
    password: "",
  });
  const [honor_checked, setHonor] = useState(false);
  const [showCropper, setShowCropper] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  //cropper logic
  const [logo, setLogo] = useState();
  const [OrigineImageToCrop, setOrigineImageToCrop] = useState();
  const onChoosingImageClickHandler = () => {
    setShowCropper(!showCropper);
  };
  return (
    <div className={`AuthContainerSign ${isMobile && "noScrollx"}`}>
      {isMobile && <div className="MargingTopMobile" />}

      <div className="iconTopContainer">
        <h1 className="headerTextSignUp">{t("Creezvotrecomptegratuit")}</h1>
        <img
          src={require("@imgs/lock.png")}
          className="iconTop"
          alt={"imageComponent"}
        />
      </div>
      <ImageInput
        labelText={t("Joindrelaphotodimention")}
        value={logo}
        setImageUrl={(file) => {
          setOrigineImageToCrop(file);
          setLogo(file);
          file && onChoosingImageClickHandler();
        }}
        setImageUrlFromApi={(file) => {
          setFormData({ ...formData, society_logo: file });
        }}
        LeftIcon={
          <img
            src={require("@imgs/LeftImageIcon.png")}
            className="LeftIcon"
            alt={"LeftIcon"}
          />
        }
        className="AppInput"
      />
      {/* btn upload */}
      <div className="between" />
      <AppInput
        labelText={t("orgname")}
        required
        className="AppInput"
        onChange={(value) => setFormData({ ...formData, society_name: value })}
        value={formData?.society_name}
      />
      <div className="between" />
      <AppInput
        labelText={t("Nomprenom")}
        required
        className="AppInput"
        onChange={(value) => setFormData({ ...formData, name: value })}
        value={formData?.name}
      />
      <div className="between" />
      <AppInput
        labelText={t("Adresse")}
        onChange={(value) => setFormData({ ...formData, address1: value })}
        value={formData?.address1}
        className="AppInput"
      />
      <div className="between" />
      <AppInput
        onChange={(value) => setFormData({ ...formData, email: value })}
        value={formData?.email}
        labelText={t("E_mail")}
        required
        className="AppInput"
      />
      <div className="between" />
      <AppInput
        labelText={t("Motdepasse")}
        type={showPassword ? "text" : "password"}
        onChange={(value) => setFormData({ ...formData, password: value })}
        value={formData?.password}
        required
        className="AppInput"
        RightIcon={
          <img
            src={require(`@imgs/${showPassword ? "close-eye" : "eye"}.png`)}
            className="RightIcon"
            alt={"RightIcon"}
            onClick={() => setShowPassword(!showPassword)}
          />
        }
      />
      <div className="between" />
      <p class=" signUpText">
        <div className="checkboxContainer">
          <CheckBox onChange={setHonor} />
        </div>
        <span>
          {t("Jaccepte")} &nbsp;
          <span
            onClick={() =>
              window.open("https://cool2scan.com/conditions-generales-de-vente")
            }
            class="green-text cursorpointertext  "
          >
            {t("conditions")}
          </span>
        </span>
      </p>
      <div className="between" />
      <Button
        text={t("Sinscrire")}
        fetching={false}
        disabled={
          !(
            formData?.name &&
            formData?.society_name &&
            formData?.email &&
            formData?.password &&
            honor_checked
          )
        }
        onClick={() => {
          const {
            name,
            society_name,
            email,
            society_logo,
            address1,
            password,
          } = formData;

          handleSignUp({
            name,
            society_name,
            email,
            ...(society_logo && { society_logo }),
            ...(address1 && { address1 }),
            password,
          });
        }}
      />
      <div className="between" />
      {/* Déjà inscrit ? Connectez-vous */}
      <div className={`between `} />
      <p class=" signUpText">
        <span onClick={() => navigator("/auth")}>
          {t("Dejainscrit")} &nbsp;
          <a class="green-text  ">{t("Connectez_vous")}</a>
        </span>
      </p>
      <div className="between" />
      <div className="between" />
      {isMobile && <div className="MargingButtomMobile" />}
      <CropperModal
        // modal trigger
        showModal={showCropper}
        translation={t}
        // modal trigger callback
        setShowModal={(val) => {
          setShowCropper(val);
        }}
        //cropper aspect (cropping demontion )
        aspect={1}
        //image to crop
        selectedimage={OrigineImageToCrop}
        //callback fire when the user complite cropping
        onDoneCropping={async (file) => {
          // //preview the image from the cropper
          setLogo(file);
        }}
        setImageUrlFromApi={(file) => {
          setFormData({ ...formData, society_logo: file });
        }}
      />
    </div>
  );
};
