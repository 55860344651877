import React, { useState, useRef, useEffect } from "react";
import AppInput from "@components/AppInput";
import "./styles.css";
import useUploadFile from "@hooks/useUploadFile";
import Video from "../Video";
import Audio from "../Audio";
import Pdf from "../Pdf";

export default ({
  type,
  setFileUrl,
  setFileUrlFromApi,
  accept,
  value,
  ...props
}) => {
  const renderFiles = (value) => {
    switch (type) {
      case "image":
        return <img src={value} alt="Selected" style={{ maxWidth: "100%" }} />;
      case "video":
        return <Video videoUrl={value} />;
      case "audio":
        return <Audio audioUrl={value} />;
      case "pdf":
        return <Pdf pdfUrl={value} />;

      default:
        return;
    }
  };
  // useEffect(() => {
  //   if (type) {
  //     // setFileUrl("");
  //     // setFileUrlFromApi("");
  //     console.log("types from multy = > ", type);
  //   }
  // }, [type]);
  const fileInputRef = useRef(null);
  const { url, fetching, error, handleUpload } = useUploadFile();
  useEffect(() => {
    url && setFileUrlFromApi(url);
  }, [url]);
  const handleImageInputChange = (event) => {
    const file = event.target.files[0];

    const url = URL.createObjectURL(file);
    const reader = new FileReader();

    reader.onloadend = () => {
      setFileUrl(reader.result);
      //    setFileUrl({ file, url });
      handleUpload(file);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = () => {
    setFileUrl("");
    setFileUrlFromApi("");
  };

  const handleCustomInputChange = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      {value ? (
        <div className="image-selector-container">
          {/* <img src={value} alt="Selected" style={{ maxWidth: "100%" }} />
           */}
          {renderFiles(value)}
          <img
            className="btnRmv"
            src={require("@imgs/removeItem.png")}
            onClick={handleImageRemove}
          />
        </div>
      ) : (
        <div className="cursor-pointer">
          <AppInput onClick={handleCustomInputChange} {...props} readOnly />
          <input
            type="file"
            accept={accept || "image/*"}
            onChange={handleImageInputChange}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
        </div>
      )}
    </div>
  );
};
