import React, { useEffect, useState, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { api } from "@globals/axiosConfig";
import { LoadingContext } from "@contexts/LoadingContext";
import ImageInput from "@components/ImageInput";
import { useSelector } from "react-redux";
import useGetUser from "@hooks/useGetUser";

export default () => {
  const getMe = useGetUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state) => state?.auth?.user);
  const [state, setState] = useState({
    email: user?.email,
    name: user?.name,
    logo: user?.society_info.logo,
    orgName: user?.society_info?.name,
  });
  const [logo, setLogo] = useState(
    user?.society_info.logo
      ? `${api}/api/v1/files/${user?.society_info.logo}/view`
      : null
  );
  useEffect(() => {
    setState({
      email: user?.email,
      name: user?.name,
      logo: user?.society_info.logo,
      orgName: user?.society_info?.name,
    });
    setLogo(
      user?.society_info.logo
        ? `${api}/api/v1/files/${user?.society_info.logo}/view`
        : null
    );
  }, [user]);
  let validForm = !(user?.is_society_owner
    ? state?.orgName && state?.name && state.logo
    : state?.name);
  const onSubmit = async () => {
    try {
      showLoader();
      await axios.post(`/manager/me/`, {
        name: state?.name,
        ...(user?.is_society_owner && {
          society_info: {
            name: state?.orgName,
            logo: state?.logo,
          },
        }),
      });
      user?.is_society_owner &&
        (await axios.put(`/manager/societies`, { name: state?.orgName }));

      getMe();
    } catch (e) {
      console.log("change2 pass error", e);
    } finally {
      hideLoader();
    }
  };

  return (
    <Container fluid className="  text-center h-100">
      <Row>
        <Col className="d-flex justify-content-start mt-4 ms-3">
          <h1 className="headerText">{t("Modifiermonprofil")}</h1>
        </Col>
      </Row>
      {/* users form  */}
      <Row className="mt-4 ms-1  ">
        <Col xs={12} md={4} className="">
          <Row>
            <Col className="d-flex justify-content-start mt-3">
              <div className="FormTitle">{t("Nomdelorganisation")}</div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-star mt-1">
              <div className="textFormContainer">
                <input
                  readOnly={!user?.is_society_owner}
                  id="orgnameupdateprofile"
                  value={state.orgName}
                  type="text"
                  onChange={(t) =>
                    setState({ ...state, orgName: t.target.value })
                  }
                  className="TabFormInput"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start mt-3">
              <div className="FormTitle">{t("Nomprenom")}</div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-star mt-1">
              <div className="textFormContainer">
                <input
                  id="inputnameupdateprofile"
                  value={state.name}
                  type="text"
                  onChange={(t) => setState({ ...state, name: t.target.value })}
                  className="TabFormInput"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start mt-3">
              <div className="FormTitle">{t("Email")}</div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-star mt-1">
              <div className="textFormContainer">
                <input
                  id="inputemailupdateprofile"
                  label="Email"
                  type="email"
                  onChange={(t) =>
                    setState({ ...state, email: t.target.value })
                  }
                  readOnly
                  className="TabFormInput"
                  value={state.email}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start mt-3">
              <div className="FormTitle">{t("Logo")}</div>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <ImageInput
                labelText={""}
                placeholder={t("Choisirunephoto")}
                value={logo}
                disabled={!user?.is_society_owner}
                required
                setImageUrl={setLogo}
                setImageUrlFromApi={(thumbnail) => {
                  setState({ ...state, logo: thumbnail });
                }}
                className="AppInput"
                LeftIcon={
                  <img
                    src={require("@imgs/LeftImageIcon.png")}
                    className="LeftIcon"
                    alt={"LeftIcon"}
                  />
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mt-5 ms-2 pb-5 d-flex justify-content-start ">
        <Col xs={12} md={4} lg={2} className="d-flex justify-content-start ">
          <div
            onClick={() => {
              !validForm && onSubmit();
            }}
            className="btnSave d-flex justify-content-center align-items-center"
          >
            {t("Sauvegarder")}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
