import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  lang: null,
};

const user = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    deleteUser: (state) => {
      state.user = null;
    },
    setLang: (state, action) => {
      state.lang = action.payload;
    },
    deleteLang: (state) => {
      state.lang = null;
    },
  },
});

export const { setUser, deleteUser, setLang, deleteLang } = user.actions;

export default user.reducer;
