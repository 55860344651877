import React, { useEffect, useState, useContext } from "react";
import { Col, Container, Row, Spinner, Table } from "react-bootstrap";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios, { api } from "@globals/axiosConfig";
import { LoadingContext } from "@contexts/LoadingContext";
import { setSubUser, deleteSubUser } from "./slice";
import { useDispatch } from "react-redux";
export default () => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState(10);

  // get users
  const getUsers = async (skip) => {
    try {
      showLoader();
      const resp = await axios.get(
        `/manager/managers/?$sort={"updated_at":"DESC"}&$top=${skip || 10}`
      );
      const data = resp?.data;
      if (data) {
        setData(data?.value);
        setCount(data?.count);
        setPagination(data?.top);
      }
    } catch (err) {
      console.log("get users err : ", err);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Container fluid className="  text-center h-100">
      <Row>
        <Col className="d-flex justify-content-start mt-4 ms-3 ">
          <h1 className="headerText">{t("Utilisateurs")}</h1>
        </Col>
        <Col className="d-flex justify-content-end mt-4 me-3">
          <div
            onClick={() => {
              navigate("/newUser");
            }}
            className="addUserBtn d-flex  justify-content-center align-items-center "
          >
            <div className="addUserText">{t("Nouvelutilisateur")}</div>
          </div>
        </Col>
      </Row>
      <Row className="UsersContainer mt-5 ms-4">
        <div style={{ width: "100%" }} className="tableStylesContainer">
          {data.length === 0 ? (
            <Col>Pas de donner</Col>
          ) : (
            <>
              <table class="table table-hover tableStyles">
                <tr className=" text-start">
                  <th scope="col">{t("Nom")}</th>
                  <th scope="col">{t("Email")}</th>
                  <th scope="col">{t("Activer")}</th>
                  <th scope="col">{t("Creationdecode")}</th>
                </tr>
                <tbody className="mt-3 text-start">
                  {data.map((item) => (
                    <tr
                      className="text-start"
                      onClick={() => {
                        // props.actions.setCurrentManagers(item);
                        dispatch(setSubUser(item));
                        // props.history.push("/utilisateur/details");
                        navigate("/DetailsUser");
                      }}
                    >
                      <td scope="row">{item?.name}</td>

                      <td>{item?.email}</td>

                      <td>{item?.account_enabled ? t("Oui") : t("Non")}</td>
                      <td>{item?.can_create_codes ? t("Oui") : t("Non")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {data?.length > 9 && data?.length !== count ? (
                <>
                  <Row className="pb-4">
                    <div
                      className="text-center  my-4"
                      style={{ cursor: "pointer", width: "100%" }}
                    >
                      <span
                        onClick={() => {
                          getUsers(pagination + 10);
                        }}
                        className="trier text-bold text-center"
                        style={{ cursor: "pointer", width: "200px" }}
                      >
                        {t("AfficherPlus")}
                      </span>
                    </div>
                  </Row>
                </>
              ) : (
                <div className="pb-5" />
              )}
            </>
            // this is afficher plus
          )}
        </div>
      </Row>
    </Container>
  );
};
