import { useContext, useState } from "react";
import axios from "@globals/axiosConfig";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "@contexts/LoadingContext";

// endpoints
import { createCodeUrl } from "../CodeCreationApi";

export default function useCreateCode() {
  const [fetching, setFetching] = useState(false);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const navigate = useNavigate();
  async function createCode(payload, cb = () => {}) {
    try {
      setFetching(true);
      showLoader();
      const rep = await axios.post(createCodeUrl(), payload);
      setFetching(false);
      cb(rep); 
      navigate("/");
    } catch (error) {
    } finally {
      setFetching(false);
      hideLoader();
    }
  } 

  return [fetching, createCode];
}
