import { useContext, useState } from "react";
import { createCommentUrl } from "../CodeMessagesApi";
import axios from "@globals/axiosConfig";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "@contexts/LoadingContext";
 
export default (id) => {
  const [fetchingHistory, setFetching] = useState(false);
  const [historyData, setData] = useState();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const navigate = useNavigate();
  async function getHistory(cb = () => {}, pagination) {
    try {
      setFetching(true);
      showLoader();
      const rep = await axios.get(
        createCommentUrl(
          `?$filter={"codes": ${JSON.stringify(id)}}&$top=${
            pagination || 10
          }`
        )
      );
     
      setFetching(false);
      setData(rep?.data?.value);
      // console.log("rep?.data?.value", rep?.data);
      cb(rep?.data?.value, rep?.data?.top, rep?.data?.count);
    } catch (error) {
    } finally {
      setFetching(false);
      hideLoader();
    }
  }
  return [fetchingHistory,historyData, getHistory];
};
