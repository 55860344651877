import React from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "./styles.css";

export default ({
  text,
  desabled,
  onClick,
  fetching,
  labelText,
  LeftComponent,
  customStyles,
  active,
  info,
  infoPlacement,
  ...props
}) => {
  return (
    <div className="MCodeBtnContainer text-center">
      <Button
        onClick={onClick}
        variant="outline-primary"
        className={
          (active ? "MCodeBtnActive" : "MCodeBtn") +
          (customStyles ? " " + customStyles : "")
        }
      >
        {fetching ? (
          <Spinner animation="border" />
        ) : (
          <>
            {LeftComponent && <LeftComponent />}
            {text}
          </>
        )}
      </Button>
      {labelText && (
        <Form.Label className="MCodelabelStyle ">{labelText}</Form.Label>
      )}
      {info && (
        <>
          {/* <br /> */}
          <OverlayTrigger
            // key={infoPlacement || "top"}
            placement={infoPlacement || "right"}
            overlay={info ? <Tooltip>{info}</Tooltip> : <></>}
          >
            <Form.Label className="MCodelabelStyle ">{"(i)"}</Form.Label>
          </OverlayTrigger>
        </>
      )}
    </div>
  );
};
