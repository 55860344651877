export function codesUrl(params) {
  return `/manager/codes/?${params}&$sort={ "updated_at": "DESC"}`;
}

export function createCodeUrl() {
  return `/manager/codes/`;
}
export function deleteCodeUrl(id) {
  return `/manager/codes/${id}`;
}
export function CodesByIdUrl(id) {
  return `/manager/codes/${id}?$expand=qrcode`;
}
export function deleteCodefromProByIdUrl(id) {
  return `/manager/codes/${id}/free?$expand=qrcode`;
}
export function createCommentUrl(params) {
  return `/manager/messages/${params}&$sort={ "updated_at": "DESC"}`;
}
export function sendMessageUrl() {
  return `/manager/messages/`;
}
export function ResendMessageUrl(id) {
  return `/manager/messages/${id}/resend`;
}
export function CodesProUrl(id) {
  return `/manager/codes/${id}/pro`;
}
