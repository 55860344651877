import React from "react";
import { Container } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Button from "@components/Button";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state) => state?.auth?.user);

  return (
    <Container className="  text-center h-100">
      {user?.can_create_codes && (
        <>
          <h1 className="mt-5 EmptyheaderText1">{t("Creezouimportez")}</h1>
          <h2 className=" EmptySubHeaderText1">{t("CommencezCommuniquer")}</h2>
        </>
      )}
      {!user?.can_create_codes && (
        <>
          <h1 className="mt-5 fs-1">
            {"Merci de demander à votre administrateur de créer des codes"}
          </h1>
          {/* <h2 className=" mb-5 fs-3">{t("CommencezCommuniquer")}</h2> */}
        </>
      )}
      {/* <img 
        src={require("@imgs/pers.png")}
        className="EmptyIcon"
        alt={"imageComponent"}
      /> */}
      <div className="EmptyIconContainer pb-1 pt-5">
        <Image
          src={require("@imgs/EmptyIcon.png")}
          className="EmptyIcon"
          thumbnail
        />
      </div>
      {user?.can_create_codes && (
        <div className="EmptyButtonContainer pt-5">
          <Button
            text={"+ " + t("creercodeGratuit")}
            fetching={false}
            customStyles='btnHight'
            onClick={() => {
              navigate(`/CodeCreation`);
            }}
          />
        </div>
      )}
    </Container>
  );
};
