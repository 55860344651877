import React from "react";
import classNames from "classnames";
import { FormGroup } from "reactstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Form from "react-bootstrap/Form";
import "./styles.css";
export default function AppInput({
  labelText,
  required,
  hasError,
  name,
  placeholder,
  type,
  value,
  onChange,
  country,
  pattern,
  info,
  onCountryChange,
  ...props
}) {
  return (
    <>
      <FormGroup>
        <Form.Label
          for="phoneNumber"
          className={"labelStyle  " + (info && "pointercursor")}
        >
          {labelText + (required ? " *" : info ? " (i)" : "")}
        </Form.Label>
        <PhoneInput
          international
          country={country}
          defaultCountry={country}
          id="phoneNumber"
          name="phone"
          placeholder={placeholder}
          type="tel"
          className={classNames("custom-input", {
            // matriculeLast: props.className === 'matricule-last',
            error: hasError,
          })}
          onCountryChange={onCountryChange}
          value={value}
          onChange={(e, country) => {
            onChange(e);
          }}
          autoComplete="off"
          step="1"
          pattern={pattern}
          {...props}
        />

        {props.leftAddOn && props.leftAddOn}
        <div></div>
      </FormGroup>
    </>
  );
}
