import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import "./styles.css";
import { useTranslation } from "react-i18next";

export default ({onClick, codeName, scans,created_at, adherents, logo, isPro,active, ...props }) => {
    const { t } = useTranslation();

  return (
    <Row  onClick={onClick}>
      <Col
        className={
          "pointer hightforItem align-items-center d-flex flex-row" +
          (active ? " active" : "")
        }
      >
        <Image
          className=" ms-3 me-4  itemIcon"
          src={logo || require("@imgs/googleIcon.png")}
        />
        <span className="itemTextContainer">
          <span className="itemFirstText">{codeName}</span>
          <span className="itemSecondText">
            {`${scans} ${t("scans")} | ${adherents} ${t("adherents")}`}
          </span>
        </span>
        {isPro && (
          <Image
            className="  me-4 ms-auto  itemProIcon"
            src={require("@imgs/proIcon.png")}
          />
        )}
      </Col>
    </Row>
  );
};
