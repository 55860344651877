import React from "react";
import "./styles.css";
import { isMobile } from "react-device-detect";
import AppInput from "@components/AppInput";
import Button from "@components/Button";
import Copyright from "@components/Copyright";
import useLogin from "../hooks/useLogin";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default () => {
  const navigator = useNavigate();
  const { handleLogin, fetching, error } = useLogin();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const { t } = useTranslation();

  return (
    <div className={`AuthContainerLogin  ${isMobile&&'noScrollx'}`}>
      <div className="iconTopContainer">
        <h1 className="headerTextAuth">{t("Connexion")}</h1>
        <img
          src={require("@imgs/lock.png")}
          className="iconTop"
          alt={"imageComponent"}
        />
      </div>
      <AppInput
        onChange={setUsername}
        value={username}
        labelText={t("E_mail")}
        required
        className="AppInput"
      />
      <div className="between" />
      <AppInput
        value={password}
        onChange={setPassword}
        labelText={t("Motdepasse")}
        type={showPassword ? "text" : "password"}
        required
        // id='passwordauth'
        className="AppInput"
        onRightClick
        RightIcon={
          <img
            src={require(`@imgs/${showPassword ? "close-eye" : "eye"}.png`)}
            className="RightIcon"
            alt={"RightIcon"}
            onClick={() => setShowPassword(!showPassword)}
          />
        }
      />
      <div
        onClick={() => navigator("forgot")}
        className="forgotPasswordContainer my-4 "
      >
        {t("Motdepasseoublie")}
      </div>
      {/* <div className="between mt-4" /> */}
      <Button
        text={t("Connexion")}
        disabled={!username || !password}
        fetching={fetching}
        onClick={() => {
          handleLogin(username, password);
        }}
      />
      <div className="between" />

      <p onClick={() => navigator("signUp")} className="signInText mt-4">
        <span>
          {t("Pasencoredecompte")} &nbsp;
          <a className="green-text ">{t("Inscrivezvous")}</a>
        </span>
      </p>
      {!isMobile&&<Copyright/>}
    </div>
  );
};
