// useLogin.js
import { useState, useEffect, useContext } from "react";
import axios from "@globals/axiosConfig";
import { setToken } from "@globals/authService";
import { loginUrl } from "../AuthEndPoints";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "@contexts/LoadingContext";
import { setUser, deleteUser } from "../slice";
import { useDispatch } from "react-redux";
const useLogin = () => {
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(false);
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useContext(LoadingContext);
const dispatch = useDispatch();
  const handleLogin = async (username, password) => {
    // username, password;
    try {
      showLoader();
      setFetching(true);
      const response = await axios.post(loginUrl(), {
        username,
        password,
      });
      console.log("user data from login : ", response.data);
      const { token } = response.data;
      setToken(token);
      dispatch(setUser(response.data));
      navigate("/");
    
    } catch (error) {
      console.error("Login failed:", error);
      setError("Invalid username or password.");
    } finally {
      setFetching(false);
      hideLoader();
    }
  };

  return {
    handleLogin,
    error,
    fetching,
  };
};

export default useLogin;
