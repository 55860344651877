// Auth.js
import React from "react";
import Container from "react-bootstrap/Container";
import "./styles.css";
import Payment from "./Payment";
import AppNavBar from "@components/AppNavBar";
export default () => {
  return (
    <Container fluid className="AppBackground disableScroll">
      <AppNavBar />
      <Container fluid className="bg-white AppFrame disableScroll">
        {/* <Outlet /> */}
        <Payment />
      </Container>
    </Container>
  );
};
