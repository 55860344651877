import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";

import App from "@/App";
import Auth from "@/modules/Auth";
import Home from "@/modules/Home";
import Plans from "@/modules/plans";
import AuthRoutes from "@/modules/Auth/routes";
import HomeRoutes from "@/modules/Home/routes";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default () => {
  const { i18n } = useTranslation();
  const selectedLanguage = useSelector((state) => state?.auth?.lang);
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, []);
  return (
    <HashRouter>
      <Routes>
        <>
          <Route
            path={"Auth"}
            element={
              <PublicRoute>
                <Auth />
              </PublicRoute>
            }
            children={AuthRoutes?.map((element, index) => (
              <Route
                index={element?.index}
                key={index}
                path={element?.path}
                element={element?.element}
              />
            ))}
          />
          <Route
            path={"/"}
            // index
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
            children={HomeRoutes.map((element, index) => (
              <Route
                index={element?.index}
                key={index}
                path={element?.path}
                element={element?.element}
                children={element?.children?.map((element, index) => (
                  <Route
                    key={index}
                    index={element?.index}
                    path={element?.path}
                    element={element?.element}
                  />
                ))}
              />
            ))}
          />
          <Route
            path={"Plans"}
            element={
              // <PublicRoute>
                <Plans />
              // </PublicRoute>
            }
            
          />
        </>
      </Routes>
    </HashRouter>
  );
};
