import React, { useRef, useEffect, useState, useCallback } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import CodeItem from "@components/CodeItem";
import { Outlet } from "react-router-dom";
import useGetCodes from "./hooks/useGetCodes";
import { api } from "@globals/axiosConfig";
import "./styles.css";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//toolkit actions
import {
  setCode as setReduxCode,
  deleteCode,
  setmobileView,
  deletemobileView,
} from "../slice";
import { debounce, unionBy } from "lodash";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

const findCodeItemById = (data, id) => {
  // Check if data and data are defined
  if (data && data && id) {
    // Use the find method to locate the item by id
    const foundItem = data.find((item) => item.id === id);

    // Return the found item or null if not found
    return foundItem || null;
  }

  // If data or data is not defined, return null
  return null;
};

export default () => {
  const { t } = useTranslation();
  const code = useSelector((state) => state?.home?.code);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSearch, setIsSearch] = React.useState(false);
  const [pagination, setPagination] = useState(10);
  let location = useLocation()?.pathname;
  const [count, setCount] = useState(0);
  //TODO GET CODES LIST
  const [fetching, data, getCodes] = useGetCodes();
  const [skip, setSkip] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  //TODO FEGURE HOW TO MAKE THIS DEBOUNCER GOLBALY  REUSEABLE
  // Debounce the getCodes function
  const debouncedGetCodes = useCallback(
    debounce((...args) => getCodes(...args), 1000),
    []
  );
  // Handle search  input changes
  const handleInputChange = (event) => {
    const newText = event.target.value;
    setSearchTerm(newText);
    // Trigger the debounced getCodes with your parameters
    debouncedGetCodes(
      (ListData, top, count) => {
        // Optional callback logic if needed
        // setPagination(10);
        setCount(count);
        setPagination(top);
      },
      10,
      newText
    );
  };
  useEffect(() => {
    getCodes((ListData, top, count) => {
      setCount(count);
      setPagination(top);
      //this is the callback ok in the resolver
      if (!location?.includes("MessageDetails")) {
        if (ListData) {
          const localCodeEmpty = ListData[0];
          if (!code) {
            dispatch(setReduxCode(localCodeEmpty));
            localCodeEmpty?.is_pro
              ? navigate(`NewMessage`)
              : navigate(`ActivateProMode`);
          } else {
            if (findCodeItemById(ListData, code?.id)) {
              const localCode = findCodeItemById(ListData, code?.id);
              dispatch(setReduxCode(localCode));
              localCode?.is_pro
                ? navigate(`NewMessage`)
                : navigate(`ActivateProMode`);
            }
          }
        }
      }
    });
  }, [location]);
  //-----------------------HANDLE SCROLLING
  const divRef = useRef(null);
  // // TODO fix scrol pagination
  // const handleScroll = () => {
  //   const div = divRef.current;
  //   const threshold = 1;
  //   // Check if the user is at or near the bottom - - - - - >
  //   if (div.scrollHeight - div.scrollTop <= div.clientHeight + threshold) {
  //     console.log("End of content. Load more data for page");
  //     // setCount(datacount+10);
  //     setPagination(pagination + 10);
  //     // pagination < datacount &&
  //     //   getCodes((data, top, count) => {
  //     //     setPagination(top);
  //     //     // setCount(count);
  //     //   }, pagination + 10);
  //   }
  // };
  // useEffect(() => {
  //   const div = divRef.current;
  //   div.addEventListener("scroll", handleScroll);
  //   return () => {
  //     // Cleanup: Remove the event listener when the component is unmounted
  //     div.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // Ensure the effect runs only once during component mount
  //end HANDLE SCROLLING -------------------

  //mobile handling logic
  // const [ShowFirstPageInMobile, setShowFirstPageInMobile] = useState(false);
  const ShowFirstPageInMobile = useSelector((state) => state?.home?.mobileView);
  // const [isMobile, setIsMobile] = useState(true);
  return (
    <Container fluid className=" mesCodesContainer h-100">
      <Row className=" gap-3 h-100">
        {/* left side */}
        {(!ShowFirstPageInMobile || !isMobile) && (
          <Col
            ref={divRef}
            className=" disableScroll order-md-1 order-2 Col-Left h-100 pt-2 overflowClass"
            xs={12}
            lg={6}
          >
            <Row className="px-3">
              <Col xs={10} md={6} className="pt-2 ms-2 ">
                <span className="mesCodesText">{t("Mescodes")}</span>
              </Col>

              {isSearch ? (
                <Col className="    d-flex justify-content-end align-items-center">
                  <Form.Group
                    className="searchInputContainerClass me-3 d-flex align-items-center py-3"
                    controlId="formGroupEmail"
                  >
                    <Image
                      className=" searchGreenIcon me-3"
                      src={require("@imgs/searchGreenIcon.png")}
                      // roundedCircle\
                      onClick={() => {}}
                    />
                    <div className="controlContainer">
                      <input
                        className="searchInputClass2"
                        type={"text"}
                        placeholder={t("Cherchercode")}
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                    </div>
                    {searchTerm && (
                      <Image
                        className="cursorEnabled searchGreenIcon2 me-3"
                        src={require("@imgs/close.png")}
                        // roundedCircle\
                        onClick={() => {
                          setIsSearch(false);
                          setSearchTerm("");
                          debouncedGetCodes(
                            (ListData, top, count) => {
                              // Optional callback logic if needed
                              // setPagination(10);
                              setCount(count);
                              setPagination(top);
                            },
                            10,
                            ""
                          );
                        }}
                      />
                    )}
                  </Form.Group>
                </Col>
              ) : (
                <Col className=" col-1 ms-auto  d-flex justify-content-end align-items-center">
                  <Image
                    onClick={() => {
                      setIsSearch(true);
                    }}
                    className="cursorEnabled searchGreenIcon"
                    src={require("@imgs/searchGreenIcon.png")}
                    // roundedCircle
                  />
                </Col>
              )}
            </Row>
            <div className="pt-4 " ref={divRef}>
              {data?.length > 0 && <div className="divider" />}

              {data?.map((element, index) => (
                <>
                  <CodeItem
                    onClick={() => {
                      // TODO IF IS PRO REDIRECT
                      element?.id !== code?.id &&
                        dispatch(setReduxCode(element));
                      if (element?.is_pro) {
                        console.log("is pro");
                        navigate(`NewMessage`, { replace: true });
                      } else {
                        console.log("is not pro");
                        navigate(`../ActivateProMode`, { replace: true });
                      }
                      isMobile && dispatch(setmobileView(true));
                    }}
                    codeName={element?.name}
                    scans={element?.count_scans}
                    // created_at={}
                    adherents={element?.count_subscriptions}
                    logo={api + element?.thumbnail_url}
                    isPro={element?.is_pro}
                    active={element?.id === code?.id}
                  />
                  <div className="divider" />
                </>
              ))}
              {data?.length > 9 && data?.length !== count ? (
                <>
                  <Row className="pb-4">
                    <div
                      className="text-center  my-4"
                      style={{ cursor: "pointer", width: "100%" }}
                    >
                      <span
                        onClick={() => {
                          setSearchTerm("");
                          getCodes((ListData, top, count) => {
                            setCount(count);
                            setPagination(top);
                            //this is the callback ok in the resolver
                            if (!location?.includes("MessageDetails")) {
                              if (ListData) {
                                const localCodeEmpty = ListData[0];
                                if (!code) {
                                  dispatch(setReduxCode(localCodeEmpty));
                                  localCodeEmpty?.is_pro
                                    ? navigate(`NewMessage`)
                                    : navigate(`ActivateProMode`);
                                } else {
                                  if (findCodeItemById(ListData, code?.id)) {
                                    const localCode = findCodeItemById(
                                      ListData,
                                      code?.id
                                    );
                                    dispatch(setReduxCode(localCode));
                                    localCode?.is_pro
                                      ? navigate(`NewMessage`)
                                      : navigate(`ActivateProMode`);
                                  }
                                }
                              }
                            }
                          }, pagination + 10);
                        }}
                        className="trier text-bold text-center"
                        style={{ cursor: "pointer", width: "200px" }}
                      >
                        {t("AfficherPlus")}
                      </span>
                    </div>
                  </Row>
                </>
              ) : (
                <div className="pb-5" />
              )}
            </div>
          </Col>
        )}

        {(ShowFirstPageInMobile || !isMobile) && (
          <Col className="Col-right AppBg disableScroll order-mp-2 order-1  h-100 pt-3 pt-md-0 overflowClass   ">
            <Row className="  gap-3">
              <Col className="   minWidthforResponsive statistic-col   align-items-center d-flex flex-row">
                <Image
                  className=" ms-1 me-2  itemIcon"
                  src={api + code?.thumbnail_url}

                  // roundedCircle
                />
                <span className="statisticTextContainer">
                  <span className="statisticsFirstText">{code?.name}</span>
                  <span className=" marginTopstyleforText statisticsSecondText">{`${t(
                    "Creele"
                  )} ${moment(code?.created_at).format("DD/MM/YYYY")}`}</span>
                </span>
              </Col>
              <Col className="   minWidthforResponsive statisticScanCountCol   align-items-center d-flex flex-row">
                <Image
                  className=" ms-1 me-2 statisticIcon"
                  src={require("@imgs/barScannerIcon.png")}
                  // roundedCircle
                />
                <span className="statisticTextContainer">
                  <span className="statisticsFirstTextScans">
                    {code?.count_scans}
                  </span>
                  <span className="statisticsSecondTextScans">
                    {t("Nombredescans")}
                  </span>
                </span>
              </Col>
              <Col className="   minWidthforResponsive statisticScanPeoplCountCol   align-items-center d-flex flex-row">
                <Image
                  className=" ms-1 me-2 statisticIcon"
                  src={require("@imgs/peopleIcon.png")}
                  // roundedCircle
                />
                <span className="statisticTextContainer">
                  <span className="statisticsFirstTextPeople">
                    {code?.count_subscriptions}
                  </span>
                  <span className="statisticsSecondTextScansPeople">
                    {t("NombreDadherents")}
                  </span>
                </span>
              </Col>
            </Row>
            <Row className=" h-100  detailsRow mt-4 overflowClass  ">
              <Outlet />
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );
};
