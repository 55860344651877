export function loginUrl() {
  return "/auth/managers/signin";
}

export function signupUrl() {
  return "/auth/managers/signup";
}

export function forgotUrl() {
  return "/auth/managers/forgot";
}
