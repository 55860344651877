import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AppRouter from "@/modules/AppRouting";
import { AuthProvider } from "./contextProviders/AuthContext";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/fonts/avenirNext/avenir.css";
import { ModalProvider } from "./contextProviders/ModalContext";
import { store, persistor } from "@store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import LoadingProvider from "./contextProviders/LoadingContext";
import { I18nextProvider } from "react-i18next";
import i18n from "./language";





const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <LoadingProvider>
          <AuthProvider>
            <ModalProvider>
              <ToastContainer />
              <AppRouter />
            </ModalProvider>
          </AuthProvider>
        </LoadingProvider>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
