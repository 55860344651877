import axios from "axios";
import { toast } from "react-toastify";
import { getToken } from "@globals/authService";
import { store } from "@store";

export const api =
  process.env.NODE_ENV === "production"
    ? "https://api.cool2scan.com"
    : "https://api.cool2scan.com";
export const apiPrefix = "/api/v1";

const axiosInstance = axios.create({
  baseURL: api + apiPrefix,
  timeout: 50000,
  // headers: {
  //   "Content-Type": "application/json",
  //   Authorization: "Bearer YourAccessToken",
  // },
  // You can add other configurations here, such as headers, timeout, etc.
});
//TODO REMEMBER TO LESTEN FOR THE LOGIN SUCCESS TO SET THE TOKEN HERE
axiosInstance.interceptors.request.use(
  (config) => {
    //TODO ADD LANG HERE
    const currentLanguage = store.getState()?.auth?.lang;
    console.log(
      "EEEE => from axios [",
      currentLanguage,
      "]",
      config.url +
        (config.url?.includes("?")
          ? `&$lng=${currentLanguage}`
          : `?$lng=${currentLanguage}`)
    );
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return {
      ...config,
      url:
        config.url +
        (config.url?.includes("?")
          ? `&$lng=${currentLanguage}`
          : `?$lng=${currentLanguage}`),
    };
  },
  (error) => {
    console.log("[ * axios request error * ]", error);
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    //const url = response.config.url;
    //setLocalStorageToken(token);
    return response;
  },
  (error) => {
    if (error?.response?.status === 413) {
      toast.error("File size is too large. Please upload a smaller file.", {
        position: "top-right",
        hideProgressBar: true,
      });
    }
    if (error?.response?.status >= 300) {
      console.log("[ * response axios global error * ]", error);
      console.log("[ message test ] '" + error?.response?.data?.message + "'");
      if (
        !(
          error?.response?.data?.message ===
            "Vous n'êtes pas autorisés à envyer des Messages (il faut que tous les codes soient PRO)." ||
          error?.response?.data?.message ===
            "Nombre codes Pro disponibles est: 0" ||
          error?.response?.data?.message ===
            "Number of Pro codes available is: 0" ||
          error?.response?.data?.message === "Verfügbare Pro-Codes: 0" ||
          error?.response?.data?.message ===
            "Numero di codici Pro disponibili: 0" ||
          error?.response?.data?.message === "Códigos Pro disponibles: 0" ||
          error?.response?.data?.message === "Code expired." ||
          error?.response?.data?.message === "Code expiré." ||
          error?.response?.data?.message === "Code abgelaufen." ||
          error?.response?.data?.message === "Codice scaduto." ||
          error?.response?.data?.message === "Código expirado."
        )
      ) {
        if (error?.response?.data?.message) {
          error?.response?.data?.message !== "USER_NOT_SIGNED_IN" &&
            toast.error(
              error?.response?.data?.message ||
                "Nom d'utilisateur ou mot de passe erroné",
              {
                position: "top-right",
                hideProgressBar: true,
              }
            );
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
