// useGetUser.js
import { useContext } from "react";
import axios from "@globals/axiosConfig";
import { LoadingContext } from "@contexts/LoadingContext";
import { setUser, deleteUser } from "@Auth/slice";
import { useDispatch } from "react-redux";

const useGetUser = () => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const dispatch = useDispatch();
  const getMe = async () => {
    try {
      showLoader();
      const res = await axios.get("/manager/me");
      dispatch(setUser(res.data));
      console.log('=====> user from get me : ',res.data);
    } catch (error) {
      console.error("signOutErr:", error);
    } finally {
      hideLoader();
      // navigate("/auth");
    }
  };

  return getMe;
};

export default useGetUser;
