// useUploadFile.js
import { useContext, useState } from "react";
import axios from "@globals/axiosConfig";
import { LoadingContext } from "@contexts/LoadingContext";

const useUploadFile = (isQrCode = false) => {
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [url, setUrl] = useState();
  const { showLoader, hideLoader } = useContext(LoadingContext);

  const handleUpload = async (file) => {
    try {
      showLoader();
      setFetching(true);
      let fileUpload = new FormData();
      fileUpload.append("file", file);
      const res = await axios.post(
        isQrCode ? "/manager/qrcodes" : "/files",
        fileUpload
      );
      //id of file
      if (isQrCode) {
        setUrl(res?.data?.id);
      } else {
        setUrl(res?.data?.file);
      }

      console.log("res image uploader hook : ", res);
      //TODO REMOVE
      // setUrl(
      //   "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789" +
      //     Math.floor(Math.random() * 1000)
      // );
    } catch (error) {
      console.error("upload failed:", error);
      setError(error);
      // setUrl(
      //   "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789" +
      //     Math.floor(Math.random() * 1000)
      // );
    } finally {
      setFetching(false);
      hideLoader();
    }
  };

  return {
    url,
    fetching,
    error,
    handleUpload,
  };
};

export default useUploadFile;
