// Navbar.js
import React, { useEffect, useState } from "react";
import "./styles.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useLogOut from "@hooks/useLogOut";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { api } from "@globals/axiosConfig";
import {
  // // setCode as setReduxCode,
  deleteCode,
  // setmobileView,
  deletemobileView,
} from "@Home/slice";

import { isMobile } from "react-device-detect";
import { Col, Row } from "react-bootstrap";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { setLang } from "@Auth/slice";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

export default () => {
  const user = useSelector((state) => state?.auth?.user);
  const mobileView = useSelector((state) => state?.home?.mobileView);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(setLang(lng));
  };
  const currentLanguage = i18n.language;
  let location = useLocation()?.pathname;
  const [expand, setExpand] = useState(false);
  const [flag, setFlag] = useState(currentLanguage);

  useEffect(() => {
    setFlag(currentLanguage);
  }, [currentLanguage]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleLogOut = useLogOut();
  //mobileStates
  const [isOpenMenu1, setIsOpenMenu1] = useState(false);
  const [isOpenMenu2, setIsOpenMenu2] = useState(false);
  const isMobileMedia = useMediaQuery("(max-width: 988px)");
  useEffect(() => {
    !isMobileMedia && setShow(false);
  }, [isMobileMedia]);
  return (
    <Navbar onToggle={setExpand} expand="lg" className="AppNavBar  ">
      {isMobile ? (
        <>
          {mobileView &&
          (location.includes("/NewMessage") ||
            location.includes("/MessageDetails") ||
            location.includes("/ActivateProMode")) ? (
            <>
              <img
                onClick={() => {
                  dispatch(deletemobileView());
                }}
                src={require("@imgs/arrowLeft.png")}
                className="arrowIconBack"
                alt={"imageComponent"}
              />
            </>
          ) : (
            <Navbar.Toggle
              onClick={() => {
                user && setShow(!show);
              }}
              aria-controls="basic-navbar-nav"
              className="me-auto toggell "
            />
          )}
          {isMobileMedia && (
            <img
              src={require("@imgs/brand.png")}
              className="brandImageMobile"
              alt={"imageComponent"}
            />
          )}
        </>
      ) : (
        <Navbar.Toggle
          onClick={() => {
            user && setShow(!show);
          }}
          aria-controls="basic-navbar-nav"
          className="me-auto toggell "
        />
      )}

      <Navbar.Offcanvas
        show={show}
        onHide={handleClose}
        id={`offcanvasNavbar-expand-md`}
        aria-labelledby={`offcanvasNavbarLabel-expand-md`}
        placement="start"
      >
        <Offcanvas.Header
          onHide={handleClose}
          className="ms-auto "
          closeButton
        ></Offcanvas.Header>
        <Offcanvas.Body
          className={
            expand
              ? ""
              : "d-lg-flex align-items-lg-center flex-lg-wrap justify-content-lg-start"
          }
        >
          {!isMobileMedia && (
            <Nav className="">
              <img
                onClick={() => {
                  navigate("/");
                }}
                src={require("@imgs/brand.png")}
                className="brandImage"
                alt={"imageComponent"}
              />
            </Nav>
          )}
          {isMobileMedia ? (
            <>
              {/* app menu  */}
              {
                <Row
                  className={
                    isOpenMenu1 ? "pb-3 cursorpointer " : "cursorpointer"
                  }
                >
                  <Col className=" me-5">
                    <div
                      onClick={() => {
                        setIsOpenMenu1(!isOpenMenu1);
                      }}
                      className="d-flex justify-content-between align-items-center ms-3"
                    >
                      <div style={{ display: "flex" }}>
                        <img
                          src={`${api}/api/v1/files/${user?.society_info?.logo}/view`}
                          className="persIcon"
                          alt={"imageComponent"}
                        />
                        <span
                          className={
                            user?.is_society_owner
                              ? "UserNameContainer"
                              : "UserNameContainer2"
                          }
                        >
                          <span className="UserNameText">{user?.name}</span>
                          <span className="CorpText">
                            {user?.is_society_owner ? user?.society?.name : ""}
                          </span>
                        </span>
                      </div>
                      <img
                        src={
                          isOpenMenu1
                            ? require("@imgs/arrowUp.png")
                            : require("@imgs/arrow.png")
                        }
                        className="arrowIcon"
                        alt={"imageComponent"}
                      />
                    </div>
                  </Col>
                </Row>
              }
              {isOpenMenu1 && (
                <>
                  <Row>
                    <div className="DropdownDivider" />
                    <Col
                      // onClick={() => {
                      //   handleClose();
                      //   navigate("/Payment");
                      // }}
                      className="dropdownItem d-flex justify-content-start ms-3 align-items-center  "
                    >
                      <img
                        src={require("@imgs/measCodesIcon.png")}
                        className="btnIcon"
                        alt={"imageComponent"}
                      />
                      <Link
                        onClick={() => {
                          handleClose();
                          setIsOpenMenu1(!isOpenMenu1);
                          //TODO DELETE SELECTED CODE
                          dispatch(deleteCode());
                        }}
                        to={"/"}
                        className="btnLinkText"
                      >
                        {t("Mescodes")}
                      </Link>
                    </Col>
                    <div className="DropdownDivider" />
                  </Row>

                  {user?.can_create_codes && (
                    <Row>
                      <Col
                        onClick={() => {
                          handleClose();
                          setIsOpenMenu1(!isOpenMenu1);
                        }}
                        className="dropdownItem d-flex justify-content-start  align-items-center "
                      >
                        <Link to={"/CodeCreation"} className="btnLinkText">
                          &nbsp;{"+ " + t("Creeruncode")}
                        </Link>
                      </Col>
                      <div className="DropdownDivider" />
                    </Row>
                  )}

                  {user?.is_society_owner && (
                    <>
                      <Row>
                        <Col
                          onClick={() => {
                            handleClose();
                            navigate("/Users");
                            setIsOpenMenu1(!isOpenMenu1);
                          }}
                          className="dropdownItem d-flex justify-content-start ms-3 align-items-center "
                        >
                          {t("Monequipe")}
                        </Col>
                        <div className="DropdownDivider" />
                      </Row>
                    </>
                  )}
                  {user?.is_society_owner && (
                    <>
                      <Row>
                        <Col
                          onClick={() => {
                            handleClose();
                            navigate("/plans");
                            setIsOpenMenu1(!isOpenMenu1);
                          }}
                          className="dropdownItem d-flex justify-content-start ms-3 align-items-center "
                        >
                          {t("Monabonnement")}
                        </Col>
                        <div className="DropdownDivider" />
                      </Row>
                    </>
                  )}

                  <Row>
                    <Col
                      onClick={() => {
                        handleClose();
                        navigate("/UpdateProfile");
                        setIsOpenMenu1(!isOpenMenu1);
                      }}
                      className="dropdownItem d-flex justify-content-start ms-3 align-items-center "
                    >
                      {t("Monprofil")}
                    </Col>
                    <div className="DropdownDivider" />
                  </Row>
                  <Row>
                    <Col
                      onClick={() => {
                        handleClose();
                        navigate("/UpdatePassword");
                        setIsOpenMenu1(!isOpenMenu1);
                      }}
                      className="dropdownItem d-flex justify-content-start ms-3 align-items-center "
                    >
                      {t("Securite")}
                    </Col>
                    <div className="DropdownDivider" />
                  </Row>
                  <Row>
                    <Col
                      onClick={() => {
                        handleClose();
                        handleLogOut(t);
                        setIsOpenMenu1(!isOpenMenu1);
                      }}
                      className="dropdownItem d-flex justify-content-start ms-3 align-items-center  "
                    >
                      {t("Sedeconnecter")}
                    </Col>
                    <div className="DropdownDivider mb-4 " />
                  </Row>
                </>
              )}
              {!isOpenMenu1 && <div className="DropdownDivider my-3" />}
              {/* lang menu  */}
              <Row
                className={
                  isOpenMenu2 ? "pb-3 cursorpointer" : " cursorpointer "
                }
              >
                <Col className=" me-5 ">
                  <div
                    onClick={() => {
                      setIsOpenMenu2(!isOpenMenu2);
                    }}
                    className="d-flex justify-content-between align-items-center ms-3"
                  >
                    <img
                      src={require(`@imgs/${flag}.png`)}
                      className="flagIcon2"
                      alt={"imageComponent"}
                    />
                    <img
                      src={
                        isOpenMenu2
                          ? require("@imgs/arrowUp.png")
                          : require("@imgs/arrow.png")
                      }
                      className="arrowIcon"
                      alt={"imageComponent"}
                    />
                  </div>
                </Col>
              </Row>
              {!isOpenMenu2 && <div className="DropdownDivider my-3" />}
              {isOpenMenu2 && (
                <>
                  {flag !== "fr" && (
                    <Row>
                      <div className="DropdownDivider" />

                      <Col
                        onClick={() => {
                          changeLanguage("fr");
                          setFlag("fr");
                          handleClose();
                          setIsOpenMenu2(!isOpenMenu2);
                        }}
                        className="dropdownItem d-flex justify-content-start ms-3 align-items-center "
                      >
                        <img
                          src={require(`@imgs/fr.png`)}
                          className="flagIcon2"
                          alt={"imageComponent"}
                        />
                      </Col>
                      <div className="DropdownDivider" />
                    </Row>
                  )}
                  {flag !== "en" && (
                    <Row>
                      <Col
                        onClick={() => {
                          handleClose();
                          changeLanguage("en");
                          setFlag("en");
                          setIsOpenMenu2(!isOpenMenu2);
                        }}
                        className="dropdownItem d-flex justify-content-start ms-3 align-items-center "
                      >
                        <img
                          src={require(`@imgs/en.png`)}
                          className="flagIcon2"
                          alt={"imageComponent"}
                        />{" "}
                      </Col>
                      <div className="DropdownDivider" />
                    </Row>
                  )}
                  {flag !== "gr" && (
                    <Row>
                      <Col
                        onClick={() => {
                          handleClose();
                          changeLanguage("gr");
                          setFlag("gr");
                          setIsOpenMenu2(!isOpenMenu2);
                        }}
                        className="dropdownItem d-flex justify-content-start ms-3 align-items-center "
                      >
                        <img
                          src={require(`@imgs/gr.png`)}
                          className="flagIcon2"
                          alt={"imageComponent"}
                        />{" "}
                      </Col>
                      <div className="DropdownDivider" />
                    </Row>
                  )}
                  {flag !== "it" && (
                    <Row>
                      <Col
                        onClick={() => {
                          handleClose();
                          changeLanguage("it");
                          setFlag("it");
                          setIsOpenMenu2(!isOpenMenu2);
                        }}
                        className="dropdownItem d-flex justify-content-start ms-3 align-items-center "
                      >
                        <img
                          src={require(`@imgs/it.png`)}
                          className="flagIcon2"
                          alt={"imageComponent"}
                        />{" "}
                      </Col>
                      <div className="DropdownDivider" />
                    </Row>
                  )}
                  {flag !== "sp" && (
                    <Row>
                      <Col
                        onClick={() => {
                          handleClose();
                          handleLogOut(t);
                          handleClose();
                          changeLanguage("sp");
                          setFlag("sp");
                          setIsOpenMenu2(!isOpenMenu2);
                        }}
                        className="dropdownItem d-flex justify-content-start ms-3 align-items-center "
                      >
                        <img
                          src={require(`@imgs/sp.png`)}
                          className="flagIcon2"
                          alt={"imageComponent"}
                        />{" "}
                      </Col>
                      <div className="DropdownDivider" />
                    </Row>
                  )}
                </>
              )}
              {/* contact  */}
              <Row className={isOpenMenu2 && "mt-3   "}>
                <Col className=" me-5 ">
                  <div
                    onClick={() => {
                      // setIsOpenMenu2(!isOpenMenu2);
                    }}
                    className="d-flex justify-content-start align-items-center ms-3"
                  >
                    <img
                      onClick={() => {
                        handleClose();
                        window.open("https://cool2scan.com/#contact");
                      }}
                      src={require("@imgs/letter.png")}
                      className="contactIcon "
                      alt={"imageComponent cursorpointer"}
                    />
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Nav className="ms-auto  order-md-2 me-3  ">
                {user ? (
                  <Dropdown
                    drop={"down"}
                    className="d-inline mx-2 ms-auto cursorpointer"
                    autoClose="true"
                  >
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id="dropdown-autoclose-outside"
                    >
                      <div className="authContainer">
                        <img
                          src={`${api}/api/v1/files/${user?.society_info.logo}/view`}
                          className="persIcon"
                          alt={"imageComponent"}
                        />
                        <span
                          className={
                            user?.is_society_owner
                              ? "UserNameContainer"
                              : "UserNameContainer2"
                          }
                        >
                          <span className="UserNameText">{user?.name}</span>
                          <span className="CorpText">
                            {user?.is_society_owner ? user?.society?.name : ""}
                          </span>
                        </span>
                        <img
                          src={require("@imgs/arrow.png")}
                          className="arrowIcon"
                          alt={"imageComponent"}
                        />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="">
                      {user?.is_society_owner && (
                        <>
                          <Dropdown.Item
                            onClick={() => {
                              handleClose();
                              navigate("/Users");
                            }}
                            className="dropdownItem"
                          >
                            {t("Monequipe")}
                          </Dropdown.Item>
                          <div className="DropdownDivider" />
                        </>
                      )}
                      {user?.is_society_owner && (
                        <>
                          <Dropdown.Item
                            onClick={() => {
                              handleClose();
                              navigate("/plans");
                            }}
                            className="dropdownItem"
                          >
                            {t("Monabonnement")}
                          </Dropdown.Item>
                          <div className="DropdownDivider" />
                        </>
                      )}
                      <Dropdown.Item
                        onClick={() => {
                          handleClose();
                          navigate("/UpdateProfile");
                        }}
                        className="dropdownItem"
                      >
                        {t("Monprofil")}
                      </Dropdown.Item>
                      <div className="DropdownDivider" />
                      <Dropdown.Item
                        onClick={() => {
                          handleClose();
                          navigate("/UpdatePassword");
                        }}
                        className="dropdownItem"
                      >
                        {t("Securite")}
                      </Dropdown.Item>
                      <div className="DropdownDivider" />
                      <Dropdown.Item
                        onClick={() => {
                          handleClose();
                          handleLogOut(t);
                        }}
                        className="dropdownItem"
                      >
                        {t("Sedeconnecter")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}
              </Nav>

              <Nav className="order-md-4 my-3 me-3 cursorpointer ">
                <Dropdown
                  drop={"down"}
                  className="d-inline mx-2 ms-auto  "
                  autoClose="true"
                >
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-autoclose-outside"
                  >
                    <div className="authContainer">
                      <img
                        src={require(`@imgs/${flag}.png`)}
                        className="flagIcon"
                        alt={"imageComponent"}
                      />
                      <img
                        src={require("@imgs/arrow.png")}
                        className="arrowIcon"
                        alt={"imageComponent"}
                      />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdownmenu" flip={true}>
                    {flag !== "fr" && (
                      <Dropdown.Item
                        onClick={() => {
                          changeLanguage("fr");
                          setFlag("fr");

                          handleClose();
                        }}
                        className=" d-flex justify-content-center text-center dropdownItem2"
                      >
                        <img
                          src={require(`@imgs/fr.png`)}
                          className="flagIcon2"
                          alt={"imageComponent"}
                        />
                      </Dropdown.Item>
                    )}
                    {flag !== "en" && (
                      <Dropdown.Item
                        onClick={() => {
                          handleClose();
                          changeLanguage("en");
                          setFlag("en");
                        }}
                        className=" d-flex justify-content-center text-center dropdownItem2"
                      >
                        <img
                          src={require(`@imgs/en.png`)}
                          className="flagIcon2"
                          alt={"imageComponent"}
                        />
                      </Dropdown.Item>
                    )}
                    {flag !== "gr" && (
                      <Dropdown.Item
                        onClick={() => {
                          handleClose();
                          changeLanguage("gr");
                          setFlag("gr");
                        }}
                        className=" d-flex justify-content-center text-center dropdownItem2"
                      >
                        <img
                          src={require(`@imgs/gr.png`)}
                          className="flagIcon2"
                          alt={"imageComponent"}
                        />
                      </Dropdown.Item>
                    )}
                    {flag !== "it" && (
                      <Dropdown.Item
                        onClick={() => {
                          handleClose();
                          changeLanguage("it");
                          setFlag("it");
                        }}
                        className=" d-flex justify-content-center text-center dropdownItem2"
                      >
                        <img
                          src={require(`@imgs/it.png`)}
                          className="flagIcon2"
                          alt={"imageComponent"}
                        />
                      </Dropdown.Item>
                    )}
                    {flag !== "sp" && (
                      <Dropdown.Item
                        onClick={() => {
                          handleClose();
                          changeLanguage("sp");
                          setFlag("sp");
                        }}
                        className=" d-flex justify-content-center text-center dropdownItem2"
                      >
                        <img
                          src={require(`@imgs/sp.png`)}
                          className="flagIcon2"
                          alt={"imageComponent"}
                        />
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
              <Nav className=" order-md-1 me-3 mt-3   ">
                {user && (
                  <Nav.Link className="ms-auto">
                    <div
                      className={
                        location.includes("/NewMessage") ||
                        location.includes("/MessageDetails") ||
                        location.includes("/ActivateProMode")
                          ? " ms-5 btnLinkActive"
                          : " ms-5 btnLink"
                      }
                    >
                      <img
                        src={require("@imgs/measCodesIcon.png")}
                        className="btnIcon"
                        alt={"imageComponent"}
                      />
                      <Link
                        onClick={() => {
                          handleClose();
                          dispatch(deleteCode());
                        }}
                        to={"/"}
                        className="btnLinkText"
                      >
                        {t("Mescodes")}
                      </Link>
                    </div>
                  </Nav.Link>
                )}
                {user?.can_create_codes && (
                  <Nav.Link
                    onClick={() => {
                      handleClose();
                    }}
                    className="ms-auto"
                  >
                    <div
                      className={
                        location.includes("CodeCreation")
                          ? "btnLinkActive2"
                          : "btnLink"
                      }
                    >
                      <Link to={"/CodeCreation"} className="btnLinkText">
                        +{" " + t("Creeruncode")}
                      </Link>
                    </div>
                  </Nav.Link>
                )}
              </Nav>
              <Nav className=" cursorpointer order-md-3 me-3 ">
                <img
                  onClick={() => {
                    handleClose();
                    window.open("https://cool2scan.com/#contact");
                  }}
                  src={require("@imgs/letter.png")}
                  className="contactIcon ms-auto my-2 my-lg-0 mx-lg-4"
                  alt={"imageComponent cursorpointer"}
                />
              </Nav>
            </>
          )}
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Navbar>
  );
};
