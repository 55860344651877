import { useContext, useState } from "react";
// api
import axios from "@globals/axiosConfig";
import { LoadingContext } from "@contexts/LoadingContext";

export default function useGetCards() {
  const [fetchingCards, setFetching] = useState(false);
  const [Data, setData] = useState(null);
  const { showLoader, hideLoader } = useContext(LoadingContext);

  async function GetCards() {
    try {
      showLoader()
      setFetching(true);
      const rep = await axios.get("/manager/societies/cards");
      setData(rep?.data);
    } catch (error) {
      console.log("error get cards ", error);
    } finally {
      setFetching(false);
      hideLoader();
    }
  }

  return [{ fetchingCards, Data }, GetCards];
}
