import { createContext, useContext, useState } from "react";
import Modal from "./Modal";
const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    headerText: "", 
    contentText: "",
    onYes: () => {},
    onNo: () => {},
  });

  const openModal = (headerText, contentText, onYes, onNo) => {
    setModalContent({
      headerText,
      contentText,
      onYes,
      onNo,
    });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {isModalOpen && (
        <Modal
          headerText={modalContent.headerText}
          contentText={modalContent.contentText}
          onYes={modalContent.onYes}
          onNo={() => {
            closeModal();

            modalContent.onNo();
          }}
          onClose={closeModal}
        />
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};
