// useSignUp.js
import { useState, useEffect, useContext } from "react";
import axios from "@globals/axiosConfig";
import { signupUrl } from "../AuthEndPoints";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "@contexts/LoadingContext";

const useSignUp = () => {
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = async (payload) => {
    // username, password;
    try {
      setFetching(true);
      showLoader();
      const response = await axios.post(signupUrl(), payload);
      navigate("/auth");
    } catch (error) {
      console.error("signUp failed:", error);
      setError("Invalid username or password.");
    } finally {
      setFetching(false);
      hideLoader();
    }
  };

  return {
    handleSignUp,
    error,
    fetching,
  };
};

export default useSignUp;
