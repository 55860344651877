import React from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";

import "./styles.css";
export default ({
  text,
  disabled,
  onClick,
  fetching,
  labelText,
  LeftComponent,
  black,
  customStyles,
  ...props
}) => {
  return (
    <>
      {labelText && <Form.Label className="labelStyle">{labelText}</Form.Label>}
      <Button
        disabled={disabled}
        onClick={onClick}
        variant="outline-primary"
        className={
          (LeftComponent
            ? "ButtonoutlineWithNohoverEffect"
            : black
            ? "ButtonoutlineBlack"
            : "Buttonoutline ") + (customStyles ? " " + customStyles : "")
        }
      >
        {fetching ? (
          <Spinner animation="border" />
        ) : (
          <>
            {LeftComponent && <LeftComponent />}
            {text}
          </>
        )}
      </Button>
    </>
  );
};
