// useLogOut.js
import { useContext } from "react";
import axios from "@globals/axiosConfig";
import { removeToken } from "@globals/authService";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "@contexts/LoadingContext";
import { useModal } from "@/contextProviders/ModalContext";
import { useDispatch } from "react-redux";
import { deleteCode } from "@Home/slice";
import { deleteUser } from "@Auth/slice";
import { deleteSubUser } from "@Home/Users/slice";

const useLogOut = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const { openModal, closeModal } = useModal();
  const dispatch = useDispatch();
  const handleLogOut = async (t) => {
    openModal(
      t("Deconnexion"),
      t("vousetesdeconnecter"),
      async () => {
        try {
          showLoader();
          await axios.get("/auth/managers/signout");
          //TODO DELETE USER CLEARE STATES LOCAL STORAGE AND REDUX
        } catch (error) {
          console.error("signOutErr:", error);
        } finally {
          //removing app data
          removeToken();
          hideLoader();
          closeModal();
          dispatch(deleteUser());
          dispatch(deleteCode());
          dispatch(deleteSubUser());
          localStorage.clear();
          navigate("/auth");
        }
      },
      () => {
        console.log("No clicked");
      }
    );
  };

  return handleLogOut;
};

export default useLogOut;
