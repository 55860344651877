import Empty from "./Empty";
import CodeCreation from "./CodeCreation";
import MesCodes from "./MesCodes";
import NewMessage from "./MesCodes/NewMessage";
import ActivateProMode from "./MesCodes/ActivateProMode";
import MessageDetails from "./MesCodes/MessageDetails";
import Users from "./Users";
import NewUser from "./newUser";
import DetailsUser from "./DetailsUser";
import UpdateProfile from "./UpdateProfile";
import UpdatePassword from "./UpdatePassword";

export default [
  {
    path: "empty",
    element: <Empty />,
  },
  // {
  //   path: "Payment",
  //   element: <Payment />,
  // },
  // replaced by /plans
  {
    path: "CodeCreation",
    element: <CodeCreation />,
  },
  {
    path: "Users",
    element: <Users />,
  },
  {
    path: "NewUser",
    element: <NewUser />,
  },
  {
    path: "DetailsUser",
    element: <DetailsUser />,
  },
  {
    path: "UpdateProfile",
    element: <UpdateProfile />,
  },
  {
    path: "UpdatePassword",
    element: <UpdatePassword />,
  },

  {
    path: "/",
    // index: true,
    element: <MesCodes />,
    children: [
      {
        path: "NewMessage",
        // index: true,
        element: <NewMessage />,
      },
      {
        path: "ActivateProMode",
        element: <ActivateProMode />,
      },
      {
        path: "MessageDetails",
        element: <MessageDetails />,
      },
    ],
  },
];
