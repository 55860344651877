// ------****** (C) Flexiapps 2023 18/07/2023 20:44 ******------
import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";
//the cropper imports
// import Slider from "@material-ui/core/Slider";
import Slider from "@mui/material/Slider";
import useUploadFile from "@hooks/useUploadFile";
import "./styles.css";

import Cropper from "react-easy-crop";
import getCroppedImg, { createImageifPngToWhiteBg } from "./cropImage";
// import LoaderFull from "components/LoaderFull";

export default ({ translation, ...props }) => {
  const { url, fetching, error, handleUpload } = useUploadFile();
  useEffect(() => {
    url && props.setImageUrlFromApi(url);
  }, [url]);
  const {
    showModal,
    setShowModal,
    cropShape /* 'rect' | 'round' => Shape of the crop area. */,
    // onDoneCropping
    aspect,
  } = props;
  //modal trigger
  const toggle = () => setShowModal(!showModal);
  //image cropping state managment
  //image file source
  const [imgSrc, setImgSrc] = useState(props?.selectedimage);
  // cropper state
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  //zoom in & out state
  const [scale, setScale] = useState(1);
  //loader
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    url && props.setImageUrlFromApi(url);
    console.log("from the cropper ", url);
  }, [url]);
  //image saver function
  const onDownloadCropClick = async () => {
    try {
      setLoader(true);
      const { file, url } = await getCroppedImg(imgSrc, croppedAreaPixels);
      props?.onDoneCropping && props.onDoneCropping(url);
      handleUpload(file);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
      setShowModal(false);
    }
  };
  //to inject the image url to the cropper from the selected image in the parrent component
  useEffect(() => {
    const callback = async () => {
      try {
        setLoader(true);

        const data = await createImageifPngToWhiteBg(props?.selectedimage);
        setImgSrc(data?.url);
      } catch (error) {
        console.log("err from createImageifPngToWhiteBg :  ", error);
      } finally {
        setLoader(false);
      }
    };
    props?.selectedimage && callback();
  }, [props?.selectedimage]);

  //cropped area shape state
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <Modal isOpen={showModal} toggle={toggle}>
      <ModalHeader toggle={toggle}> {translation("Recadrer")}</ModalHeader>
      <ModalBody>
        <Container className="text-center cropperStyles ">
          {/* the cropper */}
          {/* {loader && <LoaderFull />} */}
          <Cropper
            cropShape={cropShape}
            image={imgSrc}
            crop={crop}
            zoom={scale}
            // aspect={1}
            //square shape of cropping
            aspect={aspect}
            onZoomChange={setScale}
            onCropChange={setCrop}
            onCropComplete={cropComplete}
          />
        </Container>
      </ModalBody>
      <ModalFooter>
        <Container>
          {/* cropper sliders  */}
          <Row>
            <Col>
              <Row>
                <Col>
                  <div>
                    <Label>{translation("zoomerdezoomer")} </Label>

                    <Slider
                      value={scale}
                      step={0.1}
                      min={1}
                      max={10}
                      disabled={!imgSrc}
                      onChange={(event, newValue) => {
                        setScale(Number(newValue));
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* action buttons */}
          <Row className="mt-3">
            <Col xs="12" sm="6" md="6" className="text-center ">
              <div
                className="btn-cancel-modal text-demi"
                onClick={() => {
                  props?.onCancel && props.onCancel();
                  toggle();
                }}
              >
                {translation("Annuler")}
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" className="text-center">
              <Button
                block
                className="btn-save-modal"
                onClick={onDownloadCropClick}
              >
                <span className="text-demi">{translation("Appliquer")}</span>
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFooter>
    </Modal>
  );
};
